import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

export default class BienCommodite extends AModel {
    private _comments!: string;
    private _commodite!: DictionaryItem;
    private _distance!: number;

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get commodite(): DictionaryItem {
        return this._commodite;
    }

    set commodite(value: DictionaryItem) {
        this._commodite = value;
    }

    get distance(): number {
        return this._distance;
    }

    set distance(value: number) {
        this._distance = value;
    }
}
