import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {NgBienDossier} from '@legacy/app/managers/ressources';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getBienModalAdvancedPrintCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('BienModalAdvancedPrintCtrl', Controller);

        /**
         * Controller for advanced print modal
         *
         * @param dossier
         * @param $uibModalInstance
         * @param Ng2EtudeService
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['dossier', '$uibModalInstance', 'Ng2EtudeService', 'Ng2DictionaryFactory'];
        function Controller(this: any,
                            dossier: NgBienDossier,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            ng2EtudeService: EtudeService,
                            ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;
            var fichePrefix = 'fiche-' + dossier._esk.typeDossier;

            $ctrl.print = print;
            ng2EtudeService.last$.subscribe(currentEtude => {
                $ctrl.affichetteAuthorized = dossier._esk.typeDossier === DossierBienTypesConst.VENTE && currentEtude.imprimerAffichetteConfrere && dossier.bien.responsableDossier.etude.imprimerAffichetteConfrere;
            });
            $ctrl.typeDocument = 'fiche';
            $ctrl.codesTemplates = {
                affichette: {
                    codes: ['affichette-' + dossier._esk.typeDossier],
                    groups: ng2DictionaryFactory.getByName(Dictionary.names.AFFICHETTE_GROUPS),
                },
                fiche: {
                    codes: [fichePrefix, fichePrefix + (dossier.bien._esk.interne ? '-confidentielle' : '-confrere')],
                    courrierCodes: [fichePrefix + '-courrier-introductif'],
                },
            };

            /**
             * Close modal with print parameters
             */
            function print() {
                var selectedTemplates = {};

                if ($ctrl.typeDocument === 'fiche') {
                    // @ts-ignore
                    selectedTemplates.courrierTemplate = $ctrl.selectedTemplates.ficheCourrierTemplate;
                    // @ts-ignore
                    selectedTemplates.documentTemplate = $ctrl.selectedTemplates.ficheTemplate;
                } else if ($ctrl.typeDocument === 'affichette') {
                    // @ts-ignore
                    selectedTemplates.documentTemplate = $ctrl.selectedTemplates.affichetteTemplate;
                }

                $uibModalInstance.close(selectedTemplates);
            }
        }
    })(angularJS);
}
