import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {map, take, takeUntil} from 'rxjs/operators';
import CEstimationOnsaleReferences
    from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.model';
import {
    CEstimationOnsaleReferencesService
} from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.service';
import {IEstimationOnsaleReferencesListOptions} from '@features/estimations/estimations.interfaces';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {
    EstimationOnsaleReferenceDropdownComponent
} from '@features/estimations/estimation/onsale-references/onsale-reference/dropdown/estimation.onsale-reference.dropdown.component';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CollectionSortableService} from '@shared/collection/sortable/collection.sortable.service';

@Component({
    selector: 'app-estimation-onsale-references-list',
    templateUrl: 'estimation.onsale-references-list.component.html',
})
export class AppEstimationOnsaleReferencesListComponent implements OnDestroy, OnInit {
    // @todo Fusionner avec "clicked" comme dans AppEstimationReferencesListComponent
    @Output() readonly referenceSorted = new EventEmitter<{
        estimationOnsaleReference: EstimationOnsaleReference,
        idx: number,
    }>();
    // Impossible de passer avec la ligne ci-dessous, une erreur dans la console apparait
    // Readonly referenceSorted = output<{ estimationOnsaleReference: EstimationOnsaleReference, idx: number }>();

    static readonly initEstimationOnsaleReferencesListOptions: IEstimationOnsaleReferencesListOptions = {};
    readonly clicked = output<IDropdownClicked>();
    readonly AppEstimationOnsaleReferencesListName = 'AppEstimationOnsaleReferencesList';
    private _cEstimationOnsaleReferencesService = inject(CEstimationOnsaleReferencesService);
    private _collectionSortableService = inject(CollectionSortableService);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dropdownService = inject(DropdownService);
    private _cEstimationOnsaleReferences$!: Observable<CEstimationOnsaleReferences>;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IEstimationOnsaleReferencesListOptions = {...AppEstimationOnsaleReferencesListComponent.initEstimationOnsaleReferencesListOptions};
    private _pendingGetMore = false;
    private _superficieLabel!: string;

    get cEstimationOnsaleReferences$(): Observable<CEstimationOnsaleReferences> {
        return this._cEstimationOnsaleReferences$;
    }

    @Input({required: true})
    set cEstimationOnsaleReferences$(value$: Observable<CEstimationOnsaleReferences>) {
        this._cEstimationOnsaleReferences$ = value$;
    }

    get options(): IEstimationOnsaleReferencesListOptions {
        return this._options;
    }

    @Input()
    set options(value: IEstimationOnsaleReferencesListOptions) {
        this._options = {...AppEstimationOnsaleReferencesListComponent.initEstimationOnsaleReferencesListOptions, ...value};
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(this.options.mainNature!);
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => this.clicked.emit(dropdownClicked));
        this._collectionSortableService.movedItem$.pipe(
            map(({item, idx}) => ({estimationOnsaleReference: item, idx} as {
                estimationOnsaleReference: EstimationOnsaleReference,
                idx: number
            })),
            takeUntil(this._onDestroy$),
        ).subscribe(movedEstimationOnsaleReference => this.referenceSorted.emit(movedEstimationOnsaleReference));
        /*
        This._collectionSortableService.isInit$(this.AppEstimationOnsaleReferencesListName).pipe(
            filter(isInit => isInit),
            switchMap(() => this._collectionSortableService.getMovedItem$(this.AppEstimationOnsaleReferencesListName)),
            map(({item, idx}) => ({estimationOnsaleReference: item, idx} as {
                estimationOnsaleReference: EstimationOnsaleReference,
                idx: number,
            })),
            takeUntil(this._onDestroy$),
        ).subscribe(movedEstimationOnsaleReference => this.referenceSorted.emit(movedEstimationOnsaleReference));
        */
    }

    ngOnDestroy(): void {
        /*
        This._collectionSortableService.delete(this.AppEstimationOnsaleReferencesListName);
        */
        this._onDestroy$.next();
    }

    clickOnEstimationOnsaleReference(htmlButtonElement: HTMLButtonElement, estimationOnsaleReference: EstimationOnsaleReference): void {
        this._dropdownService.open(htmlButtonElement, {
            component: EstimationOnsaleReferenceDropdownComponent,
            data: {estimationOnsaleReference},
        });
    }

    getMore(cEstimationSelectedReferences: CEstimationOnsaleReferences): void {
        this._pendingGetMore = true;
        this._cEstimationOnsaleReferencesService.addNext$(cEstimationSelectedReferences).pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }

    isEnabled(estimationOnsaleReference: EstimationOnsaleReference): boolean {
        if (!this.options.filterEnabled) {
            return true;
        }

        return this.options.filterEnabled(estimationOnsaleReference);
    }
}
