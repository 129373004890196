import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import {LOCALE_FR_FR} from '@shared/constants';

@Pipe({name: 'textCurrency'})
export class TextCurrencyPipe implements PipeTransform {
    static transformation(value: number | string, currencyCode = 'EUR'): string {
        if (value === undefined || value === null) {
            return '';
        }

        const cleanValue = +(value.toString().replace(',', '.'));
        const fractionDigitsDefault = 2;
        const fractionDigits = (Math.round(cleanValue % 1 * Math.pow(10, fractionDigitsDefault)) === 0 ? 0 : fractionDigitsDefault).toString();

        return formatCurrency(
            cleanValue,
            LOCALE_FR_FR,
            getCurrencySymbol(currencyCode, 'wide'),
            currencyCode,
            '0.' + fractionDigits + '-' + fractionDigits,
        );
    }

    transform(value: number | string, currencyCode = 'EUR'): string {
        return TextCurrencyPipe.transformation(value, currencyCode);
    }
}
