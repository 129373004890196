import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {FeaturesModule} from '@features/features.module';
import {
    AppLayoutBienEditConstructionComponent
} from '@core/routing/layout/biens/bien/edit/construction/layout-bien-edit-construction.component';
import {
    AppLayoutBienEditFormalitesComponent
} from '@core/routing/layout/biens/bien/edit/formalites/layout-bien-edit-formalites.component';

@NgModule({
    declarations: [AppLayoutBienEditConstructionComponent, AppLayoutBienEditFormalitesComponent],
    imports: [CommonModule, EskimmoModule, FeaturesModule, SharedModule],
})
export class BiensRoutingModule {
}
