import angularJS from '@shared/angularJS/global.ng';
import {filter, map as lmap, upperFirst} from 'lodash';
import {IModule, IPromise, IQService, IWindowService} from 'angular';
import {NgEskUtils, NgSearch} from '@legacy/app/eskimmo/eskimmo';
import {NgBienDossier, NgEmailDossier, NgFilter} from '@legacy/app/managers/ressources';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgBienUtils} from '@legacy/app/biens/biens';
import {
    NgContactsGroupManager, NgDemandeurManager, NgDemandeurRechercheManager, NgEmailManager, NgLocationManager,
    NgSortManager, NgUserManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgBienDossiersCollection} from '@legacy/app/managers/collections';
import {ConditionConst, DisplayMode, SortConst} from '@shared/constants';
import {UserService} from '@core/models/users/user/user.service';
import {map, take, tap} from 'rxjs/operators';
import {firstValueFrom, of} from 'rxjs';
import Filter from '@models/filters/filter/filter.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {SearchService} from '@models/search/search.service';

export default function getDossierBiensWalletSearch(module: IModule): void {
    (function (angular) {
        'use strict';

        // Utiliser comme composant de routing
        module.component('eskDossierBiensWalletSearch', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/wallet/search.html',
        });

        /**
         * Controller of DossierBiens list
         *
         * @param VenteManager
         * @param Ng2RouterStateForNgService
         * @param DemandeurManager
         * @param DemandeurRechercheManager
         * @param $translate
         * @param SoqSweetAlert
         * @param SoqModalesManager
         * @param EmailManager
         * @param EskUtils
         * @param ContactsGroupManager
         * @param $q
         * @param BiensUtils
         * @param SortManager
         * @param LocationManager
         * @param Ng2UserService
         * @param UserManager
         * @param $window
         * @param Ng2SearchService
         */
        Controller.$inject = ['VenteManager', 'Ng2RouterStateForNgService', 'DemandeurManager', 'DemandeurRechercheManager', '$translate', 'SoqSweetAlert', 'SoqModalesManager', 'EmailManager', 'EskUtils', 'ContactsGroupManager', '$q', 'BiensUtils', 'SortManager', 'LocationManager', 'Ng2UserService', 'UserManager', '$window', 'Ng2SearchService'];
        function Controller(this: any,
                            venteManager: NgVenteManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            demandeurManager: NgDemandeurManager,
                            demandeurRechercheManager: NgDemandeurRechercheManager,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            soqModalesManager: NgSoqModalesManager,
                            emailManager: NgEmailManager,
                            eskUtils: NgEskUtils,
                            contactsGroupManager: NgContactsGroupManager,
                            $q: IQService,
                            biensUtils: NgBienUtils,
                            sortManager: NgSortManager,
                            locationManager: NgLocationManager,
                            ng2UserService: UserService,
                            userManager: NgUserManager,
                            $window: IWindowService,
                            ng2SearchService: SearchService) {
            const $ctrl = this;
            const options = ng2RouterStateForNgService.params.options ?? {};
            let dossierBiensManager: NgLocationManager | NgVenteManager;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.changeDisplayMode = changeDisplayMode;
            $ctrl.getSelectedDossierBiens = getSelectedDossierBiens;
            $ctrl.send = send;
            $ctrl.createDemandeur = createDemandeur;
            $ctrl.onChangePagination = onChangePagination;
            $ctrl.sortResults = sortResults;
            $ctrl.$onInit = $onInit;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            $ctrl.filterCode = Filter.codes.BOX_SEARCH;

            /**
             * Initialization method
             */
            function $onInit() {
                if (ng2RouterStateForNgService.current.name!.startsWith('/app/locations')) {
                    dossierBiensManager = locationManager;
                    $ctrl.filterRoute = Filter.routes.GET_LOCATIONS;
                    $ctrl.typeDossier = DossierBienTypesConst.LOCATION;
                } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/ventes')) {
                    dossierBiensManager = venteManager;
                    $ctrl.filterRoute = Filter.routes.GET_VENTES;
                    $ctrl.typeDossier = DossierBienTypesConst.VENTE;
                } else {
                    throw new Error('Impossible de déterminer le "typeDossier" pour ' + ng2RouterStateForNgService.current.name);
                }

                let params = ng2RouterStateForNgService.params;
                let ngSearch$ = of(params.search as NgSearch);

                if ($window.history.state?.extraParams?.etudeId) {
                    const statuts = $window.history.state?.extraParams?.statuts;

                    ngSearch$ = of({
                        etude: {condition: ConditionConst.IN, values: [$window.history.state.extraParams.etudeId]},
                        rapidSearchType: 'criteria',
                        statuts,
                    } as unknown as NgSearch);
                } else if ($window.history.state?.extraParams?.userId) {
                    ngSearch$ = of({
                        responsableDossier: {
                            condition: ConditionConst.IN,
                            values: [$window.history.state.extraParams.userId],
                        },
                        rapidSearchType: 'criteria',
                    } as unknown as NgSearch);
                } else if (!params.search) {
                    ngSearch$ = ng2SearchService.current$.pipe(
                        take(1),
                        tap(_ => ng2SearchService.razCurrent()),
                        map(search => eskUtils.createNgSearchFromNg2(search?.criteria)),
                        tap(ngSearch => ngSearch.rapidSearchType = 'criteria'),
                    );
                }

                ngSearch$.subscribe(ngSearch => {
                    eskUtils.setDataForRoute('/app/' + $ctrl.typeDossier + 's/search');
                    $ctrl.search = angular.extend({
                        areas: [],
                        communes: [],
                        natures: [],
                        location: {},
                        quartiers: [],
                        vente: {},
                    }, ngSearch);
                    if ($ctrl.search.tris?.dateModification) {
                        $ctrl.sort = sortManager.getOneById('dateModification' + SortConst.DESCENDING);
                    } else {
                        $ctrl.sort = sortManager.getOneById('dateDiffusion' + SortConst.DESCENDING);
                    }
                    ng2UserService.last$.pipe(
                        tap(currentUser => $ctrl.currentUser = currentUser),
                    ).subscribe(currentUser => {
                        $ctrl.options = {
                            nameDossiers: $ctrl.typeDossier.toUpperCase() + 'S_LIST',
                            typeFilter: $ctrl.typeDossier.toUpperCase() + 'S_LIST',
                            typeSort: $ctrl.typeDossier.toUpperCase() + 'S_LIST',
                            creatingMessage: 'Chargement des biens',
                            displayMode: currentUser.clientMetadata.getWallet($ctrl.filterRoute, $ctrl.filterCode).view,
                            loader: {updating: true},
                            visibleFields: {
                                archiveDateVente: false,
                                bullet: false,
                                mandatDateFin: false,
                                nomContactsGroup: false,
                                preciseLocalisation: (options as Record<string, unknown>).preciseLocalisation === true,
                                priceCession: false,
                                statut: false
                            },
                            onChangeFilter: function (filter: NgFilter) {
                                $ctrl.options.loader.updating = true;
                                $ctrl.sort = filter.queryParameters.sort;

                                return getFilteredDossierBiens().finally(function () {
                                    $ctrl.options.loader.updating = false;
                                });
                            },
                        };
                        getFilteredDossierBiens().finally(function () {
                            $ctrl.options.creatingMessage = undefined;
                            $ctrl.options.loader.updating = false;
                        });
                    });
                });
            }

            /**
             * Change view mode
             *
             * @param displayMode
             */
            function changeDisplayMode(displayMode: DisplayMode) {
                $ctrl.options.displayMode = displayMode;
            }

            /**
             * Get selected dossierBiens
             *
             * @returns {Array}
             */
            function getSelectedDossierBiens() {
                let selectedDossierBiens = [] as unknown[];

                if (angular.isObject($ctrl.dossierBiens)) {
                    selectedDossierBiens = filter($ctrl.dossierBiens.collection, {_esk: {select: true}});
                }

                return selectedDossierBiens;
            }

            /**
             * Send selected dossierBiens
             */
            function send() {
                const selectedDossierBiens = $ctrl.getSelectedDossierBiens();

                $ctrl.barreFaireLoader = 'global.en_cours.SEND';
                biensUtils.getMediasFiche($ctrl.typeDossier, selectedDossierBiens).then(function (attachments) {
                    const emailDossiers: NgEmailDossier[] = [];

                    angular.forEach(selectedDossierBiens, function (selectedDossierBiens: NgBienDossier) {
                        if (selectedDossierBiens.bien._esk.interne) {
                            emailDossiers.push({
                                typeId: selectedDossierBiens.id,
                                type: $ctrl.typeDossier,
                                dossier: selectedDossierBiens,
                            } as unknown as NgEmailDossier);
                        }
                    });

                    return soqModalesManager.open('EskModalEmail', {
                        resolve: {
                            email: emailManager.create({
                                attachments: attachments,
                                dossiers: emailDossiers,
                                subject: $translate.instant('bien.grouped_action.mailing.printedFiches.SUBJECT', {
                                    typeDossier: $ctrl.typeDossier,
                                    nbDossiers: selectedDossierBiens.length,
                                }),
                                message: $translate.instant('bien.grouped_action.mailing.printedFiches.MESSAGE', {
                                    typeDossier: $ctrl.typeDossier,
                                    nbDossiers: selectedDossierBiens.length,
                                    references: lmap(selectedDossierBiens, 'bien.reference').join(', '),
                                }) + "<br><br>" + emailManager.getCurrentUserSignature(),
                            }),
                            currentDossier: {},
                        },
                    });
                }).finally(() => {
                    $ctrl.unselectAll();
                    $ctrl.barreFaireLoader = undefined;
                });
            }

            /**
             * Create a demandeur with search
             */
            function createDemandeur() {
                var criteria;

                if ($ctrl.search.natures.length <= 0) {
                    criteria = 'nature';
                } else if ($ctrl.search.areas.length <= 0 && $ctrl.search.communes.length <= 0 && $ctrl.search.quartiers.length <= 0) {
                    criteria = 'localisations';
                }

                if (angular.isString(criteria)) {
                    soqSweetAlert.warningMessage($translate.instant('demandeur.search.edition.invalid.TITLE'), $translate.instant('demandeur.search.edition.invalid.MESSAGE', {criteria: criteria})).then(function () {
                        ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/search/criteres', {search: $ctrl.search});
                    });

                    return;
                }

                soqSweetAlert.inputConfirmation<string>($translate.instant('demandeur.search.quick_creation.confirm.TITLE'), $translate.instant('demandeur.search.quick_creation.confirm.MESSAGE'), $translate.instant('demandeur.search.quick_creation.confirm.INPUT'), {
                    confirmButtonText: $translate.instant('demandeur.search.quick_creation.confirm.CONFIRMATION'),
                    inputValidator: function (nomContactsGroup: string) {
                        if (!angular.isString(nomContactsGroup) || nomContactsGroup === '') {
                            return $translate.instant('demandeur.search.quick_creation.confirm.VALIDATION');
                        }

                        return '';
                    }
                }).then(function (nomContactsGroup) {
                    $ctrl.options.loader.creating = true;

                    return firstValueFrom(ng2UserService.last$).then(currentUser => {
                        return contactsGroupManager.create({nom: upperFirst(nomContactsGroup)}).save().then(function (contactsGroup) {
                            return demandeurManager.create({
                                responsableDossier: userManager.createFromNg2(currentUser),
                                notaire: userManager.createFromNg2($ctrl.currentUser.defaultNotaire),
                                contactsGroup: contactsGroup
                            }).save().then(function (demandeur) {
                                const search = demandeurRechercheManager.create($ctrl.search, demandeur.id);

                                return search.save().then(() => search.launch().then(() => {
                                    ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + demandeur.id + '/resultats');
                                }));
                            });
                        });
                    });
                }).finally(function () {
                    $ctrl.options.loader.creating = false;
                });
            }

            /**
             * Update DossierBiens list
             *
             * @param dossierBiens
             * @returns {Array}
             */
            function onChangePagination(dossierBiens: { collection: NgBienDossier[] }) {
                angular.forEach(dossierBiens.collection, function (dossierBien) {
                    dossierBien._esk.routing = '/app/' + $ctrl.typeDossier + 's/search/details/' + dossierBien.id.toString();
                });

                return dossierBiens;
            }

            /**
             * Sort results
             */
            function sortResults() {
                $ctrl.options.loader.updating = true;
                getFilteredDossierBiens().finally(function () {
                    $ctrl.options.loader.updating = false;
                });
            }

            /**
             * Get filtered dossierBiens
             *
             * @returns {Promise}
             */
            function getFilteredDossierBiens() {
                const filter = {sort: $ctrl.sort, statut: {condition: ConditionConst.IN, values: [ADossierBien.abstractBienStatuts.DIFFUSE, ADossierBien.abstractBienStatuts.MANDAT]}, tris: {}};
                const promises = [] as IPromise<unknown>[];

                switch ($ctrl.search.rapidSearchType) {
                    case 'reference':
                        // @ts-ignore
                        filter.reference = {condition: 'STARTS_WITH', values: [$ctrl.search.reference]};
                        break;
                    case 'localisation':
                        var voie;

                        if ($ctrl.search.rapidSearchLocalisationType === 'quartier') {
                            if ($ctrl.search.quartiers.length > 0) {
                                // @ts-ignore
                                filter.quartier = {condition: ConditionConst.IN, values: [$ctrl.search.quartiers[0].id]};
                            } else {
                                if ($ctrl.search.communes.length > 0) {
                                    // @ts-ignore
                                    filter.commune = {condition: ConditionConst.IN, values: [$ctrl.search.communes[0].id]};
                                }
                            }
                        } else {
                            if ($ctrl.search.communes.length > 0 && $ctrl.search.communes[0]) {
                                // @ts-ignore
                                filter.commune = {condition: ConditionConst.IN, values: [$ctrl.search.communes[0].id]};
                                if ($ctrl.search.communes[0].hasVoies) {
                                    voie = angular.isObject($ctrl.search.voie) ? $ctrl.search.voie.nom : undefined;
                                } else {
                                    voie = $ctrl.search.nomVoie;
                                }
                            }

                            if (angular.isString(voie) && voie !== '') {
                                // @ts-ignore
                                filter.nomVoie = {condition: ConditionConst.CONTAINS, values: [voie]};
                            }
                        }
                        break;
                    default:
                        $ctrl.search.rapidSearchType = 'criteria';
                        angular.extend(filter, eskUtils.getFilterFromSearch($ctrl.search));
                        break;
                }

                if ($ctrl.search.rapidSearchType !== 'criteria') {
                    $ctrl.search.rapidSearchType = 'criteria';
                    // @ts-ignore
                    filter.district = filter.quartier;
                    promises.push(eskUtils.getSearchFromFilter($ctrl.typeDossier, filter as unknown as NgFilter).then(search => {
                        $ctrl.search = angular.extend($ctrl.search, search);
                    }));
                }

                if ($ctrl.sort) {
                    filter.tris = {};
                    (filter.tris as Record<string, string>)[$ctrl.sort.code] = $ctrl.sort.direction;
                }

                $ctrl.filter = {queryParameters: filter, save: () => $q.resolve($ctrl.filter)};
                promises.push((dossierBiensManager.getAll(filter) as IPromise<NgBienDossiersCollection>).then(dossierBiens => {
                    $ctrl.onChangePagination(dossierBiens);
                    eskUtils.setDataForCurrentRoute({
                        dossiers: dossierBiens,
                        routeBack: {
                            name: ng2RouterStateForNgService.current.name!,
                            params: ng2RouterStateForNgService.params as unknown as string,
                        },
                        search: $ctrl.search,
                    });
                    $ctrl.dossierBiens = dossierBiens;
                }));

                return $q.all(promises);
            }
        }
    })(angularJS);
}
