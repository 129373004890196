import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    IBienCadastreDoubleMandatApi, IBienCadastreDoubleMandatQueryParameters
} from '@models/bien/cadastres/cadastre/double-mandat/bien-cadastre-double-mandat.interfaces';
import {IBienCadastreApi} from '@models/bien/cadastres/cadastre/bien-cadastre.interfaces';

@Injectable({providedIn: 'root'})
export class BienCadastresApiService {
    private _modelApiService = inject(ModelApiService);

    checkDoubleMandat$(queryParameters: IBienCadastreDoubleMandatQueryParameters): Observable<IBienCadastreDoubleMandatApi> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/toolbox/biens/cadastres/check', queryParameters));
    }

    get$(dossierBienId: string, id: string): Observable<IBienCadastreApi> {
        return this._modelApiService.get$(`/biens/${dossierBienId}/cadastres/${id}`);
    }
}
