import angularJS from '@shared/angularJS/global.ng';
import {findIndex} from 'lodash';
import {IModule} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgLocationLocataireEtatLieuxManager, NgMediaManager} from '@legacy/app/managers/managers';
import User from '@models/users/user/user.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {NgLocationLocataire, NgLocationLocataireEtatLieux, NgMedia} from '@legacy/app/managers/ressources';
import {MediaFactory} from '@models/medias/media/media.factory';
import {take} from 'rxjs/operators';
import DateFormat from '@shared/date/date.format';

export default function getLocationModalEditEtatsLieuxCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('LocationModalEditEtatsLieuxCtrl', Controller);

        /**
         * Controller for EtatsLieux modal
         *
         * @param locataire
         * @param Ng2EtudeService
         * @param LocationLocataireEtatLieuxManager
         * @param SoqSweetAlert
         * @param $translate
         * @param MediaManager
         * @param Ng2MediaFactory
         */
        Controller.$inject = ['locataire', 'Ng2EtudeService', 'LocationLocataireEtatLieuxManager', 'SoqSweetAlert', '$translate', 'MediaManager', 'Ng2MediaFactory'];
        function Controller(this: any,
                            locataire: NgLocationLocataire,
                            ng2EtudeService: EtudeService,
                            locationLocataireEtatLieuxManager: NgLocationLocataireEtatLieuxManager,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            mediaManager: NgMediaManager,
                            ng2MediaFactory: MediaFactory) {
            const $ctrl = this;

            $ctrl.setCurrent = setCurrent;
            $ctrl.save = save;
            $ctrl.remove = remove;
            $ctrl.upload = upload;
            $ctrl.downloadMedia = downloadMedia;
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtude = currentEtude);
            $ctrl.loading = true;
            $ctrl.usersRoles = [User.roles.NEGOCIATEUR, User.roles.NOTAIRE];
            locationLocataireEtatLieuxManager.getAll(locataire._esk.defaultRouteParams!.locationId!, locataire._esk.defaultRouteParams!.id!).then(function (etatsLieux) {
                $ctrl.etatsLieux = etatsLieux.collection;
                $ctrl.setCurrent();
            }).finally(() => $ctrl.loading = false);

            /**
             * Change the current etatLieux
             *
             * @param etatLieux
             */
            function setCurrent(etatLieux: NgLocationLocataireEtatLieux) {
                $ctrl.current = null;
                if (etatLieux === null) {
                    return;
                }

                if (angular.isDefined($ctrl.editForm)) {
                    $ctrl.editForm.$setPristine();
                }

                if (!angular.isObject(etatLieux)) {
                    etatLieux = locationLocataireEtatLieuxManager.create({date: DateFormat.toDate()}, locataire._esk.defaultRouteParams!.locationId!, locataire._esk.defaultRouteParams!.id!);
                }

                $ctrl.current = angular.copy(etatLieux);
            }

            /**
             * Save the current etatLieux
             *
             * @param keepCurrent
             */
            function save(keepCurrent: boolean) {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) return;

                $ctrl.sending = true;
                $ctrl.current.save().then(function () {
                    var idx = findIndex($ctrl.etatsLieux, function (etatLieux) {
                        // @ts-ignore
                        return etatLieux._esk.uuid === $ctrl.current._esk.uuid;
                    });

                    if (idx === -1) {
                        $ctrl.etatsLieux.unshift($ctrl.current);
                    } else {
                        $ctrl.etatsLieux[idx] = $ctrl.current;
                    }
                }).finally(function () {
                    if (!keepCurrent) {
                        $ctrl.setCurrent(null);
                    }
                    $ctrl.sending = false;
                });
            }

            /**
             * Remove the current etatLieux
             */
            function remove() {
                $ctrl.removing = true;
                soqSweetAlert.dangerConfirmation($translate.instant('remove.confirm.TITLE'), $translate.instant('remove.confirm.MESSAGE', {ceData: 'cet état des lieux'}), {confirmButtonText: $translate.instant('remove.confirm.CONFIRMATION')}).then(function () {
                    return $ctrl.current.remove().then(function () {
                        $ctrl.etatsLieux.splice(findIndex($ctrl.etatsLieux, function (etatLieux) {
                            // @ts-ignore
                            return etatLieux._esk.uuid === $ctrl.current._esk.uuid;
                        }), 1);
                    }).finally(() => $ctrl.setCurrent(null));
                }, function () {
                }).finally(() => $ctrl.removing = false);
            }

            /**
             * Upload the given media
             *
             * @param files
             */
            function upload(files: File[]) {
                $ctrl.editForm.$submitted = true;
                $ctrl.mediaAttached = mediaManager.create({file: files[0]});
                $ctrl.mediaAttached.upload().then(function () {
                    $ctrl.current.media = angular.copy($ctrl.mediaAttached);
                    $ctrl.mediaAttached = null;
                    $ctrl.save(true);
                });
            }

            /**
             * Download ngMedia
             *
             * @param ngMedia
             */
            function downloadMedia(ngMedia: NgMedia) {
                ng2MediaFactory.read$(ng2MediaFactory.ngCreate(ngMedia)).pipe(take(1)).subscribe();
            }
        }
    })(angularJS);
}
