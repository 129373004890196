import angularJS from '@shared/angularJS/global.ng';
import {ConditionConst, DisplayMode, SortConst} from '@shared/constants';
import {IModule} from 'angular';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {NgDictionariesManager, NgVenteManager} from '@legacy/app/managers/managers';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgVentesCollection} from '@legacy/app/managers/collections';
import {take, tap} from 'rxjs/operators';
import {NgSort} from '@legacy/app/managers/ressources';
import {UserService} from '@core/models/users/user/user.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Filter from '@models/filters/filter/filter.model';
import Vente from '@models/ventes/vente/vente.model';

export default function getEstimationsReferences(module: IModule): void {
    (function (angular) {
        'use strict';

        // Utiliser comme composant de routing
        module.component('eskEstimationsReferences', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/references.html',
        });

        /**
         * Controller de recherche des biens archivé du réseau
         *
         * @param Ng2RouterStateForNgService
         * @param EskUtils
         * @param VenteManager
         * @param DictionariesManager
         * @param Ng2UserService
         * @param Ng2DictionaryItemService
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'EskUtils', 'VenteManager', 'DictionariesManager', 'Ng2UserService', 'Ng2DictionaryItemService'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            eskUtils: NgEskUtils,
                            venteManager: NgVenteManager,
                            dictionariesManager: NgDictionariesManager,
                            ng2UserService: UserService,
                            ng2DictionaryItemService: DictionaryItemService) {
            const $ctrl = this;
            let sorts: NgSort[];

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.changeDisplayMode = changeDisplayMode;
            $ctrl.onChangePagination = onChangePagination;
            $ctrl.$onInit = $onInit;
            $ctrl.filterCode = Filter.codes.ESTIMATIONS_REFERENCES;
            $ctrl.filterRoute = Filter.routes.GET_VENTES;

            /**
             * Initialization method
             */
            function $onInit() {
                sorts = (angular.isObject(ng2RouterStateForNgService.params.sorts) ? ng2RouterStateForNgService.params.sorts : {dateDiffusion: SortConst.DESCENDING}) as NgSort[];
                eskUtils.setDataForRoute('/app/estimations/references');
                $ctrl.search = angular.extend({
                    natures: [],
                    areas: [],
                    communes: [],
                    quartiers: [],
                    vente: {},
                    archive: {
                        types: [
                            dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_TYPES, Vente.archiveTypes.NEGOCIEE)),
                        ],
                    },
                }, ng2RouterStateForNgService.params.search);
                ng2UserService.last$
                    .pipe(tap(currentUser => $ctrl.currentUser = currentUser), take(1))
                    .subscribe(currentUser => {
                        $ctrl.options = {
                            nameDossiers: 'estimation.references.TITLE',
                            creatingMessage: 'Chargement des biens',
                            displayMode: currentUser.clientMetadata.getWallet($ctrl.filterRoute, $ctrl.filterCode).view,
                            loader: {updating: true},
                            visibleFields: {
                                bullet: false,
                                mandatDateFin: false,
                                nomContactsGroup: false,
                                nombreChambres: false,
                                nombrePieces: false,
                                price: false,
                                select: false,
                                statut: false,
                            }
                        };
                        (function () {
                            const filter = angular.extend({tris: {archiveDateVente: SortConst.DESCENDING}}, eskUtils.getFilterFromSearch($ctrl.search));

                            angular.forEach(sorts, function (value, key) {
                                filter.tris[key] = value;
                            });
                            filter.statut = {condition: ConditionConst.IN, values: [Vente.statuts.ARCHIVE]};
                            filter.archive = angular.extend({
                                type: {condition: ConditionConst.NOT_IN, values: [Vente.archiveTypes.RETIREE]},
                            }, filter.archive);

                            return venteManager.getAll(filter).then(function (ventes) {
                                $ctrl.onChangePagination(ventes);
                                eskUtils.setDataForCurrentRoute({
                                    dossiers: ventes,
                                    routeBack: {
                                        name: ng2RouterStateForNgService.current.name!,
                                        params: ng2RouterStateForNgService.params as unknown as string,
                                    },
                                    search: $ctrl.search,
                                });
                                $ctrl.ventes = ventes;
                            });
                        })().finally(function () {
                            $ctrl.options.creatingMessage = undefined;
                            $ctrl.options.loader.updating = false;
                        });
                    });
            }

            /**
             * Change view mode
             *
             * @param displayMode
             */
            function changeDisplayMode(displayMode: DisplayMode) {
                $ctrl.options.displayMode = displayMode;
            }

            /**
             * Update Ventes list
             *
             * @param ventes
             * @returns {Array}
             */
            function onChangePagination(ventes: NgVentesCollection) {
                angular.forEach(ventes.collection, function (vente) {
                    vente._esk.routing = '/app/estimations/references/details/' + vente.id.toString();
                });

                return ventes;
            }
        }
    })(angularJS);
}
