import {inject, Injectable} from '@angular/core';
import Bien from '@models/bien/bien.model';
import {UserService} from '@models/users/user/user.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {BienFactory} from '@models/bien/bien.factory';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';

@Injectable({providedIn: 'root'})
export class BienService {
    private _bienFactory = inject(BienFactory);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _userService = inject(UserService);

    createVirgin$(): Observable<Bien> {
        const bien = this._bienFactory.createVirgin();

        bien.contactsGroup = this._contactsGroupFactory.createVirgin();
        bien.zoneRisques = true;

        return this._userService.last$.pipe(
            tap(user => bien.linkNotaire = user.defaultNotaire?.linkSelf),
            tap(user => bien.linkResponsableDossier = user.linkSelf),
            tap(user => bien.responsableDossierId = user.id),
            map(_ => bien),
        );
    }
}
