<div appProvideParentForm>
  @if (showPanelForBien('diagnostics')) {
    <fieldset>
      <legend>Diagnostics</legend>

      @if (showFieldForBien(FIELDS.performanceEnergetique)) {
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="row">
              <div class="form-group col-xs-12 col-sm-6 col-md-8" [appFormError]="performanceEnergetiqueDate.formInput">
                <label>
                  Date d'établissement
                  <a href="https://www.economie.gouv.fr/particuliers/immobilier-diagnostic-performance-energetique-dpe#dpeckoi"
                     target="_blank"><fa-icon [icon]="'external-link-alt'"/></a>
                </label>

                <div class="input-group input-group-date">
                  <app-form-select-date [model]="dossierBien.bien.performanceEnergetique.date"
                                        (selected)="onSelectPerformanceEnergetiqueDate($event)"
                                        [options]="options.performanceEnergetiqueDate"
                                        #performanceEnergetiqueDate="formSelectDate"/>
                </div>
              </div>

              <div class="col-xs-8 col-sm-6 col-md-4">
                <div class="form-group" [appBienEditConstraints]="performanceEnergetique.formInput">
                  <label>Statut</label>

                  <app-dictionary-select [model]="dossierBien.bien.performanceEnergetique.statutDpe"
                                         [name]="DICTIONARY_NAME_DIAGNOSTIC_STATUTS"
                                         (selected)="onSelectPerformanceEnergetiqueStatutDpe($event)"
                                         [options]="{name: 'performanceEnergetique'}"
                                         #performanceEnergetique="dictionarySelect"/>
                </div>
              </div>
            </div>

            @if (dossierBien.bien.performanceEnergetique.date && dossierBien.bien.performanceEnergetique.isDisponible()) {
              <div>
                <div class="form-group">
                  <div [ngClass]="{'has-error': hasErrorOnConstraints(['performanceEnergetiqueConsommation', 'performanceEnergetiqueConsommationClasse'])}">
                    <label for="performanceEnergetique-consommation">
                      Consommation énergétique
                      {{ hasConstraints(['performanceEnergetiqueConsommation', 'performanceEnergetiqueConsommationClasse']) ? '*' : '' }}
                    </label>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-8"
                         [appBienEditConstraints]="performanceEnergetiqueConsommation">
                      <div class="input-group input-group-number">
                        <input type="text" id="performanceEnergetique-consommation" class="form-control text-right"
                               pattern="[0-9]+" min="0" (input)="updateBienPerformanceEnergetique()"
                               [(ngModel)]="dossierBien.bien.performanceEnergetique.consommation" placeholder="Valeur"
                               name="performanceEnergetiqueConsommation" #performanceEnergetiqueConsommation="ngModel">

                        <span class="input-group-addon">kWh / m<sup>2</sup> / an</span>
                      </div>

                      <p class="help-block margin-top-0 margin-bottom-0">Kilowatt-heure par mètre carré par an</p>
                    </div>

                    @if (isDiagV2) {
                      <div class="col-xs-12 col-sm-6 col-md-4 tw-relative">
                        <div class="tw-max-w-[6rem]"
                             [appBienEditConstraints]="performanceEnergetiqueConsommationClasse.formInput">
                          <app-dictionary-select [model]="dossierBien.bien.performanceEnergetique.consommationClasse!"
                                                 [name]="DICTIONARY_NAME_PERFORMANCE_ENERGETIQUE_CLASSES"
                                                 (selected)="onSelectPerformanceEnergetiqueConsommationClasse($event)"
                                                 [options]="{name: 'performanceEnergetiqueConsommationClasse', required: true}"
                                                 #performanceEnergetiqueConsommationClasse="dictionarySelect"/>

                          <div class="tw-absolute tw-top-1 tw-left-28">
                            <soq-popover-text [display]="'default'" [placement]="'left'" [trigger]="'outsideClick'"
                                              [text]="'La lettre est calculée automatiquement avec la valeur de consommation ainsi que la lettre d\'émissions et en fonction de la surface du bien. Vous pouvez l\'adapter si besoin.'"/>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <div class="form-group">
                  <div [ngClass]="{'has-error': hasErrorOnConstraints(['performanceEnergetiqueEmissions', 'performanceEnergetiqueEmissionsClasse'])}">
                    <label for="performanceEnergetique-emissions">
                      Émissions de gaz à effet de serre
                      {{ hasConstraints(['performanceEnergetiqueEmissions', 'performanceEnergetiqueEmissionsClasse']) ? '*' : '' }}
                    </label>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-8" [appBienEditConstraints]="performanceEnergetiqueEmissions">
                      <div class="input-group input-group-number">
                        <input type="text" id="performanceEnergetique-emissions" class="form-control text-right"
                               pattern="[0-9]+" min="0" (input)="updateBienPerformanceEnergetique()"
                               [(ngModel)]="dossierBien.bien.performanceEnergetique.emissions" placeholder="Valeur"
                               name="performanceEnergetiqueEmissions" #performanceEnergetiqueEmissions="ngModel">

                        <span class="input-group-addon">kg CO<sub>2</sub> / m<sup>2</sup> / an</span>
                      </div>

                      <p class="help-block margin-top-0 margin-bottom-0">
                        Kilogramme de CO<sub>2</sub> par mètre carré par an
                      </p>
                    </div>

                    @if (isDiagV2) {
                      <div class="col-xs-12 col-sm-6 col-md-4 tw-relative">
                        <div class="tw-max-w-[6rem]"
                             [appBienEditConstraints]="performanceEnergetiqueEmissionsClasse.formInput">
                          <app-dictionary-select [model]="dossierBien.bien.performanceEnergetique.emissionsClasse!"
                                                 [name]="DICTIONARY_NAME_PERFORMANCE_ENERGETIQUE_CLASSES"
                                                 (selected)="onSelectPerformanceEnergetiqueEmissionsClasse($event)"
                                                 [options]="{name: 'performanceEnergetiqueEmissionsClasse', required: true}"
                                                 #performanceEnergetiqueEmissionsClasse="dictionarySelect"/>

                          <div class="tw-absolute tw-top-1 tw-left-28">
                            <soq-popover-text [display]="'default'" [placement]="'left'" [trigger]="'outsideClick'"
                                              [text]="'La lettre est calculée automatiquement avec la valeur d\'émissions et en fonction de la surface du bien. Vous pouvez l\'adapter si besoin.'"/>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                @if (isDiagV2) {
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-8">
                      <div [ngClass]="{'has-error': hasErrorOnConstraints(['performanceEnergetiqueCoutMin', 'performanceEnergetiqueCoutMax'])}">
                        <label for="bien-diag-estimation-min">Estimation des coûts annuels d’énergie</label>
                      </div>

                      <div class="pull-left" [appFormError]="performanceEnergetiqueCoutMin">
                        <div class="input-group input-group-number input-group-currency">
                          <input type="text" id="bien-diag-estimation-min" class="form-control text-right"
                                 placeholder="Minimum" [(ngModel)]="dossierBien.bien.performanceEnergetique.coutMin"
                                 pattern="[0-9]+" min="0" (input)="updateBienPerformanceEnergetique()"
                                 name="performanceEnergetiqueCoutMin" #performanceEnergetiqueCoutMin="ngModel">

                          <span class="input-group-addon">€</span>
                        </div>
                      </div>

                      <div class="pull-left" [appFormError]="performanceEnergetiqueCoutMax">
                        <div class="input-group input-group-number input-group-currency">
                          <input [(ngModel)]="dossierBien.bien.performanceEnergetique.coutMax" type="text"
                                 placeholder="Maximum" class="form-control text-right"
                                 #performanceEnergetiqueCoutMax="ngModel" pattern="[0-9]+" min="0"
                                 (input)="updateBienPerformanceEnergetique()" name="performanceEnergetiqueCoutMax">

                          <span class="input-group-addon">€</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-4">
                      <div class="form-group" [appFormError]="performanceEnergetiqueAnneeReference">
                        <label for="bien-annee-reference">Année de référence</label>

                        <input type="text" id="bien-annee-reference" class="form-control text-right" min="0"
                               maxlength="4" pattern="[0-9]+" [(ngModel)]="bienPerformanceEnergetiqueAnneeReference"
                               placeholder="Année" #performanceEnergetiqueAnneeReference="ngModel"
                               name="performanceEnergetiqueAnneeReference"
                               (input)="updateBienPerformanceEnergetiqueAnneeReference()">
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>

          @if (dossierBien.bien.performanceEnergetique.date && dossierBien.bien.performanceEnergetique.isDisponible()) {
            <div class="col-xs-12 col-md-6">
              <app-diag-graphes [bien]="dossierBien.bien" [options]="{estimationCoutsVisibility: false}"/>
            </div>
          }

          <div class="form-group col-xs-12">
            <label for="bien-diag-com">Commentaires</label>

            <textarea id="bien-diag-com" [(ngModel)]="dossierBien.bien.performanceEnergetique.comments"
                      (input)="updateBienPerformanceEnergetique()" name="performanceEnergetiqueComments"
                      class="form-control" [minHeight]="100">
            </textarea>
          </div>
        </div>
      }

      @if (showFieldForBien(FIELDS.performanceEnergetique)) {
        <hr>
      }

      <div class="row">
        <div class="col-xs-12 col-md-6">
          @if (ngBienDossier.bien.diagnostics.length > 0) {
            <table class="table table-collection">
              <thead><tr><th>Type de diagnostic *</th><th>Date</th><th></th></tr></thead>

              <tbody>
                @for (bienDiagnostic of ngBienDossier.bien.diagnostics; track bienDiagnostic; let idx = $index) {
                  <tr class="item"
                      [appCollectionSortable]="[AppLayoutBienEditFormalitesDiagnosticsName, ngBienDossier.bien.diagnostics, bienDiagnostic.uuid]">
                    <td class="col-xs-6" [appCollectionItemError]="[bienDiagnostic, bienDiagnosticType.formInput]">
                      <app-dictionary-select #bienDiagnosticType="dictionarySelect"
                                             [ngItem]="bienDiagnostic.typeDiagnostic"
                                             [options]="{name: 'diagnostic' + bienDiagnostic.uuid, required: !!bienDiagnostic.uuid}"
                                             (selected)="onSelectDiagnosticType(bienDiagnostic, $event)"
                                             [name]="DICTIONARY_NAME_DIAGNOSTIC_TYPES"/>
                    </td>

                    <td class="col-xs-4">
                      <app-form-select-date [model]="bienDiagnostic.date"
                                            [options]="options.diagnosticDate(bienDiagnostic)"
                                            (selected)="onSelectDiagnosticDate(bienDiagnostic, $event)"/>
                    </td>

                    <td class="col-xs-2 other tw-text-center">
                      @if (!!bienDiagnostic.uuid) {
                        @if (!bienDiagnostic._esk.loading) {
                          <button type="button" class="btn btn-o btn-null" (click)="editDiagnostic(bienDiagnostic)">
                            <fa-icon [icon]="'edit'"/>
                          </button>

                          <button type="button" title="Supprimer la pièce" class="btn btn-o text-large"
                                  (click)="removeDiagnostic(bienDiagnostic)">
                            <fa-icon [icon]="'trash-alt'"/>
                          </button>
                        } @else {
                          <fa-icon [icon]="'spinner'" animation="spin"/>
                        }
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          }

          @if (ngBienDossier.bien.diagnostics.length <= 0) {
            <div class="tw-py-4 tw-text-center">Aucun diagnostic saisi</div>
          }

          <div class="tw-flex tw-justify-center tw-py-4">
            @if (ngBienDossier.uuid || ngBienDossier.bien.diagnostics.length === 0) {
              <button type="button" class="tw-btn-secondary-info" (click)="addNewDiagnostic()">
                <fa-icon [icon]="'plus'"/> <span>Ajouter un diagnostic</span>
              </button>
            } @else {
              Enregistrer votre bien une première fois afin de pouvoir saisir plus de diagnostics.
            }
          </div>
        </div>
      </div>
    </fieldset>
  }

  @if (showPanelForBien('loiAlur')) {
    <fieldset>
      <legend>Loi ALUR</legend>

      <!-- Loi ALUR -->
      <div class="form-group">
        <div class="checkbox clip-check check-primary checkbox-inline">
          <input type="checkbox" id="bien-loi-alur" name="loiAlur"
                 [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.loiAlur">

          <label for="bien-loi-alur">Loi ALUR</label>
        </div>
      </div>

      @if (ngBienDossier.bien.metadata.loiAlur.loiAlur) {
        <div class="row">
          <!-- Nombre de lot -->
          @if (showFieldForBien(FIELDS.nombreLotsLoiAlur)) {
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appFormError]="nombreLotsLoiAlur">
                <label for="bien-nombre-lots-loi-alur">Nombre de lots</label>

                <input type="text" id="bien-nombre-lots-loi-alur" class="form-control text-right" pattern="[0-9]+"
                       min="0" name="nombreLotsLoiAlur" #nombreLotsLoiAlur="ngModel"
                       [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.nombreLots">
              </div>
            </div>
          }

          <!-- Nombre de co-propriétaires -->
          @if (showFieldForBien(FIELDS.nombreCoproprietairesLoiAlur)) {
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appFormError]="nombreCoproprietairesLoiAlur">
                <label for="bien-nombre-coproprietaires-loi-alur">Nombre de copropriétaires</label>

                <input type="text" id="bien-nombre-coproprietaires-loi-alur" class="form-control text-right"
                       min="0" name="nombreCoproprietairesLoiAlur" #nombreCoproprietairesLoiAlur="ngModel"
                       pattern="[0-9]+" [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.nombreCoproprietaires">
              </div>
            </div>
          }

          <!-- Coordonnées du Syndic -->
          @if (showFieldForBien(FIELDS.coordonneesSyndicLoiAlur)) {
            <div class="form-group col-xs-12">
              <label for="bien-coordonnees-syndic-loi-alur">Coordonnées du Syndic</label>

              <textarea class="form-control" name="{{FIELDS.coordonneesSyndicLoiAlur}}" [minHeight]="100"
                        placeholder="Saisir les coordonnées du syndic" id="bien-coordonnees-syndic-loi-alur"
                        [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.coordonneesSyndic"></textarea>
            </div>
          }

          <!-- Montant moyen annuel de la quote-part -->
          @if (showFieldForBien(FIELDS.montantQuotePartLoiAlur)) {
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appFormError]="montantQuotePartLoiAlur">
                <label for="bien-mtt-annuel-quotepart-loi-alur">Montant moyen annuel de la quote-part</label>

                <div class="input-group input-group-number">
                  <input type="text" id="bien-mtt-annuel-quotepart-loi-alur" class="form-control text-right"
                         pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" #montantQuotePartLoiAlur="ngModel"
                         name="montantQuotePartLoiAlur"
                         [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.montantQuotePart">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          }

          <!-- Montant des travaux en cours ou votés -->
          @if (showFieldForBien(FIELDS.montantTravauxLoiAlur)) {
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appFormError]="montantTravauxLoiAlur">
                <label for="bien-mtt-travaux-loi-alur">Montant des travaux en cours ou votés</label>

                <div class="input-group input-group-number">
                  <input type="text" id="bien-mtt-travaux-loi-alur" class="form-control text-right"
                         pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" name="montantTravauxLoiAlur"
                         #montantTravauxLoiAlur="ngModel"
                         [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.montantTravaux">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          }

          <!-- Plan de sauvegarde -->
          @if (showFieldForBien(FIELDS.planSauvegardeLoiAlur)) {
            <div class="form-group col-xs-12 col-sm-6 col-md-3">
              <div class="checkbox clip-check check-primary checkbox-inline">
                <input type="checkbox" id="bien-plan-sauvegarde-loi-alur" name="planSauvegardeLoiAlur"
                       [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.planSauvegarde">

                <label for="bien-plan-sauvegarde-loi-alur">Plan de sauvegarde</label>
              </div>
            </div>
          }

          <!-- Administration provisoire du Syndic -->
          @if (showFieldForBien(FIELDS.administrationSyndicLoiAlur)) {
            <div class="form-group col-xs-12 col-sm-6 col-md-3">
              <div class="checkbox clip-check check-primary checkbox-inline">
                <input type="checkbox" id="bien-admin-provisoire-syndic-loi-alur"
                       name="{{FIELDS.administrationSyndicLoiAlur}}"
                       [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.administrationSyndic">

                <label for="bien-admin-provisoire-syndic-loi-alur">Administration provisoire du Syndic</label>
              </div>
            </div>
          }

          <!-- Commentaires procédure loi ALUR -->
          @if (showFieldForBien(FIELDS.commentsLoiAlur)) {
            <div class="form-group col-xs-12">
              <label for="bien-comments-loi-alur">Commentaires procédures loi ALUR</label>

              <textarea class="form-control" name="{{FIELDS.commentsLoiAlur}}" id="bien-comments-loi-alur"
                        [(ngModel)]="ngBienDossier.bien.metadata.loiAlur.comments" [minHeight]="100"></textarea>
            </div>
          }
        </div>
      }
    </fieldset>
  }

  @if (showPanelForBien('habitation')) {
    <fieldset>
      <legend>Habitation</legend>

      <div class="row">
        <!-- Usage du bien -->
        @if (showFieldForBien(FIELDS.usage)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-3">
            <label>Usage du bien</label>

            <app-dictionary-select [model]="bienUsage" [name]="DICTIONARY_NAME_USAGES" [options]="{name: 'usage'}"
                                   (selected)="onSelectUsage($event)"/>
          </div>
        }

        <!-- Type de résidence -->
        @if (showFieldForBien(FIELDS.residence)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-3">
            <label>Type de résidence</label>

            <app-dictionary-select [model]="bienResidence" [name]="DICTIONARY_NAME_RESIDENCES"
                                   [options]="{name: 'residence'}" (selected)="onSelectResidence($event)"/>
          </div>
        }
        <!-- Résidence service -->
        @if (showFieldForBien(FIELDS.residenceService)) {
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group" [appBienEditConstraints]="residenceService.formInput">
              <label>Résidence service</label>

              <app-form-radio-ynn [model]="ngBienDossier.bien.option.residenceService" #residenceService="formRadioYnn"
                                  (selected)="ngBienDossier.bien.option.residenceService = $event"
                                  [options]="{genre: 'female', name: 'residenceService'}"/>
            </div>
          </div>
        }
      </div>

      @if (showPanelForBien('situationLocative') && ngBienDossier.bien.option.occupation) {
        <div class="row">
          <!-- Occupation -->
          @if (showFieldForBien(FIELDS.occupation)) {
            <div class="form-group col-xs-12 col-sm-6 col-md-3">
              <label>Occupation du bien</label>

              <app-dictionary-select [options]="{name: 'occupation'}" (selected)="onSelectOccupation($event)"
                                     [model]="bienOccupation" [name]="DICTIONARY_NAME_OCCUPATIONS"/>
            </div>
          }

          <!-- Date de disponibilité -->
          @if (showFieldForBien(FIELDS.dateDisponibilite) && ngBienDossier.bien.option.occupation.id !== 2) {
            <div class="form-group col-xs-12 col-sm-6 col-md-3">
              <label>Date de disponibilité</label>

              <div class="input-group input-group-date">
                <app-form-select-date [model]="ngBienDossier.bien.metadata.occupation.dateDisponibilite"
                                      [options]="{inputClass: 'form-control'}"
                                      (selected)="onSelectDateDisponibilite($event)"/>
              </div>
            </div>
          }

          <!-- Loyer actuel -->
          @if (showFieldForBien(FIELDS.loyerActuel) && ngBienDossier.bien.option.occupation.id !== 1) {
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appFormError]="loyerActuel">
                <label for="bien-loyer-actuel">Loyer actuel</label>

                <div class="input-group input-group-number input-group-currency">
                  <input [(ngModel)]="ngBienDossier.bien.metadata.occupation.loyerActuel" id="bien-loyer-actuel"
                         type="text" class="form-control text-right" name="loyerActuel"
                         pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" #loyerActuel="ngModel">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          }

          <!-- Charges locatives -->
          @if (showFieldForBien(FIELDS.chargesLocatives) && ngBienDossier.bien.option.occupation.id !== 1) {
            <div class="form-group col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appFormError]="chargesActuelles">
                <label for="bien-charges-locatives">Charges locatives</label>

                <div class="input-group input-group-number input-group-currency">
                  <input [(ngModel)]="ngBienDossier.bien.metadata.occupation.chargesActuelles" type="text"
                         class="form-control text-right" id="bien-charges-locatives" name="chargesActuelles"
                         pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" #chargesActuelles="ngModel">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </fieldset>
  }

  <fieldset>
    <legend>Coûts</legend>

    <div class="row">
      <!-- Charges -->
      @if (showFieldForBien(FIELDS.charges)) {
        <div class="col-xs-12 col-md-6">
          @if (ngBienDossier.bien.charges.length > 0) {
            <table class="table table-collection">
              <thead><tr><th>Type de charge *</th><th>Périodicité *</th><th>Coût</th><th></th></tr></thead>

              <tbody>
                @for (bienCharge of ngBienDossier.bien.charges; track bienCharge; let idx = $index) {
                  <tr class="item"
                      [appCollectionSortable]="[AppLayoutBienEditFormalitesChargesName, ngBienDossier.bien.charges, bienCharge.uuid]">
                    <td class="col-xs-5" [appCollectionItemError]="[bienCharge, bienChargeTypeCharge.formInput]">
                      <app-dictionary-select #bienChargeTypeCharge="dictionarySelect" [name]="DICTIONARY_NAME_CHARGES"
                                             [ngItem]="bienCharge.charge"
                                             (selected)="onSelectChargeType(bienCharge, $event)"
                                             [options]="{name: 'charge' + bienCharge.uuid, required: !!bienCharge.uuid}"/>
                    </td>

                    <td class="col-xs-3" [appCollectionItemError]="[bienCharge, bienChargePeriodicite.formInput]">
                      <app-dictionary-select #bienChargePeriodicite="dictionarySelect"
                                             [name]="DICTIONARY_NAME_PERIODICITES" [ngItem]="bienCharge.periodicite"
                                             [options]="{name: 'periodicite' + bienCharge.uuid, required: !!bienCharge.uuid}"
                                             (selected)="onSelectChargePeriodicite(bienCharge, $event)"/>
                    </td>

                    <td class="col-xs-2" [appCollectionItemError]="[bienCharge, cout]">
                      <span class="num-icon md:!tw-top-[34%] lg:!tw-top-[39%] 2xl:!tw-top-1/3">€</span>

                      <input type="text" [name]="'cout' + bienCharge.uuid" #cout="ngModel" class="form-control number"
                             pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" [(ngModel)]="ngBienDossier.bien.charges[idx].cout"
                             (blur)="saveCharge(bienCharge)">
                    </td>

                    <td class="col-xs-2 other tw-text-center">
                      @if (!!bienCharge.uuid) {
                        @if (!bienCharge._esk.loading) {
                          <button type="button" class="btn btn-o btn-null" (click)="editCharge(bienCharge)">
                            <fa-icon [icon]="'edit'"/>
                          </button>

                          <button type="button" title="Supprimer la charge" class="btn btn-o text-large"
                                  (click)="removeCharge(bienCharge)"><fa-icon [icon]="'trash-alt'"/></button>
                        } @else {
                          <fa-icon [icon]="'spinner'" animation="spin"/>
                        }
                      }
                    </td>
                  </tr>
                }

                <!-- Ligne nécessaire au bon fonctionnement de bienFieldConstraints et la contrainte sur les charges de copropriété -->
                <tr>
                  <td colspan="5">
                    <div [appBienEditConstraints]="chargesCopropriete" class="tw-text-center">
                      <input name="chargesCopropriete" [(ngModel)]="ngBienDossier.bien.charges"
                             #chargesCopropriete="ngModel" class="tw-invisible tw-h-0 tw-w-0 tw-float-left">
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          }

          @if (ngBienDossier.bien.charges.length <= 0) {
            <div class="tw-py-4 tw-text-center">Aucune charge saisie</div>
          }

          <div class="tw-flex tw-justify-center tw-py-4">
            @if (ngBienDossier.uuid || ngBienDossier.bien.charges.length === 0) {
              <button type="button" class="tw-btn-secondary-info" (click)="addNewCharge()">
                <fa-icon [icon]="'plus'"/> <span>Ajouter une charge</span>
              </button>
            } @else {
              Enregistrer votre bien une première fois afin de pouvoir saisir plus de charges.
            }
          </div>
        </div>
      }

      <!-- Renseignement sur les couts -->
      @if (showFieldForBien(FIELDS.commentsCouts)) {
        <div class="form-group col-xs-12 col-md-6">
          <label for="bien-comments-couts">Renseignements coûts</label>

          <textarea class="form-control" name="{{FIELDS.commentsCouts}}" id="bien-comments-couts"
                    [(ngModel)]="ngBienDossier.bien.metadata.couts.comments" [minHeight]="100"></textarea>
        </div>
      }
    </div>
  </fieldset>

  @if (showPanelForBien('urbanisme')) {
    <fieldset>
      <legend>Urbanisme</legend>

      @if (showFieldForBien(FIELDS.urbanismeZonePLU)) {
        <div class="form-group">
          <label for="bien-urbanisme-zone-plu">Zone PLU</label>

          <textarea class="form-control" name="urbanismeZonePLU" id="bien-urbanisme-zone-plu"
                    [(ngModel)]="ngBienDossier.bien.option.urbanismeZonePLU" [minHeight]="100"></textarea>
        </div>
      }

      @if (showFieldForBien(FIELDS.commentsUrbanisme)) {
        <textarea class="form-control" name="urbanismeComments"
                  placeholder="Saisir les informations complémentaires à l'urbanisme"
                  [(ngModel)]="ngBienDossier.bien.option.urbanismeComments" [minHeight]="100"></textarea>
      }
    </fieldset>
  }

  @if (showPanelForBien('risques')) {
    <fieldset>
      <legend>Risques</legend>

      <div class="form-group">
        <div class="checkbox clip-check check-primary checkbox-inline">
          <input type="checkbox" id="bien-risques-zone" name="risquesZone"
                 [(ngModel)]="ngBienDossier.bien.option.risquesZone">

          <label for="bien-risques-zone">Bien dans une zone à risques</label>
        </div>
      </div>

      @if (ngBienDossier.bien.option.risquesZone) {
        <p class="margin-bottom-20">
          Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site
          <a href="https://www.georisques.gouv.fr" target="_blank">
            Géorisques <fa-icon [icon]="'external-link-alt'"/>
          </a>.
        </p>

        @if (showFieldForBien(FIELDS.commentsRisques)) {
          <textarea class="form-control" name="risquesComments" [minHeight]="100"
                    placeholder="Saisir les informations complémentaires aux risques"
                    [(ngModel)]="ngBienDossier.bien.option.risquesComments"></textarea>
        }
      }
    </fieldset>
  }
</div>
