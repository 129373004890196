export default `
<form name="$ctrl.editForm" class="form-login" ng-submit="$ctrl.signin()" novalidate>
  <legend>Connexion à votre espace</legend> <p>Merci de de saisir votre email et mot de passe personnel.</p>

  <!-- Username -->
  <div class="form-group" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.username.$invalid}">
    <span class="input-icon">
      <input type="text" name="username" class="form-control" required="required"
             placeholder="Nom d'utilisateur / Adresse email" ng-model="$ctrl.username">

      <span class="icon"><fa-icon [icon]="'user'" size="2x"></fa-icon></span>
    </span>

    <div class="error text-small tw-block" ng-messages="$ctrl.editForm.username.$error"
         ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.username.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>

  <!-- Password -->
  <div class="form-group form-actions"
       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.password.$invalid}">
    <span class="input-icon">
      <input type="password" class="form-control password" name="password" required="required"
             placeholder="{{'Mot de passe'|translate}}" ng-model="$ctrl.password">

      <span class="icon"><fa-icon [icon]="'lock'" size="2x"></fa-icon></span>
    </span>

    <div class="error text-small tw-block" ng-messages="$ctrl.editForm.password.$error"
         ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.password.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>

    <!-- @todo Transformer en bouton -->
    <a class="small" ng-click="$ctrl.requestPassword()">J'ai oublié mon mot de passe</a>
  </div>

  <p class="text-danger" ng-if="$ctrl.message">
    <fa-icon [icon]="'times-circle'"></fa-icon> {{$ctrl.message | translate}}
  </p>

  <p ng-show="$ctrl.pending">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.DEFAULT' | translate}}
  </p>

  <div class="form-actions">
    <div class="checkbox clip-check check-primary">
      <input type="checkbox" id="remember" value="1" ng-model="$ctrl.remember">

      <label for="remember" title="Cochez cette case pour rester connecté sur ce poste.">Restez connecté</label>
    </div>

    <button type="submit" class="btn material-btn-primary pull-right" ng-disabled="$ctrl.pending">
      Se connecter <fa-icon [icon]="'arrow-circle-right'"></fa-icon>
    </button>
  </div>
</form>
`;
