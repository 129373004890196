import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getSoqModalesManager from "@legacy/app/soqrate/services/manager-modales";
import getSoqSweetAlert from "@legacy/app/soqrate/services/sweet-alert";
import getSoqValidation from "@legacy/app/soqrate/services/validation";
import getSoqTemplates from "@legacy/app/soqrate/templates";
import getSoqPhone from "@legacy/app/soqrate/components/phone";
import getSoqTransformation from "@legacy/app/soqrate/services/transformation";
import getSoqFacebook from '@legacy/app/soqrate/directives/facebook';
import getSoqPanel from '@legacy/app/soqrate/directives/panel';
import getSoqChart from '@legacy/app/soqrate/directives/chart';
import getSoqMultiple from '@legacy/app/soqrate/directives/multiple';
import getSoqInputRadioYnn from '@legacy/app/soqrate/components/input-radio-ynn';
import getSoqGmapsFreeDraw from '@legacy/app/soqrate/components/gmaps.free-draw';
import getSoqDiagnostics from '@legacy/app/soqrate/directives/diagnostic';
import getSoqDpeValue from '@legacy/app/soqrate/components/dpe-value';
import getSoqInputPhotos from '@legacy/app/soqrate/components/input-photos';
import getSoqInputFile from '@legacy/app/soqrate/directives/input-file';
import getSoqCapitalizeInput from '@legacy/app/soqrate/directives/capitalize-input';
import getSoqOnlyDigits from '@legacy/app/soqrate/directives/only-digits';
import getSoqPreventTouchMove from '@legacy/app/soqrate/directives/prevent-touch-move';
import getSoqRadioChecked from '@legacy/app/soqrate/directives/radio-checked';
import getSoqPopover from '@legacy/app/soqrate/components/popover';

export default function getSoqrate(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular.module('soqrate', ['ui.bootstrap']);

        // Config / Run
        getSoqTemplates(module);

        // Services
        getSoqModalesManager(module);
        getSoqSweetAlert(module);
        getSoqTransformation(module);
        getSoqValidation(module);

        // Directives
        getSoqCapitalizeInput(module);
        getSoqChart(module);
        getSoqDiagnostics(module);
        getSoqFacebook(module);
        getSoqInputFile(module);
        getSoqMultiple(module);
        getSoqOnlyDigits(module);
        getSoqPanel(module);
        getSoqPreventTouchMove(module);
        getSoqRadioChecked(module);

        // Components
        getSoqDpeValue(module);
        getSoqGmapsFreeDraw(module);
        getSoqInputPhotos(module);
        getSoqInputRadioYnn(module);
        getSoqPhone(module);
        getSoqPopover(module);

        return module;
    })(angularJS);
}
