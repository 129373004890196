import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {DiagnosticLettersConst} from '@shared/diagnostic/diagnostic.constants';

export default function getSoqDpeValue(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <soq-dpe-value></soq-dpe-value>
         */
        module.component('soqDpeValue', {
            bindings: {value: '='},
            controller: Controller,
            template: '{{$ctrl.dpeCode}}'
        });

        /**
         * Manage collection
         *
         * @constructor
         */
        Controller.$inject = [] as string[];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.dpeCode = 'N.C.';

                if ($ctrl.value >= 0 && $ctrl.value <= 50) {
                    $ctrl.dpeCode = DiagnosticLettersConst.A;
                }

                if ($ctrl.value >= 51 && $ctrl.value <= 90) {
                    $ctrl.dpeCode = DiagnosticLettersConst.B;
                }

                if ($ctrl.value >= 91 && $ctrl.value <= 150) {
                    $ctrl.dpeCode = DiagnosticLettersConst.C;
                }

                if ($ctrl.value >= 151 && $ctrl.value <= 230) {
                    $ctrl.dpeCode = DiagnosticLettersConst.D;
                }

                if ($ctrl.value >= 231 && $ctrl.value <= 330) {
                    $ctrl.dpeCode = DiagnosticLettersConst.E;
                }

                if ($ctrl.value >= 331 && $ctrl.value <= 450) {
                    $ctrl.dpeCode = DiagnosticLettersConst.F;
                }

                if ($ctrl.value >= 451) {
                    $ctrl.dpeCode = DiagnosticLettersConst.G;
                }
            }
        }
    })(angularJS);
}
