import {AfterViewInit, Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgEstimationManager} from '@legacy/app/managers/managers';
import {NgEstimation} from '@legacy/app/managers/ressources';
import {NgForm} from '@angular/forms';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {delay, firstValueFrom, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';

@Component({selector: 'layout-estimation-edit', templateUrl: 'layout-estimation-edit.component.html'})
export class AppLayoutEstimationEditComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('editForm') editForm!: NgForm;

    private _apiImpersonationService = inject(ApiImpersonationService);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _estimationFactory = inject(EstimationFactory);
    private _estimationService = inject(EstimationService);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _estimation!: NgEstimation;
    private _hasConstraintOnPhotos = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _saveIsDone = false;
    private _saving = false;
    private _showSaveButton = true;

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService<NgBienDossierCurrent>('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService<NgContactsGroupManager>('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService<NgEstimationManager>('EstimationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngSoqSweetAlert(): NgSoqSweetAlert {
        return this._ngInjectorService.getService<NgSoqSweetAlert>('SoqSweetAlert');
    }

    get estimation(): NgEstimation {
        return this._estimation;
    }

    get hasConstraintOnPhotos(): boolean {
        return this._hasConstraintOnPhotos;
    }

    get saving(): boolean {
        return this._saving;
    }

    get showSaveButton(): boolean {
        return this._showSaveButton;
    }

    get STATUTS_ARCHIVE(): string {
        return Estimation.statuts.ARCHIVE;
    }

    canDeactivate(): Observable<boolean> {
        if (this._apiImpersonationService.isImpersonate()) {
            return of(true);
        }

        if (this._saveIsDone) {
            return of(true);
        }

        if (![Estimation.statuts.BROUILLON, Estimation.statuts.ARCHIVE].includes(this.estimation?.statut) && !this.editForm.valid) {
            this.editForm.onSubmit(undefined!);
            this._formEstimationNotValid();

            return of(false);
        }

        return of(true);
    }

    ngOnInit(): void {
        this.ngEstimationManager.current$.pipe(
            tap(_ => this._hasConstraintOnPhotos = this.ngBienDossierCurrent.hasConstraints('photos')),
            takeUntil(this._onDestroy$),
        ).subscribe(ngEstimation => this._estimation = ngEstimation);
        this._router.events.pipe(
            map(event => event as RouterEvent),
            filter(event => event instanceof NavigationStart),
            map((event: NavigationStart) => !event.url.endsWith('/edit/photos') && !event.url.endsWith('/edit/virtual_visit')),
            takeUntil(this._onDestroy$),
        ).subscribe(showSaveButton => this._showSaveButton = showSaveButton);
    }

    ngAfterViewInit(): void {
        this._estimationService.needToBeSaved$.pipe(
            filter(_ => !!this.estimation),
            takeUntil(this._onDestroy$),
        ).subscribe(_ => this.save(true));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        if (!this._apiImpersonationService.isImpersonate() && !this._saveIsDone && this.estimation?.id) {
            this.save();
        }
    }

    save(ignoreSubmitted = false): void {
        const isEstimationNew = !this.estimation.id;

        if (this.estimation.statut === Estimation.statuts.ARCHIVE || this._saving) {
            return;
        }

        // Nouveau mais bouton Enregistrer pas cliqué
        if (isEstimationNew && !this.editForm.submitted) {
            return;
        }

        // Pas nouveau, bouton Enregistrer cliqué mais pas valide
        if ((this.estimation.statut !== Estimation.statuts.BROUILLON || (this.estimation.type.code === Estimation.types.EVALUATION && !!this.estimation.rapportId)) &&
            (this.editForm.submitted || ignoreSubmitted) && !this.editForm.valid) {
            this._formEstimationNotValid();
            return;
        }

        /*
                If (this.estimation.statut === Estimation.statuts.BROUILLON && this.editForm.submitted && !this.editForm.valid) {
                    return this._formEstimationNotValid();
                }
        */

        this._saveIsDone = true;
        this._saving = true;
        // Nécessaire pour que la récupération du nom du CG soit réalisé correctement car il y a un "debounceTime(500)" sur la frappe
        firstValueFrom(of(undefined).pipe(
            delay(600),
            switchMap(_ => this._contactsGroupFactory.save$(this._contactsGroupFactory.ngCreate(this.estimation.bien.contactsGroup))),
        )).then(contactsGroupSaved => this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))
            .then(contactsGroup => this.estimation.bien.contactsGroup = contactsGroup)
            .then(_ => firstValueFrom(this._estimationFactory.save$(this._estimationFactory.ngCreate(this.estimation))))
            .then(estimation => this.ngEstimationManager.getOneById(estimation.id))
            .then(ngEstimation => {
                this.ngEstimationManager.emitCurrent(ngEstimation);
                if (isEstimationNew) {
                    return this._router.navigateByUrl('/app/estimations/' + this.estimation.id.toString(), {replaceUrl: true});
                }

                return;
            }, _ => this._toasterService.error('Oups !', 'Une erreur est survenue lors de la mise à jour de votre bien en estimation.'))
            .finally(() => this._saving = false);
    }

    private _formEstimationNotValid(): void {
        this.ngSoqSweetAlert.warningMessage('Saisie des champs', 'Certains champs n\'ont pas de valeurs correctes.');

        return;
    }
}
