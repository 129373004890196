import {inject, Injectable} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgLocationManager} from '@legacy/app/managers/managers';
import {LocationFactory} from '@models/locations/location/location.factory';
import Location from '@models/locations/location/location.model';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LocationService {
    private _locationFactory = inject(LocationFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _needToBeSavedSource = new Subject<void>();
    private _needToBeSaved$ = this._needToBeSavedSource.asObservable();

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService('LocationManager');
    }

    get needToBeSaved$(): Observable<void> {
        return this._needToBeSaved$;
    }

    askToBeSaved(): void {
        this._needToBeSavedSource.next();
    }

    getCurrentFromNg(): Location {
        if (!this.ngLocationManager.current) {
            return undefined!;
        }

        return this._locationFactory.ngCreate(this.ngLocationManager.current);
    }
}
