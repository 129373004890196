import {inject, Injectable} from '@angular/core';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import BienCommodite from '@models/bien/commodite/bien-commodite.model';
import {NgBienCommodite} from '@legacy/app/managers/ressources';
import {IBienCommoditeApi} from '@models/bien/commodite/bien-commodite.interfaces';

@Injectable({providedIn: 'root'})
export class BienCommoditeFactory {
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienCommoditeApi: IBienCommoditeApi): BienCommodite {
        const bienCommodite: BienCommodite = new BienCommodite(bienCommoditeApi.uuid);

        bienCommodite.comments = bienCommoditeApi.comments;
        bienCommodite.distance = bienCommoditeApi.distance;
        if (bienCommoditeApi._embedded?.commodite) {
            bienCommodite.commodite = this._dictionaryItemFactory.create(bienCommoditeApi._embedded.commodite);
        }

        return bienCommodite;
    }

    ngCreate(ngBienCommodite: NgBienCommodite): BienCommodite {
        const bienCommodite: BienCommodite = new BienCommodite(ngBienCommodite.uuid);

        bienCommodite.comments = ngBienCommodite.comments;
        bienCommodite.distance = ngBienCommodite.distance;
        if (ngBienCommodite.commodite) {
            bienCommodite.commodite = this._dictionaryItemFactory.ngCreate(ngBienCommodite.commodite);
        }

        return bienCommodite;
    }
}
