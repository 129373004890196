export default `
<ng-switch on="!!$ctrl.bien.id">
  <h2 ng-switch-default class="tw-text-center">Enregistrez le bien avant de pouvoir ajouter des photos.</h2>

  <div ng-switch-when="true">
    <esk-upload-files-info type-file="photo" info-client-filter="$ctrl.infoClientFilter"
                           update-tasks="$ctrl.updateTasks" update-tasks-errors="$ctrl.updateTasksErrors">
    </esk-upload-files-info>

    <soq-input-photos get-input-photo="$ctrl.getInputPhoto"></soq-input-photos>

    <esk-bien-edit-photos bien="$ctrl.bien" ng-show="$ctrl.bien.photos.length > 0"></esk-bien-edit-photos>
  </div>
</ng-switch>
`;
