import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgEstimationReference} from '@legacy/app/managers/ressources';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEstimationReferenceManager} from '@legacy/app/managers/managers';
import {ModalService} from '@shared/modal/modal.service';
import {ReferenceFactory} from '@models/references/reference/reference.factory';
import {switchMap, take} from 'rxjs/operators';
import Reference from '@models/references/reference/reference.model';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import CReferences from '@models/references/collection/references.collection.model';
import {
    DCEstimationReferenceEditComponent
} from '@features/estimations/estimation/references/reference/edit/estimation.reference-edit.component';
import {
    EstimationReferenceFactory
} from '@models/estimations/estimation/references/reference/estimation-reference.factory';
import {DCReferenceEditComponent} from '@features/references/reference/edit/reference.edit.component';
import {of} from 'rxjs';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';
import {
    CEstimationReferencesFactory
} from '@models/estimations/estimation/references/collection/estimation-references.collection.factory';
import {NgEstimationReferencesCollection} from '@legacy/app/managers/collections';

export default function getEstimationReferencesCrud(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-estimation-references-crud></esk-estimation-references-crud>
         * @param options : {refresh(bool)}
         */
        module.component('eskEstimationReferencesCrud', {
            bindings: {estimation: '=', onChange: '=?', options: '=?',},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/item/references/crud.html'
        });

        /**
         * Controller for estimation references
         *
         * @param EstimationReferenceManager
         * @param SoqModalesManager
         * @param $translate
         * @param SoqSweetAlert
         * @param Ng2RouterStateForNgService
         * @param $q
         * @param Ng2ModalService
         * @param Ng2ReferenceFactory
         * @param Ng2EstimationFactory
         * @param Ng2EstimationReferenceFactory
         * @param Ng2CEstimationReferencesFactory
         */
        Controller.$inject = ['EstimationReferenceManager', 'SoqModalesManager', '$translate', 'SoqSweetAlert', 'Ng2RouterStateForNgService', '$q', 'Ng2ModalService', 'Ng2ReferenceFactory', 'Ng2EstimationFactory', 'Ng2EstimationReferenceFactory', 'Ng2CEstimationReferencesFactory'];
        function Controller(this: any,
                            estimationReferenceManager: NgEstimationReferenceManager,
                            soqModalesManager: NgSoqModalesManager,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $q: IQService,
                            ng2ModalService: ModalService,
                            ng2ReferenceFactory: ReferenceFactory,
                            ng2EstimationFactory: EstimationFactory,
                            ng2EstimationReferenceFactory: EstimationReferenceFactory,
                            ng2CEstimationReferencesFactory: CEstimationReferencesFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.editReference = editReference;
            $ctrl.importReferences = importReferences;
/*
            $ctrl.referenceAction = referenceAction;
*/
            $ctrl.removeReference = removeReference;
            $ctrl.searchReferences = searchReferences;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.loader = {updating: true};
                if (!angular.isObject($ctrl.options)) $ctrl.options = {};

                $ctrl.options.list = {edit: $ctrl.editReference, remove: $ctrl.removeReference};
                if (!angular.isFunction($ctrl.onChange)) $ctrl.onChange = function () {
                };

                updateReferences();
            }

/*
            function referenceAction({action, value}: {action: string, value: { estimationReference: EstimationReference, idx: number }}) {
                if (action === AppEstimationReferencesListComponent.actions.EDIT) {
                    editReference(value.estimationReference);
                } else if (action === AppEstimationReferencesListComponent.actions.REMOVE) {
                    removeReference(value.estimationReference);
                } else if (action === AppEstimationReferencesListComponent.actions.UPDATE_RANK) {
                    console.log('update rank', value.estimationReference, value);
                    //updateRankReference(estimationReference);
                }
            }
*/

            function updateReferences() {
                $ctrl.loader.updating = true;

                return estimationReferenceManager.getAll<NgEstimationReferencesCollection>($ctrl.estimation.id)
                    .then(ngEstimationReferencesCollection => $ctrl.references = ngEstimationReferencesCollection)
                    .then(ngEstimationReferencesCollection => $ctrl.cEstimationReferences = ng2CEstimationReferencesFactory.ngCreate(ngEstimationReferencesCollection))
                    .then(_ => $ctrl.cEstimationReferences$ = of($ctrl.cEstimationReferences))
                    .finally(() => {
                        $ctrl.onChange($ctrl.cEstimationReferences, !$ctrl.options.refresh);
                        $ctrl.loader.updating = false;
                    });
            }

/*
            function editReference(estimationReference: EstimationReference) {
                if (estimationReference) {
                    return ng2ModalService.open$<EstimationReference>(DCEstimationReferenceEditComponent, {estimationReference}, {align: 'top'}).pipe(
                        switchMap(estimationReference => {
                            if (!estimationReference) {
                                return of(undefined!);
                            }

                            return ng2EstimationReferenceFactory.save$(ng2EstimationFactory.ngCreate($ctrl.estimation), estimationReference);
                        }),
                        take(1),
                    ).subscribe(estimationReference => {
                        if (estimationReference) {
                            updateReferences();
                        }
                    });
                }
*/
            function editReference(ngEstimationReference: NgEstimationReference) {
                if (ngEstimationReference) {
                    ngEstimationReference._esk.loading = true;

                    return ng2ModalService.open$(DCEstimationReferenceEditComponent, {estimationReference: ng2EstimationReferenceFactory.ngCreate(ngEstimationReference)}, {align: 'top'}).pipe(
                        switchMap(estimationReference => {
                            if (!estimationReference) {
                                return of(undefined!);
                            }

                            return ng2EstimationReferenceFactory.save$(ng2EstimationFactory.ngCreate($ctrl.estimation), estimationReference as EstimationReference);
                        }),
                        take(1),
                    ).subscribe(reference => {
                        ngEstimationReference._esk.loading = false;
                        if (reference) {
                            updateReferences();
                        }
                    });
                }

                return ng2ModalService.open$(DCReferenceEditComponent, {reference: ng2ReferenceFactory.createVirgin()}, {align: 'top'}).pipe(
                    switchMap(reference => {
                        if (!reference) {
                            return of(undefined!);
                        }

                        return ng2ReferenceFactory.save$(reference as Reference);
                    }),
                    take(1),
                ).subscribe(reference => {
                    if (reference) {
                        linkReference(reference).then(_ => updateReferences());
                    }
                });
            }

            /**
             * Import references from file
             */
            function importReferences() {
                soqModalesManager.open<CReferences>('EstimationModalReferencesImport', {resolve: {nature: $ctrl.estimation.bien.nature}}).then(cReferences => {
                    if (!cReferences) return;

                    $q.all(cReferences.results.map(reference => linkReference(reference))).then(_ => updateReferences());
                });
            }

            /**
             * Link reference to estimation
             *
             * @param reference
             * @return {Object}
             */
            function linkReference(reference: Reference) {
                return estimationReferenceManager.createFromNg2(reference, $ctrl.estimation.id).save();
            }

/*
            function removeReference(estimationReference: EstimationReference) {
                soqSweetAlert.warningConfirmation($translate.instant('estimation.reference.remove.confirm.TITLE'), $translate.instant('estimation.reference.remove.confirm.MESSAGE', {type: estimationReference.sourceType}), {confirmButtonText: $translate.instant('estimation.reference.remove.confirm.CONFIRMATION')})
                    .then(() => firstValueFrom(ng2EstimationReferenceFactory.delete$(ng2EstimationFactory.ngCreate($ctrl.estimation), estimationReference)))
                    .then(() => updateReferences());
            }
*/
            function removeReference(estimationReference: NgEstimationReference) {
                estimationReference._esk.loading = true;
                soqSweetAlert.warningConfirmation($translate.instant('estimation.reference.remove.confirm.TITLE'), $translate.instant('estimation.reference.remove.confirm.MESSAGE', {type: estimationReference.sourceType}), {confirmButtonText: $translate.instant('estimation.reference.remove.confirm.CONFIRMATION')})
                    .then(() => estimationReference.remove())
                    .then(() => updateReferences())
                    .finally(() => estimationReference._esk.loading = false);
            }

            /**
             * Search references
             */
            function searchReferences() {
                ng2RouterStateForNgService.navigateByUrl('/app/estimations/' + $ctrl.estimation.id + '/evaluation/references/criteres');
            }
        }
    })(angularJS);
}
