@if (contactsGroup$ | async; as contactsGroup) {
  <div appProvideParentForm class="tw-space-y-8">
    @if (contactsGroup.members.length > 0) {
      <ul class="tw-flex tw-flex-wrap tw-justify-center tw-gap-4">
        @for (member of contactsGroup.members; track member) {
          <li [appCollectionSortable]="[AppDossierContactsGroupEditMembersName, contactsGroup.members, member.uuid]"
              class="tw-w-[calc(100%_-_1rem)] md:tw-w-[calc(50%_-_1rem)] xl:tw-w-[calc(33%_-_1rem)] 2xl:tw-w-[calc(25%_-_1rem)] tw-h-[4.75rem] tw-bg-gray-50 tw-rounded-md">
            <div class="tw-flex tw-items-center tw-pr-1 tw-w-px">
              <app-contact-card [contact]="member.contact" (clicked)="editContact(contactsGroup, $event)"
                                class="tw-flex-1 tw-py-2 tw-overflow-hidden"/>

              <button class="tw-btn-secondary-info tw-bg-transparent tw-shadow-none tw-ring-0 tw-h-full" type="button"
                      #buttonElement (click)="actionsMember(buttonElement, contactsGroup, member)">
                <fa-icon [icon]="'ellipsis-v'" size="xl"/>
              </button>
            </div>
          </li>
        }
      </ul>
    }

    <div class="tw-flex tw-justify-center">
      <button type="button" class="tw-btn-primary-info disabled:tw-waiting" (click)="openSearchModal()"
              [disabled]="searchModalProcessing">Rechercher ou créer un contact
      </button>
      <!-- En commentaire suite à l'incompréhension pour les utilisateurs, demander à l'équipe si il faut le remettre
      Voir également [disabled]="searchModalProcessing" pour le remettre en place si besoin
      <app-search-open (clicked)="openSearchModal()" class="tw-w-full sm:tw-w-2/3 md:tw-w-1/2 lg:tw-w-1/3"
      [options]="{placeHolder: 'Rechercher ou créer un contact'}"></app-search-open>
      -->
    </div>

    <hr/>

    <div class="tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row sm:tw-space-y-0">
      <div class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/4 tw-px-4" [appFormError]="contactsGroupNom">
        <label for="edit-contacts-group-nom">Nom du dossier *</label>

        <input id="edit-contacts-group-nom" name="contactsGroupNom" placeholder="Nom du dossier" type="text" required
               class="tw-input-text" [(ngModel)]="contactsGroup.nom" #contactsGroupNom="ngModel" [maxLength]="140"
               (input)="onChangeNom(contactsGroup)" (blur)="onChangeNomValidation(contactsGroup)">
      </div>

      <div class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/4 tw-px-4" [appFormError]="contactsGroupType.formInput">
        <label>Type de dossier *</label>

        <app-dictionary-select [name]="DICTIONARY_NAMES_CONTACTS_GROUP_TYPES" #contactsGroupType="dictionarySelect"
                               [model]="contactsGroup.typeGroup" [options]="{name: 'contactsGroupType', required: true}"
                               (selected)="onSelectTypeGroup($event, contactsGroup)"/>
      </div>
    </div>
  </div>
} @else {
  <app-loader class="tw-block" [classHeight]="'tw-h-40'"/>
}
