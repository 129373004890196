import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IFormFieldInput, IFormRadioOptions} from '@shared/form/form.interfaces';
import {NgModel} from '@angular/forms';

@Component({exportAs: 'formRadio', selector: 'app-form-radio', templateUrl: 'form.radio.component.html'})
export class AppFormRadioComponent {
    @Output() selected = new EventEmitter<IFormFieldInput>();

    static readonly initFormRadioOptions: IFormRadioOptions = {
        classInput: '',
        classLabel: '',
        // @todo Utiliser des class TW, "tw-input-radio" ?
        classList: 'radio-inline radio clip-radio radio-primary',
        name: 'formRadioInput',
    };
    private _formInput!: NgModel;
    private _list: IFormFieldInput[] = [];
    private _model!: IFormFieldInput;
    private _options: IFormRadioOptions = {...AppFormRadioComponent.initFormRadioOptions};

    get formInput(): NgModel {
        return this._formInput;
    }

    @ViewChild('formInput')
    set formInput(value: NgModel) {
        setTimeout(_ => this._formInput = value, 1);
    }

    get list(): IFormFieldInput[] {
        return this._list;
    }

    @Input()
    set list(value: IFormFieldInput[]) {
        this._list = value;
    }

    @Input()
    set model(value: IFormFieldInput) {
        this._model = value;
    }

    get modelCode(): string {
        return this._model.code;
    }

    get options(): IFormRadioOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormRadioOptions) {
        this._options = {...AppFormRadioComponent.initFormRadioOptions, ...value};
    }

    onSelect(formFieldInput: IFormFieldInput): void {
        this._model = formFieldInput;
        this.selected.emit(this._model);
    }
}
