import angularJS from '@shared/angularJS/global.ng';
import {camelCase} from 'lodash';
import {IModule, IPromise, IQService, IScope} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgDemandeurManager, NgDemandeurRechercheManager} from '@legacy/app/managers/managers';
import {UserService} from '@models/users/user/user.service';
import {NgDemandeur, NgDemandeurRecherche} from '@legacy/app/managers/ressources';

export default function getDemandeurEditCriteriaCtrl(module: IModule): void {
    (angular => {
        'use strict';

        module.component('eskDemandeurEditCriteriaCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/controllers/item/edit/criteria.html',
        });

        /**
         * Controller to edit demandeur
         *
         * @param DemandeurManager
         * @param SoqSweetAlert
         * @param $translate
         * @param Ng2RouterStateForNgService
         * @param $scope
         * @param $q
         * @param Ng2UserService
         * @param DemandeurRechercheManager
         * @constructor
         */
        Controller.$inject = ['DemandeurManager', 'SoqSweetAlert', '$translate', 'Ng2RouterStateForNgService', '$scope', '$q', 'Ng2UserService', 'DemandeurRechercheManager'];
        function Controller(this: any,
                            demandeurManager: NgDemandeurManager,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $scope: IScope,
                            $q: IQService,
                            ng2UserService: UserService,
                            demandeurRechercheManager: NgDemandeurRechercheManager) {
            const $ctrl = this;

            ng2UserService.last$.subscribe(currentUser => {
                $ctrl.options = {
                    routing: ng2RouterStateForNgService.current.name?.endsWith('general') ? 'general' : 'secondary',
                    disableSecondaryCriteria: true,
                    disabledFields: {
                        fieldsetCession: true,
                        fieldsetSource: true,
                        fieldsetTypeTransaction: !currentUser.hasRoleGrpLocation(),
                        searchByAdresse: true,
                    },
                    onChangeType: (oldType: string, newType: string) => {
                        if (!angular.isNumber($ctrl.search.id)) return $q.resolve();

                        return soqSweetAlert.warningConfirmation($translate.instant('demandeur.search.change_type.TITLE'), $translate.instant('demandeur.search.change_type.MESSAGE', {
                            new: camelCase(newType),
                            old: camelCase(oldType),
                        }), {confirmButtonText: $translate.instant("demandeur.search.change_type.CONFIRMATION")});
                    },
                };

                let promise: IPromise<unknown>;

                if (demandeurManager.current?.recherches?.[0]?.id > 0) {
                    promise = $q.resolve();
                } else {
                    promise = demandeurManager.getOneById<NgDemandeur>(demandeurManager.current.id)
                        .then(demandeur => {
                            if (demandeur.recherches?.[0]?.id > 0) {
                                return demandeurRechercheManager.getOneById<NgDemandeurRecherche>(demandeur.id, demandeur.recherches[0].id);
                            }

                            return $q.resolve(undefined);
                        })
                        .then(demandeurRecherche => {
                            demandeurManager.current.recherches = [(demandeurRecherche ?? demandeurRechercheManager.create({status: 'brouillon'}, demandeurManager.current.id)) as NgDemandeurRecherche]
                        });
                }

                promise.then(() => $ctrl.search = demandeurManager.current.recherches[0]);
            });
            $scope.$on('demandeur.search.launch', (event, toState, toParams, fromState, fromParams, options) => {
                if (!$ctrl.search || !angular.isFunction($ctrl.search.updateSearchLocalisations)) {
                    return;
                }

                return $ctrl.search.updateSearchLocalisations($ctrl.search).then(() => {
                    let criteria;
                    const newSearch = demandeurManager.current.recherches.length === 0;

                    if ($ctrl.search.natures.length <= 0) {
                        criteria = 'nature';
                    } else if ($ctrl.search.areas.length <= 0 && $ctrl.search.communes.length <= 0 && $ctrl.search.quartiers.length <= 0) {
                        criteria = 'localisations';
                    }

                    if (angular.isString(criteria)) {
                        soqSweetAlert.warningMessage($translate.instant('demandeur.search.edition.invalid.TITLE'), $translate.instant('demandeur.search.edition.invalid.MESSAGE', {criteria: criteria}));

                        return;
                    }

                    $ctrl.loadingSearch = true;

                    return $ctrl.search.save().then(() => {
                        if (newSearch) {
                            demandeurManager.current.recherches.push($ctrl.search);
                        }

                        return $ctrl.search.launch();
                    }).then(() => {
                        if (angular.isObject(toState)) {
                            ng2RouterStateForNgService.navigateByUrl(toState.name, toParams);
                        } else {
                            ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + demandeurManager.current.id + '/resultats');
                        }
                    }).finally(() => $ctrl.loadingSearch = false);
                });
            });
        }
    })(angularJS);
}
