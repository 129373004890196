import angularJS from '@shared/angularJS/global.ng';
import {IModule, IScope} from 'angular';
import DateFormat from '@shared/date/date.format';

export default function getEskSelectDate(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-date></esk-select-date>
         * @param options (use for datepicker options): {maxDate(date), minDate(date), placeholder(string)}
         */
        module.component('eskSelectDate', {
            bindings: {
                model: '=',
                onSelect: '&',
                form: '=',
                formFieldName: '@',
                isRequired: '=',
                options: '=?',
                optionsHour: '=?',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/date.html'
        });

        /**
         * Select date
         *
         * @param $scope
         * @constructor
         */
        Controller.$inject = ['$scope'];
        function Controller(this: any, $scope: IScope) {
            const $ctrl = this;
            let previousDate: unknown;

            $ctrl.$onInit = $onInit;
            $ctrl.change = change;

            /**
             * Change of options
             *
             * @param options
             * @param oldOptions
             */
            $scope.$watch('$ctrl.options', function (options, oldOptions) {
                if (options !== oldOptions) $ctrl.change();
            });

            /**
             * Change of ngModel
             */
            $scope.$watch('$ctrl.model', function (newDate: string, oldDate: string) {
                if (angular.isFunction($ctrl.onSelect) && newDate !== oldDate && DateFormat.diff(newDate, oldDate) !== 0) {
                    $ctrl.onSelect();
                }
            });

            /**
             * Initialization method
             */
            function $onInit() {
                if (!angular.isObject($ctrl.optionsHour)) {
                    $ctrl.optionsHour = {};
                }

                $ctrl.optionsHour.showHour = $ctrl.optionsHour.showHour === true;

                previousDate = $ctrl.model;
                $ctrl.open = false;
                $ctrl.ngRequired = $ctrl.isRequired === true ? true : null;
            }

            /**
             * On change date
             */
            function change() {
                var minDate = true;
                var maxDate = true;
                var model;
                var dateOption;

                if ($ctrl.ngRequired && angular.isDefined(previousDate) && !angular.isDefined($ctrl.model)) {
                    $ctrl.model = previousDate;
                }

                if (angular.isObject($ctrl.form) && angular.isObject($ctrl.form[$ctrl.formFieldName])) {
                    if (angular.isDefined($ctrl.model) && $ctrl.model !== null) {
                        model = angular.copy($ctrl.model).setHours(0, 0, 0, 0);

                        if (angular.isDefined($ctrl.options.minDate) && $ctrl.options.minDate !== null) {
                            dateOption = angular.copy($ctrl.options.minDate).setHours(0, 0, 0, 0);
                            minDate = model.valueOf() >= dateOption.valueOf();
                        }

                        if (angular.isDefined($ctrl.options.maxDate) && $ctrl.options.maxDate !== null) {
                            dateOption = angular.copy($ctrl.options.maxDate).setHours(0, 0, 0, 0);
                            maxDate = model.valueOf() <= dateOption.valueOf();
                        }
                    }

                    $ctrl.form[$ctrl.formFieldName].$setValidity("mindate", minDate);
                    $ctrl.form[$ctrl.formFieldName].$setValidity("maxdate", maxDate);
                }

                previousDate = $ctrl.model;
            }
        }
    })(angularJS);
}
