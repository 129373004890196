import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {ICBienPhotosApi} from '@models/bien/photos/collection/bien-photos.collection.interfaces';

@Injectable({providedIn: 'root'})
export class BienPhotosApiService {
    private _modelApiService = inject(ModelApiService);

    getCollectionByLink$(link: string): Observable<ICBienPhotosApi> {
        return this._modelApiService.getCollection$(link);
    }
}
