import {inject, Injectable} from '@angular/core';
import BienPhoto from '@models/bien/photos/photo/bien-photo.model';
import {NgBienPhoto} from '@legacy/app/managers/ressources';
import {IBienPhotoApi} from '@models/bien/photos/photo/bien-photo.interfaces';
import {PhotoFactory} from '@models/photos/photo/photo.factory';
import {TextsService} from '@shared/texts/texts.service';

@Injectable({providedIn: 'root'})
export class BienPhotoFactory {
    private _photoFactory = inject(PhotoFactory);
    private _textsService = inject(TextsService);

    create(bienPhotoApi: IBienPhotoApi): BienPhoto {
        const bienPhoto: BienPhoto = this.createVirgin(bienPhotoApi.uuid);

        bienPhoto.bottomRightCoordinates = bienPhotoApi.bottomRightCoordinates;
        bienPhoto.topLeftCoordinates = bienPhotoApi.topLeftCoordinates;
        bienPhoto.visibilite = bienPhotoApi.visibilite;
        if (bienPhotoApi._embedded?.photo) {
            bienPhoto.photo = this._photoFactory.create(bienPhotoApi._embedded.photo);
        }

        if (bienPhotoApi._links?.file) {
            bienPhoto.linkFile = bienPhotoApi._links.file.href;
        }

        return bienPhoto;
    }

    createVirgin(uuid = this._textsService.uuid()): BienPhoto {
        return new BienPhoto(uuid);
    }

    ngCreate(ngBienPhoto: NgBienPhoto): BienPhoto {
        const bienPhoto: BienPhoto = this.createVirgin(ngBienPhoto.uuid);

        bienPhoto.bottomRightCoordinates = ngBienPhoto.bottomRightCoordinates;
        bienPhoto.topLeftCoordinates = ngBienPhoto.topLeftCoordinates;
        bienPhoto.visibilite = ngBienPhoto.visibilite;
        if (ngBienPhoto.photo) {
            bienPhoto.photo = this._photoFactory.ngCreate(ngBienPhoto.photo);
        }

        if (ngBienPhoto._links?.file) {
            bienPhoto.linkFile = ngBienPhoto._links.file.href;
        }

        return bienPhoto;
    }
}
