import {Component, inject, Input, output} from '@angular/core';
import {IFormCheckboxListOptions, IFormFieldCheckbox} from '@shared/form/form.interfaces';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';

@Component({selector: 'app-dictionary-checkbox', templateUrl: 'dictionary-checkbox.component.html'})
export class AppDictionaryCheckboxComponent {
    static readonly initDictionaryCheckboxListOptions: IFormCheckboxListOptions = {
        bindLabel: 'label',
        name: 'dictionary-checkbox',
    };
    readonly selected = output<DictionaryItem[]>();
    private _dictionaryFactory = inject(DictionaryFactory);
    private _list: DictionaryItem[] = [];
    private _model: DictionaryItem[] = [];
    private _options: IFormCheckboxListOptions = {...AppDictionaryCheckboxComponent.initDictionaryCheckboxListOptions};

    get list(): DictionaryItem[] {
        return this._list;
    }

    get model(): DictionaryItem[] {
        return this._model;
    }

    @Input()
    set model(value: DictionaryItem[]) {
        this._model = value;
    }

    @Input()
    set name(value: string) {
        this._list = this._dictionaryFactory.getByName(value).items;
        this._options.name = value;
    }

    get options(): IFormCheckboxListOptions {
        return this._options;
    }

    onSelect(dictionaryItems: IFormFieldCheckbox[]): void {
        this.selected.emit(dictionaryItems as DictionaryItem[]);
    }
}
