import {Component, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import BienLogement from '@models/bien/logement/bien-logement.model';

@Component({
    selector: 'app-dossier-bien-overview-logements',
    templateUrl: 'dossier-bien-overview.logements.component.html',
})
export class AppDossierBienOverviewLogementsComponent {
    private _bienLogements!: BienLogement[];

    get bienLogements(): BienLogement[] {
        return this._bienLogements;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._bienLogements = value.bien.logements;
    }
}
