import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ISearchOpenOptions} from '@shared/search/search.interfaces';

@Component({selector: 'app-search-open', templateUrl: 'search.open.component.html'})
export class SearchOpenComponent {
    @Output() readonly clicked = new EventEmitter<void>();

    static readonly initSearchOpenOptions: ISearchOpenOptions = {placeHolder: 'Rechercher'};
    private _options: ISearchOpenOptions = {...SearchOpenComponent.initSearchOpenOptions};

    get options(): ISearchOpenOptions {
        return this._options;
    }

    @Input()
    set options(value: ISearchOpenOptions) {
        this._options = {...SearchOpenComponent.initSearchOpenOptions, ...value};
    }

    click(): void {
        this.clicked.emit();
    }
}
