import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'textNl2br'})
export class TextNl2brPipe implements PipeTransform {
    transform(value: string): string {
        if (value === undefined || value === null) {
            return '';
        }

        return value.replace(/\n/g, '<br/>');
    }
}
