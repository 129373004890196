import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {delay, Observable, Subject, switchMap} from 'rxjs';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';
import {DossierService} from '@models/dossiers/dossier/dossier.service';

@Component({selector: 'layout-offreachats', templateUrl: 'layout-offreachats.component.html'})
export class AppLayoutOffreachatsComponent implements OnDestroy, OnInit {
    private _dossierService = inject(DossierService);
    private _offreachatService = inject(OffreachatService);
    private _router = inject(Router);
    private readonly _onDestroy$ = new Subject<void>();
    private _redirectionRoute!: string;
    private _title$!: Observable<string>;

    get CALL_TO_ACTION_MAIN(): string {
        return CallToActionService.MAIN;
    }

    get redirectionRoute(): string {
        return this._redirectionRoute;
    }

    get title$(): Observable<string> {
        return this._title$;
    }

    ngOnInit(): void {
        this._title$ = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            startWith(undefined!),
            switchMap(_ => this._offreachatService.current$),
            map(offreachat => {
                const dossier = this._dossierService.getCurrentFromNg(this._router.url);
                const url = this._router.url.split('#')[0];

                if (dossier) {
                    this._redirectionRoute = '/app/' + dossier.dossierType + 's/' + dossier.id.toString() + '/offres-achat/portefeuille';
                } else {
                    this._redirectionRoute = '/app/dashboard';
                }

                switch (url.split('/').splice(-1, 1)[0]) {
                    case 'edit' :
                        return offreachat?.isNew() ? 'Création d\'une offre d\'achat' : 'Édition d\'une offre d\'achat';
                    case 'redaction' :
                        return 'Rédaction d\'une offre d\'achat';
                    case 'resume' :
                        return 'Résumé d\'une offre d\'achat';
                    case 'sign' :
                    case 'signature' :
                        return 'Signature d\'une offre d\'achat';
                }

                if (dossier) {
                    this._redirectionRoute = '/app/' + dossier.dossierType + 's/' + dossier.id.toString() + '/lifetime';
                } else {
                    this._redirectionRoute = '/app/dashboard';
                }

                return 'Offres d\'achat';
            }),
            delay(1),
            takeUntil(this._onDestroy$),
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
