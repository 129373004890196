<div class="tw-py-4 md:tw-p-4 tw-flex-1">
  <div class="tw-form-2col">
    @if (demandeur$ | appLoadObs | async; as demandeur) {
      <div>
        <div class="tw-form-2col-part">
          <div class="tw-form-left-col">
            <h3 class="tw-form-left-col-title">Demandeur</h3>

            <p class="tw-form-left-col-explain">
              @if (!options.fixedDemandeur) {
                Recherchez un demandeur ou un contact à associer par un nom, un téléphone, une adresse email ou une
                référence.<br>Vous ne le trouvez pas ? Créez le et retrouvez le dans votre portefeuille de demandeurs !
              }
            </p>
          </div>

          <div class="tw-form-right-col">
            @if (options.fixedDemandeur) {
              <div class="tw-text-sm tw-text-gray-400">
                Votre demandeur {{ demandeur.value ? demandeur.value.reference : '' }} est sélectionné automatiquement.
              </div>
            } @else {
              <form #demandeurForm="ngForm" id="demandeurForm" novalidate>
                <div class="sm:tw-max-w-md tw-space-y-2" [appFormError]="demandeursSelect.formInput">
                  <label class="tw-block tw-text-sm tw-text-gray-700">Sélection d'un demandeur</label>

                  <div class="tw-flex tw-space-x-4">
                    <app-demandeurs-select #demandeursSelect="demandeursSelect" [demandeur]="demandeur.value"
                                           [options]="{disabled: demandeurCreation, placeholder: 'Rechercher un demandeur ou un contact', required: true, withContacts: true}"
                                           class="tw-w-[calc(100%_-_68px_-_1rem)] sm:tw-w-[calc(100%_-_175px_-_1rem)] sm:tw-max-w-[calc(100%_-_175px_-_1rem)]"
                                           (selected)="selectDemandeur($event)"/>

                    <button type="button" [disabled]="demandeurCreation" class="tw-btn-secondary-info"
                            (click)="demandeurCreation = true">
                      Créer<span class="tw-hidden sm:tw-inline"> un demandeur</span>
                    </button>
                  </div>
                </div>

                @if (demandeurCreation) {
                  <div class="tw-mt-8 tw-border-t tw-pt-4 tw-space-y-4">
                    <div class="tw-flex tw-items-center">
                      <label class="tw-flex-1 tw-text-center tw-text-lg tw-font-medium tw-text-gray-500">
                        Création d'un demandeur
                      </label>

                      <button type="button" class="tw-btn-tertiary-default" (click)="stopDemandeurCreation()">
                        <fa-icon [icon]="'times'" size="xl"/>
                      </button>
                    </div>

                    <app-person-edit-simple class="tw-block tw-w-full sm:tw-w-[37rem] md:tw-w-[42rem] lg:tw-w-[39rem] xl:tw-w-[37rem] 2xl:tw-w-[49rem] tw-mx-auto"
                                            (edited)="createDemandeur($event)"/>
                  </div>
                }
              </form>
            }
          </div>
        </div>
      </div>
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
    }

    @if (vente$ | appLoadObs | async; as vente) {
      <div>
        <div class="tw-form-2col-part">
          <div class="tw-form-left-col">
            <h3 class="tw-form-left-col-title">Vente</h3>

            <p class="tw-form-left-col-explain">
              @if (!options.fixedVente) {
                Recherchez le bien en vente à associer.<br><br>Le filtre sur les biens correspondants n'est actif que
                pour un demandeur sélectionné avec une recherche.<br>Pour la recherche par mots-clés, celle-ci
                s'effectuera sur les noms, prénoms, téléphones et adresses email (si le bien appartient à mon étude) et
                sur les références, le titre, le descriptif, la rue, le code postal et la commune du bien.
              }
            </p>
          </div>

          <div class="tw-form-right-col">
            @if (options.fixedVente) {
              <div class="tw-text-sm tw-text-gray-400">
                {{ vente.value.interne ? 'Votre' : 'Le' }} bien en vente {{ vente.value.reference }} est sélectionné
                automatiquement.
              </div>
            } @else {
              <div class="tw-space-y-2">
                <label class="tw-block tw-text-sm tw-text-gray-700">Vente sélectionnée</label>

                <ul class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-6">
                  @if (vente.value) {
                    <li class="tw-shadow-sm tw-rounded-md">
                      <app-dossier-bien-card [dossierBien]="vente.value" [options]="{withBorder: true}"/>
                    </li>
                  }

                  @if (!vente.value) {
                    <li class="tw-hidden first:last:tw-flex tw-justify-center tw-items-center tw-shadow-sm tw-rounded-md tw-bg-gray-100 tw-text-lg tw-min-h-[6rem]">
                      Recherchez un bien en vente à associer
                    </li>
                  }
                </ul>
              </div>

              <div class="tw-mt-8">
                @if (!venteSearch) {
                  <button type="button" class="tw-btn-secondary-info" (click)="displayVenteSearch()">
                    Rechercher<span class="tw-hidden sm:tw-inline"> un bien en vente</span>
                  </button>
                }

                @if (venteSearch) {
                  <div class="tw-border-t tw-pt-4 tw-space-y-4">
                    <div class="tw-flex tw-items-center">
                      <label class="tw-flex-1 tw-text-center tw-text-lg tw-font-medium tw-text-gray-500">
                        Recherche et sélection d'un bien en vente
                      </label>

                      <button type="button" class="tw-btn-tertiary-default" (click)="venteSearch = false">
                        <fa-icon [icon]="'times'" size="xl"/>
                      </button>
                    </div>

                    <div class="tw-space-y-2">
                      <label class="tw-block tw-text-sm tw-text-gray-700">Filtres sur la recherche</label>

                      @if (currentUser$ | async; as currentUser) {
                        <div class="tw-flex tw-flex-wrap tw-gap-4">
                          <app-form-select-button [list]="ventesSources" [model]="ventesSourcesCode"
                                                  (selected)="selectVentesSource($event)"/>

                          <form class="tw-min-w-[20rem]" novalidate>
                            <app-form-search-input [options]="{placeholder: 'Rechercher un bien en vente'}"
                                                   (searchedText)="searchVentes($event)"/>
                          </form>
                        </div>
                      } @else {
                        <app-loader [classHeight]="'tw-h-10'" class="tw-block"/>
                      }
                    </div>

                    @if (filteredVentes$ | async; as filteredVentes) {
                      <div>
                        <div class="tw-grid-list">
                          @for (vente of filteredVentes.results; track vente) {
                            <div class="tw-grid-list-item">
                              <app-dossier-bien-card [dossierBien]="vente" (action)="selectVente($event)"
                                                     [options]="{actionOnAll: true, enabledAction: 'choose'}"/>
                            </div>
                          }

                          <div class="tw-hidden first:tw-col-span-2 first:tw-flex even:tw-flex tw-justify-center tw-items-center tw-text-lg tw-min-h-[7rem]">
                            Modifier vos filtres pour voir plus de biens en vente
                          </div>
                        </div>
                      </div>
                    } @else {
                      <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
                    }
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
    }

    <div class="tw-form-2col-part">
      <div class="tw-form-left-col"><h3 class="tw-form-left-col-title">Offre</h3></div>

      <form class="tw-form-right-col sm:tw-grid sm:tw-grid-cols-2 md:tw-flex lg:tw-grid lg:tw-grid-cols-2 2xl:tw-flex tw-space-y-4 sm:tw-space-y-0 2xl:tw-space-x-4 2xl:tw-space-x-8 sm:tw-gap-4 2xl:tw-gap-8"
            #offreForm="ngForm" id="offreForm" novalidate>
        @if (vente | async) {
          <div class="tw-w-48 tw-space-y-2" [appFormError]="offreachatMontant">
            <label for="offreachat-montant" class="tw-block tw-text-sm tw-text-gray-700">
              Montant {{ offreachat.typeCharges.label | lowercase }}
            </label>

            <div class="tw-input-currency tw-mt-1">
              <input id="offreachat-montant" type="number" step="0.01" min="0.01" max="10000000000"
                     name="offreachatMontant" class="tw-input-number" [(ngModel)]="offreachat.montant" required
                     #offreachatMontant="ngModel" (input)="onChangeMontant()">
              <span>€</span>
            </div>
          </div>

          @if (offreachat.typeCharges.code !== DICTIONARY_CODE_HONO_NEGO_INCLUS) {
            <div class="tw-w-48 tw-space-y-2" [appFormError]="offreachatHonorairesNego">
              <label for="offreachat-honoraires-nego" class="tw-block tw-text-sm tw-text-gray-700">
                Honoraires de négociation
              </label>

              <div class="tw-input-currency tw-mt-1">
                <input id="offreachat-honoraires-nego" type="number" step="0.01" min="0" name="offreachatHonorairesNego"
                       class="tw-input-number" [(ngModel)]="offreachat.honorairesNego"
                       #offreachatHonorairesNego="ngModel" required>
                <span>€</span>
              </div>
            </div>
          }
        } @else {
          <div class="tw-flex tw-items-center sm:tw-col-span-2 tw-mr-8 md:tw-mr-16 tw-text-lg">
            Sélectionnez un bien en vente pour saisir le montant de l'offre.
          </div>
        }

        <div class="tw-w-48 tw-space-y-2" [appFormError]="offreachatDate.formInput">
          <label class="tw-block tw-text-sm tw-text-gray-700">Date</label>

          <app-form-select-datetime [model]="offreachat.dateOffre" (selected)="offreachat.dateOffre = $event"
                                    [options]="{name: 'offreachatDatetime', required: true}"
                                    #offreachatDate="formSelectDatetime" class="tw-block"/>
        </div>
      </form>
    </div>
  </div>
</div>
