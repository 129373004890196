import {inject, Injectable} from '@angular/core';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import BienLogement from '@models/bien/logement/bien-logement.model';
import {NgBienLogement} from '@legacy/app/managers/ressources';
import {IBienLogementApi} from '@models/bien/logement/bien-logement.interfaces';

@Injectable({providedIn: 'root'})
export class BienLogementFactory {
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienLogementApi: IBienLogementApi): BienLogement {
        const bienLogement: BienLogement = new BienLogement(bienLogementApi.uuid);

        bienLogement.comments = bienLogementApi.comments;
        bienLogement.otherLabel = bienLogementApi.otherLabel;
        bienLogement.surface = bienLogementApi.surface;
        if (bienLogementApi._embedded) {
            if (bienLogementApi._embedded.etage) {
                bienLogement.etage = this._dictionaryItemFactory.create(bienLogementApi._embedded.etage);
            }

            if (bienLogementApi._embedded.logement) {
                bienLogement.logement = this._dictionaryItemFactory.create(bienLogementApi._embedded.logement);
            }
        }

        return bienLogement;
    }

    ngCreate(ngBienLogement: NgBienLogement): BienLogement {
        const bienLogement: BienLogement = new BienLogement(ngBienLogement.uuid);

        bienLogement.comments = ngBienLogement.comments;
        bienLogement.otherLabel = ngBienLogement.otherLabel;
        bienLogement.surface = ngBienLogement.surface;
        if (ngBienLogement.etage) {
            bienLogement.etage = this._dictionaryItemFactory.ngCreate(ngBienLogement.etage);
        }

        if (ngBienLogement.logement) {
            bienLogement.logement = this._dictionaryItemFactory.ngCreate(ngBienLogement.logement);
        }

        return bienLogement;
    }
}
