import angularJS from '@shared/angularJS/global.ng';
import {isBoolean, map} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {NgAreaManager, NgCommuneManager, NgDictionariesManager, NgQuartierManager} from '@legacy/app/managers/managers';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgSearch} from '@legacy/app/eskimmo/eskimmo';
import {NgCommune} from '@legacy/app/managers/ressources';
import {filter} from 'rxjs/operators';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Etude from '@models/etudes/etude/etude.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {ConditionConst} from '@shared/constants';
import DateFormat from '@shared/date/date.format';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Vente from '@models/ventes/vente/vente.model';
import {SearchCriteriaSourceConst} from '@models/search/criteria/search-criteria.constants';
import SearchCriteria from '@models/search/criteria/search-criteria.model';

export default function getEskUtils(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EskUtils', Factory);

        /**
         * EskUtils
         *
         * @param DictionariesManager
         * @param CommuneManager
         * @param $q
         * @param AreaManager
         * @param QuartierManager
         * @param Ng2RouterStateForNgService
         * @param Ng2EtudeService
         * @param Ng2DictionaryItemService
         */
        Factory.$inject = ['DictionariesManager', 'CommuneManager', '$q', 'AreaManager', 'QuartierManager', 'Ng2RouterStateForNgService', 'Ng2EtudeService', 'Ng2DictionaryItemService'];
        function Factory(dictionariesManager: NgDictionariesManager,
                         communeManager: NgCommuneManager,
                         $q: IQService,
                         areaManager: NgAreaManager,
                         quartierManager: NgQuartierManager,
                         ng2RouterStateForNgService: RouterStateForNgService,
                         ng2EtudeService: EtudeService,
                         ng2DictionaryItemService: DictionaryItemService) {
            let currentEtude: Etude;
            const dataForRoute: Record<string, unknown> = {};
            const factory = {
                setCodePostalFromWatchingCommune: setCodePostalFromWatchingCommune,
                getFilterFromSearch: getFilterFromSearch,
                getSearchFromFilter: getSearchFromFilter,
                setDataForRoute: setDataForRoute,
                setDataForCurrentRoute: setDataForCurrentRoute,
                getDataFromParentRoute: getDataFromParentRoute,
                getArraySum: getArraySum,
                getPeriodByMonths: getPeriodByMonths,
                createNgSearchFromNg2: createNgSearchFromNg2,
            };

            ng2EtudeService.current$.pipe(filter(etude => !!etude)).subscribe(etude => currentEtude = etude);

            return factory;

            /**
             * Set codePostal from watching Commune
             *
             * @param model
             * @param commune
             * @param oldCommune
             */
            function setCodePostalFromWatchingCommune(model: { codePostal: string },
                                                      commune: NgCommune,
                                                      oldCommune: NgCommune) {
                if (angular.isObject(commune)) {
                    if (!angular.isString(model.codePostal) || !angular.isObject(oldCommune) || commune.id !== oldCommune.id) {
                        model.codePostal = commune.codesPostaux[0];
                    }
                }
            }

            /**
             * Get filter from search
             *
             * @param search
             * @returns {Object}
             */
            function getFilterFromSearch(search: NgSearch) {
                var filter = {
                    statut: {condition: ConditionConst.IN, values: [ADossierBien.abstractBienStatuts.DIFFUSE, ADossierBien.abstractBienStatuts.MANDAT]},
                };

                if (angular.isArray(search.statuts) && search.statuts.length > 0) {
                    filter.statut = {condition: ConditionConst.IN, values: search.statuts};
                }
                if (angular.isArray(search.natures) && search.natures.length > 0) {
                    // @ts-ignore
                    filter.nature = {condition: ConditionConst.IN, values: map(search.natures, 'code')};
                }
                if (angular.isArray(search.quartiers) && search.quartiers.length > 0) {
                    // @ts-ignore
                    filter.district = {condition: ConditionConst.IN, values: map(search.quartiers, 'id')};
                }
                if (angular.isArray(search.communes) && search.communes.length > 0) {
                    // @ts-ignore
                    filter.commune = {condition: ConditionConst.IN, values: map(search.communes, 'id')};
                }
                if (angular.isArray(search.areas) && search.areas.length > 0) {
                    // @ts-ignore
                    filter.areas = {condition: ConditionConst.IN, values: map(search.areas, 'id')};
                }
                if (angular.isObject(search.vente)) {
                    if (search.vente.excludeVenteTraditionnelle || search.vente.excludeVenteViager || search.vente.excludeVenteInteractive) {
                        // @ts-ignore
                        filter.type = {condition: ConditionConst.NOT_IN, values: []};
                        if (search.vente.excludeVenteInteractive) {
                            // @ts-ignore
                            filter.type.values.push(Vente.types.INTERACTIVE);
                        }
                        if (search.vente.excludeVenteTraditionnelle) {
                            // @ts-ignore
                            filter.type.values.push(Vente.types.SIMPLE);
                        }
                        if (search.vente.excludeVenteViager) {
                            // @ts-ignore
                            filter.type.values.push(Vente.types.VIAGER);
                        }
                    }
                    if (search.vente.budgetMin! > 0 || search.vente.budgetMax! > 0) {
                        // @ts-ignore
                        filter.budget = {};

                        // @ts-ignore
                        if (search.vente.budgetMin > 0) filter.budget.min = search.vente.budgetMin;
                        // @ts-ignore
                        if (search.vente.budgetMax > 0) filter.budget.max = search.vente.budgetMax;
                        // @ts-ignore
                        if (search.vente.budgetHni) filter.budgetHni = true;
                    }
                    if (search.vente.renteMin! > 0 || search.vente.renteMax! > 0) {
                        // @ts-ignore
                        filter.viager = {rente: {}};

                        // @ts-ignore
                        if (search.vente.renteMin > 0) filter.viager.rente.min = search.vente.renteMin;
                        // @ts-ignore
                        if (search.vente.renteMax > 0) filter.viager.rente.max = search.vente.renteMax;
                    }
                }
                if (angular.isObject(search.location)) {
                    if (search.location.loyerMin > 0 || search.location.loyerMax > 0) {
                        // @ts-ignore
                        filter.loyer = {};

                        // @ts-ignore
                        if (search.location.loyerMin > 0) filter.loyer.min = search.location.loyerMin;
                        // @ts-ignore
                        if (search.location.loyerMax > 0) filter.loyer.max = search.location.loyerMax;
                    }
                }
                if (search.superficieMin! > 0 || search.superficieMax! > 0) {
                    // @ts-ignore
                    filter.superficie = {};

                    // @ts-ignore
                    if (search.superficieMin > 0) filter.superficie.min = search.superficieMin;
                    // @ts-ignore
                    if (search.superficieMax > 0) filter.superficie.max = search.superficieMax;
                }
                if (angular.isObject(search.option)) {
                    if (search.option.uniquementBiensNotaire || search.option.source === SearchCriteriaSourceConst.ETUDE) {
                        // @ts-ignore
                        filter.etude = {condition: ConditionConst.IN, values: [currentEtude.id]};
                    } else if (search.option.source === SearchCriteriaSourceConst.NETWORK) {
                        // @ts-ignore
                        filter.etude = {condition: ConditionConst.NOT_IN, values: [currentEtude.id]};
                    }
                    if (search.option.surfaceCadastraleMin! > 0 || search.option.surfaceCadastraleMax! > 0 || search.option.surfaceBatieMin! > 0 || search.option.surfaceBatieMax! > 0 || search.option.surfaceNonBatieMin! > 0 || search.option.surfaceNonBatieMax! > 0 || search.option.surfaceSejourMin! > 0 || search.option.surfaceSejourMax! > 0) {
                        // @ts-ignore
                        filter.surfaces = {};

                        if (search.option.surfaceCadastraleMin! > 0 || search.option.surfaceCadastraleMax! > 0) {
                            // @ts-ignore
                            filter.surfaces.cadastrale = {};

                            // @ts-ignore
                            if (search.option.surfaceCadastraleMin > 0) filter.surfaces.cadastrale.min = search.option.surfaceCadastraleMin;
                            // @ts-ignore
                            if (search.option.surfaceCadastraleMax > 0) filter.surfaces.cadastrale.max = search.option.surfaceCadastraleMax;
                        }

                        if (search.option.surfaceBatieMin! > 0 || search.option.surfaceBatieMax! > 0) {
                            // @ts-ignore
                            filter.surfaces.batie = {};

                            // @ts-ignore
                            if (search.option.surfaceBatieMin > 0) filter.surfaces.batie.min = search.option.surfaceBatieMin;
                            // @ts-ignore
                            if (search.option.surfaceBatieMax > 0) filter.surfaces.batie.max = search.option.surfaceBatieMax;
                        }

                        if (search.option.surfaceNonBatieMin! > 0 || search.option.surfaceNonBatieMax! > 0) {
                            // @ts-ignore
                            filter.surfaces.nonBatie = {};

                            // @ts-ignore
                            if (search.option.surfaceNonBatieMin > 0) filter.surfaces.nonBatie.min = search.option.surfaceNonBatieMin;
                            // @ts-ignore
                            if (search.option.surfaceNonBatieMax > 0) filter.surfaces.nonBatie.max = search.option.surfaceNonBatieMax;
                        }

                        if (search.option.surfaceSejourMin! > 0 || search.option.surfaceSejourMax! > 0) {
                            // @ts-ignore
                            filter.surfaces.sejour = {};

                            // @ts-ignore
                            if (search.option.surfaceSejourMin > 0) filter.surfaces.sejour.min = search.option.surfaceSejourMin;
                            // @ts-ignore
                            if (search.option.surfaceSejourMax > 0) filter.surfaces.sejour.max = search.option.surfaceSejourMax;
                        }
                    }
                    if (search.option.nombrePiecesMin! > 0 || search.option.nombrePiecesMax! > 0) {
                        // @ts-ignore
                        filter.nombrePieces = {};

                        // @ts-ignore
                        if (search.option.nombrePiecesMin > 0) filter.nombrePieces.min = search.option.nombrePiecesMin;
                        // @ts-ignore
                        if (search.option.nombrePiecesMax > 0) filter.nombrePieces.max = search.option.nombrePiecesMax;
                    }
                    if (search.option.nombreLogementsMin! > 0 || search.option.nombreLogementsMax! > 0) {
                        // @ts-ignore
                        filter.nombreLogements = {};

                        // @ts-ignore
                        if (search.option.nombreLogementsMin > 0) filter.nombreLogements.min = search.option.nombreLogementsMin;
                        // @ts-ignore
                        if (search.option.nombreLogementsMax > 0) filter.nombreLogements.max = search.option.nombreLogementsMax;
                    }
                    if (search.option.nombreChambresMin! > 0 || search.option.nombreChambresMax! > 0) {
                        // @ts-ignore
                        filter.nombreChambres = {};

                        // @ts-ignore
                        if (search.option.nombreChambresMin > 0) filter.nombreChambres.min = search.option.nombreChambresMin;
                        // @ts-ignore
                        if (search.option.nombreChambresMax > 0) filter.nombreChambres.max = search.option.nombreChambresMax;
                    }
                    if (search.option.anneeConstructionMin! > 0 || search.option.anneeConstructionMax! > 0) {
                        // @ts-ignore
                        filter.anneeConstruction = {};

                        // @ts-ignore
                        if (search.option.anneeConstructionMin > 0) filter.anneeConstruction.min = search.option.anneeConstructionMin;
                        // @ts-ignore
                        if (search.option.anneeConstructionMax > 0) filter.anneeConstruction.max = search.option.anneeConstructionMax;
                    }
                    // @ts-ignore
                    if (search.option.annexe) filter.annexe = true;
                    // @ts-ignore
                    if (search.option.ascenseur) filter.ascenseur = true;
                    // @ts-ignore
                    if (search.option.balcon) filter.balcon = true;
                    // @ts-ignore
                    if (search.option.cave) filter.cave = true;
                    // @ts-ignore
                    if (search.option.cheminee) filter.cheminee = true;
                    // @ts-ignore
                    if (search.option.garage) filter.garage = true;
                    // @ts-ignore
                    if (search.option.grenier) filter.grenier = true;
                    // @ts-ignore
                    if (search.option.parking) filter.parking = true;
                    // @ts-ignore
                    if (search.option.piscine) filter.piscine = true;
                    // @ts-ignore
                    if (search.option.pointEau) filter.pointEau = true;
                    // @ts-ignore
                    if (search.option.sousSol) filter.sousSol = true;
                    // @ts-ignore
                    if (search.option.stationnement) filter.stationnement = true;
                    // @ts-ignore
                    if (search.option.terrasse) filter.terrasse = true;
                    // @ts-ignore
                    if (search.option.terrasseOuBalcon) filter.terrasseOuBalcon = true;
                    // @ts-ignore
                    if (search.option.vueExceptionnelle) filter.vueExceptionnelle = true;
                    // @ts-ignore
                    if (isBoolean(search.option.residenceService)) filter.residenceService = search.option.residenceService;
                    if (angular.isObject(search.option.voie)) {
                        // @ts-ignore
                        filter.voie = search.option.voie;
                        // @ts-ignore
                        filter.nomVoie = {condition: ConditionConst.CONTAINS, values: [search.option.voie.nom]};
                    } else if (angular.isString(search.option.nomVoie) && search.option.nomVoie !== '') {
                        // @ts-ignore
                        filter.nomVoie = {condition: ConditionConst.CONTAINS, values: [search.option.nomVoie]};
                    }
                }
                if (angular.isArray(search.etats) && search.etats.length > 0) {
                    // @ts-ignore
                    filter.etat = {condition: ConditionConst.IN, values: map(search.etats, 'id')};
                }
                if (angular.isArray(search.mitoyennetes) && search.mitoyennetes.length > 0) {
                    // @ts-ignore
                    filter.mitoyennete = {condition: ConditionConst.IN, values: map(search.mitoyennetes, 'id')};
                }
                if (angular.isArray(search.occupations) && search.occupations.length > 0) {
                    // @ts-ignore
                    filter.occupation = {condition: ConditionConst.IN, values: map(search.occupations, 'id')};
                }
                if (angular.isArray(search.plainPieds) && search.plainPieds.length > 0) {
                    // @ts-ignore
                    filter.plainPied = {condition: ConditionConst.IN, values: map(search.plainPieds, 'id')};
                }
                if (angular.isArray(search.vues) && search.vues.length > 0) {
                    // @ts-ignore
                    filter.vue = {condition: ConditionConst.IN, values: map(search.vues, 'id')};
                }
                if (angular.isObject(search.archive)) {
                    // @ts-ignore
                    filter.archive = {};
                    if (search.archive.dateVenteMin || search.archive.dateVenteMax) {
                        // @ts-ignore
                        filter.archive.dateVente = {};

                        // @ts-ignore
                        if (search.archive.dateVenteMin) filter.archive.dateVente.min = DateFormat.toJSON(search.archive.dateVenteMin);
                        // @ts-ignore
                        if (search.archive.dateVenteMax) filter.archive.dateVente.max = DateFormat.toJSON(search.archive.dateVenteMax);
                    }
                    if (search.archive.prixCessionMin! > 0 || search.archive.prixCessionMax! > 0) {
                        // @ts-ignore
                        filter.archive.prixCession = {};

                        // @ts-ignore
                        if (search.archive.prixCessionMin > 0) filter.archive.prixCession.min = search.archive.prixCessionMin;
                        // @ts-ignore
                        if (search.archive.prixCessionMax > 0) filter.archive.prixCession.max = search.archive.prixCessionMax;
                    }
                    if (angular.isArray(search.archive.types) && search.archive.types.length > 0) {
                        // @ts-ignore
                        filter.archive.type = {condition: ConditionConst.IN, values: map(search.archive.types, 'code')};
                    }
                }
                // @ts-ignore
                if (angular.isObject(search.responsableDossier)) filter.responsableDossier = search.responsableDossier;
                // @ts-ignore
                if (angular.isObject(search.etude)) filter.etude = search.etude;
                // @ts-ignore
                if (angular.isObject(search.dateModification)) filter.dateModification = search.dateModification;
                // @ts-ignore
                if (angular.isObject(search.tris)) filter.tris = search.tris;

                return filter;
            }

            /**
             * Get search from filter
             *
             * @param type
             * @param filter
             * @returns {Promise}
             */
            function getSearchFromFilter(type: string, filter: Record<string, {
                condition: string;
                values: unknown[]
            }> = {}) {
                const promises = [] as IPromise<unknown>[];
                const search = {
                    natures: [],
                    etats: [],
                    mitoyennetes: [],
                    occupations: [],
                    plainPieds: [],
                    vues: [],
                    quartiers: [],
                    communes: [],
                    areas: [],
                    vente: {},
                    option: {},
                    type,
                } as unknown as NgSearch;
                const hasCommunes = (angular.isObject(filter.commune) && filter.commune.condition === ConditionConst.IN && angular.isArray(filter.commune.values) && filter.commune.values.length > 0);

                if (angular.isObject(filter.statut) && filter.statut.condition === ConditionConst.IN && angular.isArray(filter.statut.values) && filter.statut.values.length > 0) {
                    search.statuts = filter.statut.values as string[];
                }
                if (angular.isObject(filter.nature) && filter.nature.condition === ConditionConst.IN && angular.isArray(filter.nature.values) && filter.nature.values.length > 0) {
                    angular.forEach(filter.nature.values, (value: string) => {
                        search.natures!.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.NATURES, value)));
                    });
                }
                if (angular.isObject(filter.district) && filter.district.condition === ConditionConst.IN && angular.isArray(filter.district.values) && filter.district.values.length > 0) {
                    promises.push(quartierManager.getAll({id: filter.district}).then(function (quartiers) {
                        search.quartiers = quartiers.collection;
                    }));
                }
                if (hasCommunes) {
                    promises.push(communeManager.getAll({id: filter.commune}).then(function (communes) {
                        search.communes = communes.collection;
                    }));
                }
                if (angular.isObject(filter.areas) && filter.areas.condition === ConditionConst.IN && angular.isArray(filter.areas.values) && filter.areas.values.length > 0) {
                    promises.push(areaManager.getAll({id: filter.areas}).then(function (areas) {
                        search.areas = areas.collection;
                    }));
                }
                if (angular.isString(filter.nomVoie) && filter.nomVoie !== '') {
                    if (angular.isObject(filter.voie) && hasCommunes && (filter.commune.values[0] as NgCommune).hasVoies) {
                        // @ts-ignore
                        search.option.voie = filter.voie;
                    } else {
                        // @ts-ignore
                        search.option.nomVoie = filter.nomVoie;
                    }
                }
                if (angular.isObject(filter.type) && filter.type.condition === ConditionConst.NOT_IN && angular.isArray(filter.type.values) && filter.type.values.length > 0) {
                    (filter.type.values as string[]).forEach(type => {
                        if (type === Vente.types.SIMPLE) {
                            // @ts-ignore
                            search.vente.excludeVenteTraditionnelle = true;
                        }
                        if (type === Vente.types.VIAGER) {
                            // @ts-ignore
                            search.vente.excludeVenteViager = true;
                        }
                        if (type === Vente.types.INTERACTIVE) {
                            // @ts-ignore
                            search.vente.excludeVenteInteractive = true;
                        }
                    });
                }
                if (angular.isObject(filter.budget)) {
                    // @ts-ignore
                    if (filter.budget.min > 0) search.vente.budgetMin = filter.budget.min;
                    // @ts-ignore
                    if (filter.budget.max > 0) search.vente.budgetMax = filter.budget.max;
                }
                // @ts-ignore
                if (filter.budgetHni === true) search.vente.budgetHni = true;
                if (angular.isObject(filter.viager) && angular.isObject((filter.viager as unknown as {
                    rente: unknown
                }).rente)) {
                    // @ts-ignore
                    if (filter.viager.rente.min > 0) search.vente.renteMin = filter.viager.rente.min;
                    // @ts-ignore
                    if (filter.viager.rente.max > 0) search.vente.renteMax = filter.viager.rente.max;
                }
                if (angular.isObject(filter.superficie)) {
                    // @ts-ignore
                    if (filter.superficie.min > 0) search.vente.superficieMin = filter.superficie.min;
                    // @ts-ignore
                    if (filter.superficie.max > 0) search.vente.superficieMax = filter.superficie.max;
                }
                if (angular.isObject(filter.etude) && angular.isArray(filter.etude.values)) {
                    if (filter.etude.condition === ConditionConst.IN) {
                        // @ts-ignore
                        search.option.uniquementBiensNotaire = true;
                        // @ts-ignore
                        search.option.source = SearchCriteriaSourceConst.ETUDE;
                        // @ts-ignore
                    } else if (filter.etude.condition === ConditionConst.NOT_IN) search.option.source = SearchCriteriaSourceConst.NETWORK;
                }
                if (angular.isObject(filter.surfaces)) {
                    if (angular.isObject((filter.surfaces as unknown as Record<string, unknown>).cadastrale)) {
                        // @ts-ignore
                        if (filter.surfaces.cadastrale.min > 0) search.option.surfaceCadastraleMin = filter.surfaces.cadastrale.min;
                        // @ts-ignore
                        if (filter.surfaces.cadastrale.max > 0) search.option.surfaceCadastraleMax = filter.surfaces.cadastrale.max;
                    }
                    if (angular.isObject((filter.surfaces as unknown as Record<string, unknown>).batie)) {
                        // @ts-ignore
                        if (filter.surfaces.batie.min > 0) search.option.surfaceBatieMin = filter.surfaces.batie.min;
                        // @ts-ignore
                        if (filter.surfaces.batie.max > 0) search.option.surfaceBatieMax = filter.surfaces.batie.max;
                    }
                    if (angular.isObject((filter.surfaces as unknown as Record<string, unknown>).nonBatie)) {
                        // @ts-ignore
                        if (filter.surfaces.nonBatie.min > 0) search.option.surfaceNonBatieMin = filter.surfaces.nonBatie.min;
                        // @ts-ignore
                        if (filter.surfaces.nonBatie.max > 0) search.option.surfaceNonBatieMax = filter.surfaces.nonBatie.max;
                    }
                    if (angular.isObject((filter.surfaces as unknown as Record<string, unknown>).sejour)) {
                        // @ts-ignore
                        if (filter.surfaces.sejour.min > 0) search.option.surfaceSejourMin = filter.surfaces.sejour.min;
                        // @ts-ignore
                        if (filter.surfaces.sejour.max > 0) search.option.surfaceSejourMax = filter.surfaces.sejour.max;
                    }
                }
                if (angular.isObject(filter.nombrePieces)) {
                    // @ts-ignore
                    if (filter.nombrePieces.min > 0) search.option.nombrePiecesMin = filter.nombrePieces.min;
                    // @ts-ignore
                    if (filter.superficie.max > 0) search.option.nombrePiecesMax = filter.nombrePieces.max;
                }
                if (angular.isObject(filter.nombreLogements)) {
                    // @ts-ignore
                    if (filter.nombreLogements.min > 0) search.option.nombreLogementsMin = filter.nombreLogements.min;
                    // @ts-ignore
                    if (filter.nombreLogements.max > 0) search.option.nombreLogementsMax = filter.nombreLogements.max;
                }
                if (angular.isObject(filter.nombreChambres)) {
                    // @ts-ignore
                    if (filter.nombreChambres.min > 0) search.option.nombreChambresMin = filter.nombreChambres.min;
                    // @ts-ignore
                    if (filter.nombreChambres.max > 0) search.option.nombreChambresMax = filter.nombreChambres.max;
                }
                if (angular.isObject(filter.anneeConstruction)) {
                    // @ts-ignore
                    if (filter.anneeConstruction.min > 0) search.option.anneeConstructionMin = filter.anneeConstruction.min;
                    // @ts-ignore
                    if (filter.anneeConstruction.max > 0) search.option.anneeConstructionMax = filter.anneeConstruction.max;
                }
                // @ts-ignore
                if (filter.annexe === true) search.option.annexe = true;
                // @ts-ignore
                if (filter.ascenseur === true) search.option.ascenseur = true;
                // @ts-ignore
                if (filter.balcon === true) search.option.balcon = true;
                // @ts-ignore
                if (filter.cave === true) search.option.cave = true;
                // @ts-ignore
                if (filter.cheminee === true) search.option.cheminee = true;
                // @ts-ignore
                if (filter.garage === true) search.option.garage = true;
                // @ts-ignore
                if (filter.grenier === true) search.option.grenier = true;
                // @ts-ignore
                if (filter.parking === true) search.option.parking = true;
                // @ts-ignore
                if (filter.piscine === true) search.option.piscine = true;
                // @ts-ignore
                if (filter.pointEau === true) search.option.pointEau = true;
                // @ts-ignore
                if (filter.sousSol === true) search.option.sousSol = true;
                // @ts-ignore
                if (filter.stationnement === true) search.option.stationnement = true;
                // @ts-ignore
                if (filter.terrasse === true) search.option.terrasse = true;
                // @ts-ignore
                if (filter.terrasseOuBalcon === true) search.option.terrasseOuBalcon = true;
                // @ts-ignore
                if (filter.vueExceptionnelle === true) search.option.vueExceptionnelle = true;
                // @ts-ignore
                if (isBoolean(filter.residenceService)) search.option.residenceService = filter.residenceService;
                if (angular.isObject(filter.etat) && filter.etat.condition === ConditionConst.IN && angular.isArray(filter.etat.values)) {
                    angular.forEach(filter.etat.values, (value: number) => {
                        search.etats!.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.ETATS, value)));
                    });
                }
                if (angular.isObject(filter.mitoyennete) && filter.mitoyennete.condition === ConditionConst.IN && angular.isArray(filter.mitoyennete.values)) {
                    angular.forEach(filter.mitoyennete.values, (value: number) => {
                        search.mitoyennetes!.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.MITOYENNETES, value)));
                    });
                }
                if (angular.isObject(filter.occupation) && filter.occupation.condition === ConditionConst.IN && angular.isArray(filter.occupation.values)) {
                    angular.forEach(filter.occupation.values, (value: number) => {
                        search.occupations!.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.OCCUPATIONS, value)));
                    });
                }
                if (angular.isObject(filter.plainPied) && filter.plainPied.condition === ConditionConst.IN && angular.isArray(filter.plainPied.values)) {
                    angular.forEach(filter.plainPied.values, (value: number) => {
                        search.plainPieds!.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.PLAIN_PIEDS, value)));
                    });
                }
                if (angular.isObject(filter.vue) && filter.vue.condition === ConditionConst.IN && angular.isArray(filter.vue.values) && filter.vue.values.length > 0) {
                    angular.forEach(filter.vue.values, (value: number) => {
                        search.vues!.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.VUES, value)));
                    });
                }
                if (angular.isObject(filter.archive)) {
                    // @ts-ignore
                    search.archive = {};
                    if (angular.isObject((filter.archive as Record<string, unknown>).dateVente)) {
                        // @ts-ignore
                        if (filter.archive.dateVente.min) search.archive.dateVenteMin = DateFormat.toDate(filter.archive.dateVente.min);
                        // @ts-ignore
                        if (filter.archive.dateVente.max) search.archive.dateVenteMax = DateFormat.toDate(filter.archive.dateVente.max);
                    }
                    if (angular.isObject((filter.archive as Record<string, unknown>).prixCession)) {
                        // @ts-ignore
                        if (filter.archive.prixCession.min > 0) search.archive.prixCessionMin = filter.archive.prixCession.min;
                        // @ts-ignore
                        if (filter.archive.prixCession.max > 0) search.archive.prixCessionMax = filter.archive.prixCession.max;
                    }
                    if (angular.isObject((filter.archive as Record<string, unknown>).type)
                        && (filter.archive as unknown as {
                            type: Record<string, unknown>
                        }).type.condition === ConditionConst.IN
                        && angular.isArray((filter.archive as unknown as {
                            type: Record<string, unknown>
                        }).type.values)) {
                        // @ts-ignore
                        search.archive.types = [];
                        angular.forEach((filter.archive as unknown as {
                            type: Record<string, unknown>
                        }).type.values, function (value) {
                            // @ts-ignore
                            search.archive.types.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_TYPES, value)));
                        });
                    }
                }
                // @ts-ignore
                if (angular.isObject(filter.responsableDossier)) search.responsableDossier = filter.responsableDossier;
                // @ts-ignore
                if (angular.isObject(filter.etude)) search.etude = filter.etude;
                // @ts-ignore
                if (angular.isObject(filter.dateModification)) search.dateModification = filter.dateModification;
                // @ts-ignore
                if (angular.isObject(filter.tris)) search.tris = filter.tris;

                return $q.all(promises).then(() => search);
            }

            /**
             * Set data for current route
             *
             * @param route
             * @param data
             */
            function setDataForRoute(route: string, data: unknown) {
                dataForRoute[route] = data;
            }

            /**
             * Set data for current route
             *
             * @param data
             */
            function setDataForCurrentRoute(data: unknown) {
                factory.setDataForRoute(ng2RouterStateForNgService.current.name!, data);
            }

            /**
             * Get data from parent route
             *
             * @param childRoute
             * @return {*}
             */
            function getDataFromParentRoute(childRoute: string) {
                const explodedChildRoute = childRoute.split('/');

                explodedChildRoute.splice(-1, 1);
                if (explodedChildRoute.at(-1) === 'details') {
                    explodedChildRoute.splice(-1, 1);
                }

                const parentRoute = explodedChildRoute.join('/');

                return dataForRoute[parentRoute];
            }

            /**
             * Sum of array term by term
             *
             * @param array1
             * @param array2
             * @return {Array}
             */
            function getArraySum(array1: number[], array2: number[]) {
                const arraySum: number[] = [];

                for (let i = 0; i < array1.length; i++) {
                    arraySum.push(array1[i] + array2[i]);
                }

                return arraySum;
            }

            /**
             * Get period by months
             *
             * @param dateDebut
             * @param dateFin
             * @return {Array}
             */
            function getPeriodByMonths(dateDebut: Date, dateFin: Date): Date[] {
                const months: Date[] = [dateDebut];

                while (DateFormat.diff(months[months.length - 1].toString(), dateFin.toString()) < 0) {
                    months.push(DateFormat.toDate(DateFormat.addMonthsToDateString(months[months.length - 1].toString(), 1)));
                }

                return months;
            }

            function createNgSearchFromNg2(searchCriteria = {} as SearchCriteria): NgSearch {
                const ngSearch = {
                    areas: (searchCriteria.departements ?? []).map(departement => areaManager.createFromNg2Departement(departement)),
                    communes: (searchCriteria.communes ?? []).map(commune => communeManager.createFromNg2(commune)),
                    dateModification: searchCriteria.dateModification,
                    epoques: (searchCriteria.epoques ?? []).map(epoque => dictionariesManager.createFromNg2(epoque)),
                    etats: (searchCriteria.etats ?? []).map(etat => dictionariesManager.createFromNg2(etat)),
                    mitoyennetes: (searchCriteria.mitoyennetes ?? []).map(mitoyennete => dictionariesManager.createFromNg2(mitoyennete)),
                    natures: (searchCriteria.natures ?? []).map(nature => dictionariesManager.createFromNg2(nature)),
                    occupations: (searchCriteria.occupations ?? []).map(occupation => dictionariesManager.createFromNg2(occupation)),
                    option: {
                        accesPmr: searchCriteria.accesPmr,
                        anneeConstructionMax: searchCriteria.anneeConstructionMax,
                        anneeConstructionMin: searchCriteria.anneeConstructionMin,
                        annexe: searchCriteria.annexe,
                        ascenseur: searchCriteria.ascenseur,
                        balcon: searchCriteria.balcon,
                        exceptionnel: searchCriteria.bienExceptionnel,
                        rdc: searchCriteria.bienRdc,
                        cave: searchCriteria.cave,
                        chambreRdc: searchCriteria.chambreRdc,
                        cheminee: searchCriteria.cheminee,
                        dernierEtage: searchCriteria.dernierEtage,
                        garage: searchCriteria.garage,
                        grenier: searchCriteria.grenier,
                        nombreChambresMax: searchCriteria.nombreChambresMax,
                        nombreChambresMin: searchCriteria.nombreChambresMin,
                        nombreEtagesMax: searchCriteria.nombreEtagesMax,
                        nombreEtagesMin: searchCriteria.nombreEtagesMin,
                        nombreLogementsMax: searchCriteria.nombreLogementsMax,
                        nombreLogementsMin: searchCriteria.nombreLogementsMin,
                        nombrePiecesMax: searchCriteria.nombrePiecesMax,
                        nombrePiecesMin: searchCriteria.nombrePiecesMin,
                        nomVoie: searchCriteria.nomVoie,
                        numeroVoie: searchCriteria.numeroVoie,
                        parking: searchCriteria.parking,
                        pieceEauRdc: searchCriteria.pieceEauRdc,
                        piscine: searchCriteria.piscine,
                        pointEau: searchCriteria.pointEau,
                        residenceService: searchCriteria.residenceService,
                        source: searchCriteria.source,
                        sousSol: searchCriteria.sousSol,
                        stationnement: searchCriteria.stationnement,
                        surfaceBatieMax: searchCriteria.surfaceBatieMax,
                        surfaceBatieMin: searchCriteria.surfaceBatieMin,
                        surfaceCadastraleMax: searchCriteria.surfaceCadastraleMax,
                        surfaceCadastraleMin: searchCriteria.surfaceCadastraleMin,
                        surfaceNonBatieMax: searchCriteria.surfaceNonBatieMax,
                        surfaceNonBatieMin: searchCriteria.surfaceNonBatieMin,
                        surfaceSejourMax: searchCriteria.surfaceSejourMax,
                        surfaceSejourMin: searchCriteria.surfaceSejourMin,
                        terrasse: searchCriteria.terrasse,
                        terrasseOuBalcon: searchCriteria.terrasseOuBalcon,
                        uniquementBiensNotaire: searchCriteria.uniquementBiensNotaire,
                    },
                    plainPieds: (searchCriteria.plainPieds ?? []).map(plainPied => dictionariesManager.createFromNg2(plainPied)),
                    quartiers: (searchCriteria.quartiers ?? []).map(quartier => quartierManager.createFromNg2(quartier.communeId, quartier)),
                    statuts: (searchCriteria.statuts ?? []).map(statut => statut.code),
                    superficieMax: searchCriteria.superficieMax,
                    superficieMin: searchCriteria.superficieMin,
                    tris: searchCriteria.tris,
                    type: searchCriteria.typeSearch,
                    vues: (searchCriteria.vues ?? []).map(vue => dictionariesManager.createFromNg2(vue)),
                } as unknown as NgSearch;

                ngSearch.areas?.concat((searchCriteria.regions ?? []).map(region => areaManager.createFromNg2Region(region)));

                if (searchCriteria.archiveDateVenteMax || searchCriteria.archiveDateVenteMin || searchCriteria.archivePrixCessionMax || searchCriteria.archivePrixCessionMin || (searchCriteria.archiveTypes || []).length > 0) {
                    ngSearch.archive = {
                        dateVenteMax: (searchCriteria.archiveDateVenteMax ? DateFormat.toDate(searchCriteria.archiveDateVenteMax) : null) as unknown as string,
                        dateVenteMin: (searchCriteria.archiveDateVenteMin ? DateFormat.toDate(searchCriteria.archiveDateVenteMin) : null) as unknown as string,
                        prixCessionMax: searchCriteria.archivePrixCessionMax,
                        prixCessionMin: searchCriteria.archivePrixCessionMin,
                        types: (searchCriteria.archiveTypes ?? []).map(type => dictionariesManager.createFromNg2(type)),
                    };
                }

                if (searchCriteria.chargesIncluses || searchCriteria.locationMeuble || searchCriteria.loyerMax || searchCriteria.loyerMin) {
                    ngSearch.location = {
                        chargesIncluses: searchCriteria.chargesIncluses,
                        meuble: searchCriteria.locationMeuble,
                        loyerMax: searchCriteria.loyerMax,
                        loyerMin: searchCriteria.loyerMin,
                    };
                }

                if (searchCriteria.voie) {
                    ngSearch.option.voie = communeManager.createVoieFromNg2(searchCriteria.voie);
                }

                if (searchCriteria.budgetHni || searchCriteria.budgetMax || searchCriteria.budgetMin || searchCriteria.excludeVenteInteractive || searchCriteria.excludeVenteTraditionnelle || searchCriteria.excludeVenteViager || searchCriteria.renteMax || searchCriteria.renteMin) {
                    ngSearch.vente = {
                        budgetHni: searchCriteria.budgetHni,
                        budgetMax: searchCriteria.budgetMax,
                        budgetMin: searchCriteria.budgetMin,
                        excludeVenteInteractive: searchCriteria.excludeVenteInteractive,
                        excludeVenteTraditionnelle: searchCriteria.excludeVenteTraditionnelle,
                        excludeVenteViager: searchCriteria.excludeVenteViager,
                        renteMax: searchCriteria.renteMax,
                        renteMin: searchCriteria.renteMin,
                    };
                }

                return ngSearch;
            }
        }
    })(angularJS);
}
