import {Component, Input, output} from '@angular/core';
import {EditorOptions} from 'tinymce';

@Component({selector: 'app-ng2-tinymce', templateUrl: 'ng2-tinymce.component.html'})
export class Ng2TinymceComponent {
    readonly changeContent = output<string>();
    private _options!: EditorOptions;
    private _txt!: string;

    @Input()
    set content(value: string) {
        this._txt = value;
    }

    get options(): EditorOptions {
        return this._options;
    }

    @Input()
    set options(value: EditorOptions) {
        this._options = value;
    }

    get txt(): string {
        return this._txt;
    }

    set txt(value: string) {
        this._txt = value;
        this.changeContent.emit(value);
    }
}
