import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ICollectionItem, ICollectionMovedItem} from '@shared/collection/collection.interfaces';

@Injectable({providedIn: 'root'})
export class CollectionSortableService {
    static readonly DRAGENTER_VIRGIN = 'dragenter_virgin';

    private _dragenterUuidsSource = new Subject<[string, string]>();
    private _dragenterUuids$ = this._dragenterUuidsSource.asObservable();
    private _movedItemSource = new Subject<ICollectionMovedItem>();
    private _movedItem$ = this._movedItemSource.asObservable();
    private _startUuid!: string;
    private _startIdElementList!: string;

    get dragenterUuids$(): Observable<[string, string]> {
        return this._dragenterUuids$;
    }

    get movedItem$(): Observable<ICollectionMovedItem> {
        return this._movedItem$;
    }

    dragend(): void {
        this._dragenterUuidsSource.next([this._startIdElementList, CollectionSortableService.DRAGENTER_VIRGIN]);
    }

    dragenter(uuid: string): void {
        this._dragenterUuidsSource.next([this._startIdElementList, uuid]);
    }

    end(uuid: string, collectionItems: ICollectionItem[], idElementList: string): void {
        // On ne fait rien car les 2 listes ne sont pas les mêmes
        if (this._startIdElementList !== idElementList) {
            return;
        }

        const idxToMove = collectionItems.findIndex(item => item.uuid === this._startUuid);
        const idxToTake = collectionItems.findIndex(item => item.uuid === uuid);
        const itemToMove = collectionItems[idxToMove];

        collectionItems.splice(idxToMove, 1);
        collectionItems.splice(idxToTake, 0, itemToMove);
        this._movedItemSource.next({idx: idxToTake, item: itemToMove});
        this.dragend();
    }

    isDragover(uuid: string, idElementList: string): boolean {
        return uuid !== this._startUuid && idElementList === this._startIdElementList;
    }

    start(uuid: string, idElementList: string): void {
        this._startUuid = uuid;
        this._startIdElementList = idElementList;
    }
}

/*
Export class CollectionSortableService {
    static readonly DRAGENTER_VIRGIN = 'dragenter_virgin';

    private _mapConfiguration = new Map<string, {
        dragenterUuidsSource: Subject<[string, string]>;
        dragenterUuids$: Observable<[string, string]>;
        movedItemSource: Subject<ICollectionMovedItem>;
        movedItem$: Observable<ICollectionMovedItem>;
        startUuid: string;
        startIdElementList: string;
    }>();

    delete(name: string): void {
        this._mapConfiguration.delete(name);
    }

    dragend(name: string): void {
        const configuration = this._mapConfiguration.get(name);

        if (!configuration) {
            return;
        }

        configuration.dragenterUuidsSource.next([configuration.startIdElementList, CollectionSortableService.DRAGENTER_VIRGIN]);
    }

    dragenter(name: string, uuid: string): void {
        const configuration = this._mapConfiguration.get(name);

        if (!configuration) {
            return;
        }

        configuration.dragenterUuidsSource.next([configuration.startIdElementList, uuid]);
    }

    end(name: string, uuid: string, collectionItems: ICollectionItem[], idElementList: string): void {
        const configuration = this._mapConfiguration.get(name);

        if (!configuration) {
            return;
        }

        // On ne fait rien car les 2 listes ne sont pas les mêmes
        if (configuration.startIdElementList !== idElementList) {
            return;
        }

        const idxToMove = collectionItems.findIndex(item => item.uuid === configuration.startUuid);
        const idxToTake = collectionItems.findIndex(item => item.uuid === uuid);
        const itemToMove = collectionItems[idxToMove];

        collectionItems.splice(idxToMove, 1);
        collectionItems.splice(idxToTake, 0, itemToMove);
        configuration.movedItemSource.next({item: itemToMove, idx: idxToTake});
        this.dragend(name);
    }

    getDragenterUuids$(name: string): Observable<[string, string]> {
        const configuration = this._mapConfiguration.get(name);

        console.log('getDragenterUuids$', name, configuration);
        if (!configuration) {
            return of(undefined as unknown as [string, string]);
        }

        return configuration.dragenterUuids$;
    }

    getMovedItem$(name: string): Observable<ICollectionMovedItem> {
        const configuration = this._mapConfiguration.get(name);

        if (!configuration) {
            return of(undefined as unknown as ICollectionMovedItem);
        }

        return configuration.movedItem$;
    }

    init(name: string): void {
        if (this._mapConfiguration.get(name)) {
            this._mapConfiguration.delete(name);
        }

        const dragenterUuidsSource = new ReplaySubject<[string, string]>;
        const movedItemSource = new ReplaySubject<ICollectionMovedItem>;

        this._mapConfiguration.set(name, {
            dragenterUuidsSource,
            dragenterUuids$: dragenterUuidsSource.asObservable(),
            movedItemSource,
            movedItem$: movedItemSource.asObservable(),
            startUuid: undefined!,
            startIdElementList: undefined!,
        });
    }

    isDragover(name: string, uuid: string, idElementList: string): boolean {
        const configuration = this._mapConfiguration.get(name);

        if (!configuration) {
            return false;
        }

        return uuid !== configuration.startUuid && idElementList === configuration.startIdElementList;
    }

    isInit$(name: string): Observable<boolean> {
        const isInitSource = new BehaviorSubject<boolean>(false);
        const endSource = new Subject<void>();

        interval(10).pipe(
            map(_ => this._mapConfiguration.has(name)),
            filter(isInit => !!isInit),
            takeUntil(endSource),
        ).subscribe(_ => {
            isInitSource.next(true);
            endSource.next();
            endSource.complete();
        });

        return isInitSource.asObservable();
    }

    start(name: string, uuid: string, idElementList: string): void {
        const configuration = this._mapConfiguration.get(name);

        if (!configuration) {
            return;
        }

        configuration.startUuid = uuid;
        configuration.startIdElementList = idElementList;
    }


    /!* Méthodes utilisées dans CollectionSelectionService

        isInit$() => Liste initialisation
        getListSelected$() => Liste initialisation

        delete() => Liste destroy

        init() => Dans la directive ?

        clear() => Vérifier pertinence
        getNumberSelected() => Vérifier pertinence
        hasValues() => Vérifier pertinence
        indeterminate() => Vérifier pertinence
        isAllSelected() => Vérifier pertinence
        isSelected() => Vérifier pertinence
        itemIsEnabled() => Vérifier pertinence
        itemTitleDisabled() => Vérifier pertinence
        operateListSelected$() => Vérifier pertinence
        toggle() => Vérifier pertinence
        toggleAll => Vérifier pertinence

    *!/
}
*/
