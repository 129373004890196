import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {OffreachatFactory} from '@models/offreachats/offreachat/offreachat.factory';
import {map, take, tap} from 'rxjs/operators';
import {IOffreachatAddedInformation} from '@models/offreachats/offreachat/offreachat.interfaces';
import {DemandeurService} from '@core/models/demandeurs/demandeur/demandeur.service';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {CTemplatesService} from '@models/templates/collection/templates.collection.service';
import Procedure from '@models/procedures/procedure/procedure.model';
import {CProceduresService} from '@core/models/procedures/collection/procedures.collection.service';
import {ModalService} from '@shared/modal/modal.service';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';
import Vente from '@models/ventes/vente/vente.model';
import TemplateCategory from '@models/templates/template/category/template-category.model';
import {ProcedureFactory} from '@models/procedures/procedure/procedure.factory';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';

@Injectable({providedIn: 'root'})
export class OffreachatService {
    static readonly messages = {
        archive: {
            CONFIRMATION: 'Voulez-vous vraiment archiver cette offre d\'achat ?',
            PROCEDURE_IN_PROGRESS: 'Une procédure de signature est en cours.<br>Attendez le retour des signataires ou annulez-la.',
            TITLE: 'Archivage de l\'ofrfe d\'achat',
        },
        launchAcquereurs: {
            NO_ONE: 'Au moins un signataire "Acquéreur" est requis pour lancer la signature électronique de votre document.',
            TITLE: 'Saisie des signataires',
        },
    };
    private _cProceduresService = inject(CProceduresService);
    private _cTemplatesService = inject(CTemplatesService);
    private _demandeurFactory = inject(DemandeurFactory);
    private _demandeurService = inject(DemandeurService);
    private _modalService = inject(ModalService);
    private _offreachatFactory = inject(OffreachatFactory);
    private _procedureFactory = inject(ProcedureFactory);
    private _procedureService = inject(ProcedureService);
    private _venteFactory = inject(VenteFactory);
    private _ventePriceFactory = inject(VentePriceFactory);
    private _currentSource = new BehaviorSubject<Offreachat>(undefined!);
    private _current$ = this._currentSource.asObservable();
    private _lastEditedSubject = new Subject<Offreachat>();

    get current$(): Observable<Offreachat> {
        return this._current$;
    }

    get lastEdited$(): Observable<Offreachat> {
        return this._lastEditedSubject.asObservable();
    }

    archive$(offreachat: Offreachat): Observable<boolean> {
        return this.getProcedure$(offreachat).pipe(
            switchMap(procedure => {
                if (!procedure || procedure.isNewOrDraft() || procedure.isClosed()) {
                    return this._modalService.openConfirmation$({
                        buttonConfirmationLabel: 'Archiver',
                        question: OffreachatService.messages.archive.CONFIRMATION,
                        title: OffreachatService.messages.archive.TITLE,
                        status: ModalService.status.WARNING,
                    }).pipe(switchMap(confirmation => {
                        if (confirmation) {
                            return this._offreachatFactory.archive$(offreachat).pipe(
                                tap(_ => this._lastEditedSubject.next(offreachat)),
                                map(_ => true),
                            );
                        }

                        return of(false);
                    }));
                }

                return this._modalService.openInformation$({
                    comments: OffreachatService.messages.archive.PROCEDURE_IN_PROGRESS,
                    title: OffreachatService.messages.archive.TITLE,
                    status: ModalService.status.WARNING,
                }).pipe(map(_ => false));
            }),
        );
    }

    archiveCurrent$(): Observable<boolean> {
        return this.current$.pipe(
            take(1),
            switchMap(offreachat => this.archive$(offreachat)),
            switchMap(isArchived => this.updateCurrent$().pipe(map(_ => isArchived))),
        );
    }

    cancelSignCurrent$(): Observable<Offreachat> {
        return this.current$.pipe(
            take(1),
            switchMap(offreachat => this._procedureFactory.getByLink$(offreachat.isSignatureAcquereur() ? offreachat.linkProcedureAcquereur : offreachat.linkProcedureVendeur)),
            switchMap(procedure => this._procedureService.cancel$(procedure)),
            switchMap(isCanceled => isCanceled ? this.updateCurrent$() : of(undefined as unknown as Offreachat)),
        );
    }

    getDossiers$(offreachat: Offreachat): Observable<ADossier[]> {
        const dossiers$: Observable<ADossier>[] = [];

        if (offreachat.linkDemandeur) {
            dossiers$.push(this._demandeurFactory.getByLink$(offreachat.linkDemandeur));
        }

        if (offreachat.linkVente) {
            dossiers$.push(this._venteFactory.getByLink$(offreachat.linkVente));
        }

        return dossiers$.length > 0 ? combineLatest(dossiers$) : of([]);
    }

    getDossiersCurrent$(): Observable<ADossier[]> {
        return this.current$.pipe(take(1), switchMap(offreachat => this.getDossiers$(offreachat)));
    }

    getOne$(uuid: string, addedInformation: IOffreachatAddedInformation = {}): Observable<Offreachat> {
        if (uuid === Offreachat.statuts.NEW) {
            const offreachat = this._offreachatFactory.createVirgin(Offreachat.statuts.NEW);

            if (addedInformation.demandeur) {
                offreachat.demandeurUuid = addedInformation.demandeur.uuid;
                offreachat.linkDemandeur = addedInformation.demandeur.linkSelf;
            }

            if (addedInformation.vente) {
                offreachat.linkVente = addedInformation.vente.linkSelf;
                offreachat.venteUuid = addedInformation.vente.uuid;
            }

            return of(offreachat);
        }

        return this._offreachatFactory.get$(uuid);
    }

    getProcedure$(offreachat: Offreachat): Observable<Procedure> {
        if (offreachat.isSignatureAcquereur()) {
            return this.getProcedureAcquereur$(offreachat);
        } else if (offreachat.isSignatureVendeur()) {
            return this.getProcedureVendeur$(offreachat);
        }

        // Conservation de la compatibilité avec les anciennes offres d'achat qui ont un mode de signature en 1 fois
        return offreachat.linkProcedures ? this._cProceduresService.getFirst$(offreachat.linkProcedures) : of(undefined as unknown as Procedure);
    }

    getProcedureAcquereur$(offreachat: Offreachat): Observable<Procedure> {
        return offreachat.linkProcedureAcquereur ? this._procedureFactory.getByLink$(offreachat.linkProcedureAcquereur) : of(undefined as unknown as Procedure);
    }

    getProcedureVendeur$(offreachat: Offreachat): Observable<Procedure> {
        return offreachat.linkProcedureVendeur ? this._procedureFactory.getByLink$(offreachat.linkProcedureVendeur) : of(undefined as unknown as Procedure);
    }

    initCurrent(uuid: string, addedInformation: IOffreachatAddedInformation = {}): void {
        this.razCurrent();
        this.getOne$(uuid, addedInformation).pipe(take(1)).subscribe(offreachat => this._currentSource.next(offreachat));
    }

    launch$(offreachat: Offreachat): Observable<Offreachat> {
        return this._procedureFactory.getByLink$(offreachat.isSignatureAcquereur() ? offreachat.linkProcedureAcquereur : offreachat.linkProcedureVendeur).pipe(
            switchMap(procedure => this._procedureService.getCProcedureSignataires$(procedure).pipe(
                switchMap(cProcedureSignataires => {
                    if (cProcedureSignataires.total > 0) {
                        return this._procedureService.launch$(procedure).pipe(
                            switchMap(_ => this._offreachatFactory.getByLink$(offreachat.linkSelf)),
                        );
                    }

                    return this._modalService.openInformation$({
                        comments: OffreachatService.messages.launchAcquereurs.NO_ONE,
                        title: OffreachatService.messages.launchAcquereurs.TITLE,
                        status: ModalService.status.WARNING,
                    }).pipe(map(_ => undefined!));
                }),
            )),
        );
    }

    razCurrent(): void {
        this._currentSource.next(undefined!);
    }

    saveCurrent$(informationToSave: IOffreachatAddedInformation = {}): Observable<Offreachat> {
        return this.current$.pipe(
            take(1),
            switchMap(offreachat => {
                let demandeur$ = of(informationToSave.demandeur);

                offreachat.linkVente = informationToSave.vente?.linkSelf as unknown as string;
                offreachat.venteUuid = informationToSave.vente?.uuid as unknown as string;
                if (informationToSave.demandeur?.uuid === Demandeur.statuts.NEW) {
                    demandeur$ = this._demandeurService.save$(informationToSave.demandeur);
                }

                return demandeur$.pipe(
                    tap(demandeur => {
                        if (demandeur) {
                            offreachat.demandeurUuid = demandeur.uuid;
                            offreachat.linkDemandeur = demandeur.linkSelf;
                        }
                    }),
                    map(_ => offreachat),
                );
            }),
            switchMap(offreachat => this._offreachatFactory.save$(offreachat)),
            tap(offreachat => this._currentSource.next(offreachat)),
        );
    }

    signCurrent$(provider: string): Observable<Offreachat> {
        return this.current$.pipe(
            take(1),
            switchMap(offreachat => this._offreachatFactory.sign$(offreachat, provider)),
            switchMap(_ => this.updateCurrent$()),
        );
    }

    updateCurrent$(): Observable<Offreachat> {
        return this.current$.pipe(
            take(1),
            switchMap(offreachat => this._offreachatFactory.get$(offreachat.uuid)),
            tap(offreachat => this._currentSource.next(offreachat)),
        );
    }

    updateFromVentePrice$(vente: Vente, offreachat: Offreachat): Observable<void> {
        if (!offreachat.montant && offreachat.montant !== 0) {
            return of(undefined);
        }

        const initialMontant = offreachat.montant;

        return this._ventePriceFactory.getFromOffreachat$(vente, offreachat).pipe(
            tap(ventePrice => {
                if (initialMontant === offreachat.montant) {
                    offreachat.honorairesNego = ventePrice.honoraires;
                }
            }),
            map(_ => undefined),
        );
    }

    writeCurrent$(): Observable<Offreachat> {
        return this._cTemplatesService.getWithDefaultFirst$([TemplateCategory.codes.OFFREACHAT]).pipe(
            switchMap(cTemplates => combineLatest([this.current$, of(cTemplates.results[0])])),
            take(1),
            switchMap(([offreachat, template]) => this._offreachatFactory.write$(offreachat, template)),
            switchMap(_ => this.updateCurrent$()),
        );
    }
}
