import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgPhotoManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgBien, NgBienPhoto} from '@legacy/app/managers/ressources';
import BienPhoto from '@models/bien/photos/photo/bien-photo.model';

export default function getManagersBienPhoto(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienPhotoManager', manager);

        /**
         * Manager bienPhoto
         *
         * @param BaseManager
         * @param PhotoManager
         * @param UtilsManager
         * @returns {BienPhotoManager}
         */
        manager.$inject = ['BaseManager', 'PhotoManager', 'UtilsManager'];
        function manager(baseManager: NgManager, photoManager: NgPhotoManager, utilsManager: NgUtilsManager) {
            let classResource = baseManager.getClass<NgBienPhoto>();

            /**
             * Routing
             */
            baseManager.addRoute('bien-photos.cget', {path: '/biens/{bienId}/photos', method: 'GET'});
            baseManager.addRoute('bien-photos.insert', {path: '/biens/{bienId}/photos', method: 'POST'});
            baseManager.addRoute('bien-photos.edit', {path: '/biens/{bienId}/photos/{id}', method: 'PUT'});
            baseManager.addRoute('bien-photos.update-rank', {
                path: '/biens/{bienId}/photos/{id}/rank',
                method: 'PATCH'
            });
            baseManager.addRoute('bien-photos.remove', {path: '/biens/{bienId}/photos/{id}', method: 'DELETE'});

            /**
             * BienPhotoManager object
             *
             * @constructor
             */
            angular.extend(BienPhotoManager.prototype, baseManager.__proto__);
            BienPhotoManager.prototype.eskManager = {prefixRoute: 'bien-photos', collectionName: 'items'};
            function BienPhotoManager() {
            }
            BienPhotoManager.prototype.createFromNg2 = function (bien: NgBien, ng2BienPhoto: BienPhoto) {
                if (!ng2BienPhoto) {
                    return undefined!;
                }

                const toto = this.create({
                    _links: {file: {href: ng2BienPhoto.linkFile}},
                    photo: {
                        bottomRightCoordinates: ng2BienPhoto.bottomRightCoordinates,
                        id: ng2BienPhoto.photo.id,
                        topLeftCoordinates: ng2BienPhoto.topLeftCoordinates,
                    },
                    bottomRightCoordinates: ng2BienPhoto.bottomRightCoordinates,
                    topLeftCoordinates: ng2BienPhoto.topLeftCoordinates,
                    uuid: ng2BienPhoto.uuid,
                    visibilite: ng2BienPhoto.visibilite,
                }, bien.id);

                return toto;
            };

            /**
             * Create a BienPhoto object
             *
             * @param data
             * @param bienId
             * @returns manager.BienPhoto object || Array of manager.BienPhoto objects
             */
            BienPhotoManager.prototype.create = function (data: unknown, bienId: number) {
                if (angular.isArray(data)) {
                    var bienPhotos = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        bienPhotos.push(new BienPhoto(bienId, data[i]));
                    }

                    return bienPhotos;
                }

                // @ts-ignore
                return new BienPhoto(bienId, data);
            };

            /**
             * BienPhoto object
             *
             * @param bienId
             * @param data
             * @constructor
             */
            angular.extend(BienPhoto.prototype, classResource.prototype);
            BienPhoto.prototype.eskManager = {prefixRoute: 'bien-photos'};
            function BienPhoto(this: NgBienPhoto, bienId: number, data: unknown) {
                this.visibilite = 'publique';
                this.extend(data);
                this.id = (angular.isObject(this._links) && angular.isObject(this._links.file) && angular.isString(this._links.file.href)) as unknown as number;
                this._esk.defaultRouteParams = {id: this.photo.id, bienId: bienId};
                this._esk.persist = !!this.id;
                this._esk.flush = this._esk.persist;
            }

            /**
             * Extend the existing BienPhoto with new data
             *
             * @param data
             * @returns {BienPhoto}
             */
            BienPhoto.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this.photo = photoManager.create(this.photo);
                this._esk.href = utilsManager.createPhotoHref(this);

                return this;
            };

            /**
             * Sanitize object BienPhoto before send to API
             *
             * @returns {object}
             */
            BienPhoto.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet BienPhoto pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'photo');

                return sanitizeObject;
            };

            /**
             * Returns the promise of save
             *
             * @returns {Promise}
             */
            BienPhoto.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    self._esk.href = utilsManager.createPhotoHref(self, true);
                    self.id = angular.isObject(self._links) && angular.isObject(self._links.file) && angular.isString(self._links.file.href);
                    self._esk.defaultRouteParams.id = self.photo.id;
                    self._esk.persist = self.id;
                    self._esk.flush = self._esk.persist;

                    return self;
                });
            };

            /**
             * Test validity and fill _esk.errors
             *
             * @returns {boolean}
             */
            BienPhoto.prototype.isValid = function () {
                this._esk.errors = {};

                return true;
            };

            /**
             * Test if BienPhoto is empty
             *
             * @returns {boolean}
             */
            BienPhoto.prototype.isEmpty = function () {
                return false;
            };

            // @ts-ignore
            return new BienPhotoManager();
        }
    })(angularJS);
}
