import angularJS from '@shared/angularJS/global.ng';
import {find} from 'lodash';
import {IModule} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgDemandeurManager, NgEmailManager, NgFilterManager} from '@legacy/app/managers/managers';
import {NgFilter} from '@legacy/app/managers/ressources';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import Filter from '@models/filters/filter/filter.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';

export default function getDemandeurLifetimeCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDemandeurLifetimeCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/controllers/item/lifetime.html',
        });

        /**
         * Controller of Lifetime Demandeur
         *
         * @param DemandeurManager
         * @param SoqModalesManager
         * @param Ng2RouterStateForNgService
         * @param $translate
         * @param Ng2ToasterService
         * @param FilterManager
         * @param EmailManager
         * @constructor
         */
        Controller.$inject = ['DemandeurManager', 'SoqModalesManager', 'Ng2RouterStateForNgService', '$translate', 'Ng2ToasterService', 'FilterManager', 'EmailManager'];
        function Controller(this: any,
                            demandeurManager: NgDemandeurManager,
                            soqModalesManager: NgSoqModalesManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $translate: angular.translate.ITranslateService,
                            ng2ToasterService: ToasterService,
                            filterManager: NgFilterManager,
                            emailManager: NgEmailManager) {
            const $ctrl = this;

            $ctrl.archive = archive;
            $ctrl.createEmail = createEmail;
            $ctrl.BonvisiteStatutNEW = Bonvisite.statuts.NEW;
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.OffreachatStatutNEW = Offreachat.statuts.NEW;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            $ctrl.STATUT_ARCHIVE = Demandeur.statuts.ARCHIVE;
            filterManager.getActiveByRouteCode(Filter.routes.GET_RECHERCHE_RESULTATS, Filter.codes.WALLET_CURRENT).then(filter => filter as NgFilter).then(filter => {
                $ctrl.demandeur = demandeurManager.current;
                $ctrl.resultsOptions = {
                    displayMode: 'box',
                    visibleFields: {
                        archiveDateVente: false,
                        mandatDateFin: false,
                        nomContactsGroup: false,
                        nombreChambres: false,
                        nombrePieces: false,
                        preciseLocalisation: false,
                        priceCession: false,
                        select: false,
                        statut: false
                    },
                    loader: {updating: false},
                };
                if ($ctrl.demandeur.recherches.length > 0) {
                    $ctrl.resultsOptions.loader.updating = true;
                    $ctrl.demandeur.recherches[0].getResults(angular.extend({limit: 4}, filter.getParamsRequest())).then(function () {
                        angular.forEach($ctrl.demandeur.recherches[0]._esk.dossiersCorrespondants.collection, function (dossier) {
                            const predicate = {[dossier._esk.typeDossier]: {id: dossier.id}};

                            dossier._esk.routing = '/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/resultats/' + find($ctrl.demandeur.recherches[0].results.collection, predicate).id.toString();
                        });
                    }).finally(function () {
                        $ctrl.resultsOptions.loader.updating = false;
                    });
                }
            });

            /**
             * Create email
             */
            function createEmail() {
                soqModalesManager.open('EskModalEmail', {
                    resolve: {
                        email: emailManager.create({
                            recipients: $ctrl.demandeur.contactsGroup.getRecipients(),
                            dossiers: [
                                {
                                    typeId: $ctrl.demandeur.id,
                                    type: $ctrl.demandeur._esk.typeDossier,
                                    dossier: $ctrl.demandeur,
                                },
                            ],
                        }),
                        currentDossier: $ctrl.demandeur,
                    }
                });
            }

            /**
             * Open modal demandeur archive
             */
            function archive() {
                soqModalesManager.open('DemandeurModalArchive', {
                    resolve: {demandeur: $ctrl.demandeur}
                }).then(function () {
                    ng2ToasterService.success($translate.instant('demandeur.archive.realised.TITLE'), $translate.instant('demandeur.archive.realised.MESSAGE', {reference: $ctrl.demandeur.reference}));
                    ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/portefeuille');
                });
            }
        }
    })(angularJS);
}
