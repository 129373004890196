import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule, IQService} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgContactManager} from '@legacy/app/managers/managers';
import {NgContact} from '@legacy/app/managers/ressources';

export default function getBienModalShareDocumentsCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('BienModalShareDocumentsCtrl', Controller);

        /**
         * Controller for share documents
         *
         * @param documents
         * @param ContactManager
         * @param $uibModalInstance
         * @param $q
         * @param SoqSweetAlert
         * @param $translate
         */
        Controller.$inject = ['documents', 'ContactManager', '$uibModalInstance', '$q', 'SoqSweetAlert', '$translate'];
        function Controller(this: any,
                            documents: unknown[],
                            contactManager: NgContactManager,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            $q: IQService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService) {
            const $ctrl = this;

            $ctrl.changeTypeAddContacts = changeTypeAddContacts;
            $ctrl.saveNewContact = saveNewContact;
            $ctrl.addContact = addContact;
            $ctrl.share = share;
            $ctrl.options = {
                nameDossiers: 'CONTACTS_LIST',
                loader: {},
                contactsSearch: {
                    dataValidation: function (contacts: NgContact[]) {
                        const validatedContacts: NgContact[] = [];

                        angular.forEach(contacts, function (contact) {
                            if (angular.isString(contact[contact.type]?.emailAddress) && contact[contact.type]?.emailAddress !== '') {
                                validatedContacts.push(contact);
                            }
                        });

                        return validatedContacts;
                    }
                },
                visibleFields: {edition: false}
            };
            $ctrl.documents = documents;
            $ctrl.contacts = {totalItems: 0, collection: []};
            if ($ctrl.documents.length === 1) {
                angular.forEach($ctrl.documents[0].contacts, $ctrl.addContact);
            }

            /**
             * Change
             *
             * @param typeAddContacts
             */
            function changeTypeAddContacts(typeAddContacts: string) {
                $ctrl.options.typeAddContacts = typeAddContacts;
                if ($ctrl.options.typeAddContacts === 'CONTACT_NEW') $ctrl.currentContact = contactManager.create();
            }

            /**
             * Save new contact
             */
            function saveNewContact() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant('formulaire.invalid.TITLE'), $translate.instant('formulaire.invalid.MESSAGE'));

                    return;
                }

                $ctrl.options.loader.contactSaving = true;
                $ctrl.currentContact.save().then($ctrl.addContact).finally(function () {
                    $ctrl.options.loader.contactSaving = false;
                    $ctrl.changeTypeAddContacts('CONTACT_EXISTING');
                });
            }

            /**
             * Add selected contact to collection
             *
             * @param contact
             */
            function addContact(contact: NgContact) {
                if (angular.isObject(contact)) {
                    contact._esk.select = true;
                    $ctrl.contacts.collection.push(contact);
                    $ctrl.contacts.totalItems = $ctrl.contacts.collection.length;
                }
            }

            /**
             * Close modal and return selected contacts
             */
            function share() {
                var selectedContacts = filter($ctrl.contacts.collection, {_esk: {select: true}});
                var promise = $q.resolve();

                if (selectedContacts.length <= 0) {
                    promise = soqSweetAlert.warningMessage($translate.instant('bien.documents.share.no_contact_selected.TITLE', {nbDocuments: $ctrl.documents.length}), $translate.instant('bien.documents.share.no_contact_selected.MESSAGE', {nbDocuments: $ctrl.documents.length}));
                }

                promise.then(() => $uibModalInstance.close(selectedContacts));
            }
        }
    })(angularJS);
}
