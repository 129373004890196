import {Component, inject, Input, OnInit, output} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {IFormCheckboxListOptions, IFormFieldCheckbox} from '@shared/form/form.interfaces';
import User from '@models/users/user/user.model';
import {Observable, ReplaySubject} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {CUsersFactory} from '@models/users/collection/users.collection.factory';
import {ConditionConst} from '@shared/constants';

@Component({selector: 'app-users-checkbox', templateUrl: 'users-checkbox.component.html'})
export class AppUsersCheckboxComponent implements OnInit {
    static readonly initUsersCheckboxListOptions: IFormCheckboxListOptions = {
        bindLinkImage: 'linkPhotoThumbnail',
        name: 'users-checkbox',
    };
    readonly selected = output<User[]>();
    private _cUsersFactory = inject(CUsersFactory);
    private _etudes: Etude[] = [];
    private _model: User[] = [];
    private _options: IFormCheckboxListOptions = {...AppUsersCheckboxComponent.initUsersCheckboxListOptions};
    private _roles: string[] = [];
    private _usersSource = new ReplaySubject<User[]>(1);
    private _users$ = this._usersSource.asObservable();

    @Input()
    set etudes(value: Etude[]) {
        this._etudes = value;
    }

    get model(): User[] {
        return this._model;
    }

    @Input()
    set model(value: User[]) {
        this._model = value;
    }

    get options(): IFormCheckboxListOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormCheckboxListOptions) {
        this._options = {...AppUsersCheckboxComponent.initUsersCheckboxListOptions, ...value};
    }

    @Input()
    set roles(value: string[]) {
        this._roles = value;
    }

    get users$(): Observable<User[]> {
        return this._users$;
    }

    ngOnInit(): void {
        this._cUsersFactory.get$({
            etude: {condition: ConditionConst.IN, values: this._etudes.map(etude => etude.id)},
            role: {condition: ConditionConst.CONTAINS_SOME, values: this._roles},
        }).pipe(map(cUsers => cUsers.results), take(1))
            .subscribe(users => this._usersSource.next(users));
    }

    onSelect(users: IFormFieldCheckbox[]): void {
        this.selected.emit(users as User[]);
    }
}
