import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@bluehalo/ngx-leaflet';
import { LeafletDirectiveWrapper } from '@bluehalo/ngx-leaflet';
import * as L from 'leaflet';
import 'leaflet.markercluster';
class LeafletMarkerClusterDirective {
  constructor(leafletDirective) {
    // Hexbin data binding
    this.markerData = [];
    // Fired when the marker cluster is created
    this.markerClusterReady = new EventEmitter();
    this.leafletDirective = new LeafletDirectiveWrapper(leafletDirective);
  }
  ngOnInit() {
    this.leafletDirective.init();
    const map = this.leafletDirective.getMap();
    this.markerClusterGroup = L.markerClusterGroup(this.markerClusterOptions);
    // Add the marker cluster group to the map
    this.markerClusterGroup.addTo(map);
    // Set the data now that the markerClusterGroup exists
    this.setData(this.markerData);
    // Fire the ready event
    this.markerClusterReady.emit(this.markerClusterGroup);
  }
  ngOnChanges(changes) {
    // Set the new data
    if (changes['markerData']) {
      this.setData(this.markerData);
    }
  }
  setData(layers) {
    // Ignore until the markerClusterGroup exists
    if (null != this.markerClusterGroup) {
      this.markerClusterGroup.clearLayers();
      this.markerClusterGroup.addLayers(layers);
    }
  }
  static {
    this.ɵfac = function LeafletMarkerClusterDirective_Factory(ɵt) {
      return new (ɵt || LeafletMarkerClusterDirective)(i0.ɵɵdirectiveInject(i1.LeafletDirective));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LeafletMarkerClusterDirective,
      selectors: [["", "leafletMarkerCluster", ""]],
      inputs: {
        markerData: [0, "leafletMarkerCluster", "markerData"],
        markerClusterOptions: [0, "leafletMarkerClusterOptions", "markerClusterOptions"]
      },
      outputs: {
        markerClusterReady: "leafletMarkerClusterReady"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LeafletMarkerClusterDirective, [{
    type: Directive,
    args: [{
      selector: '[leafletMarkerCluster]'
    }]
  }], () => [{
    type: i1.LeafletDirective
  }], {
    markerData: [{
      type: Input,
      args: ['leafletMarkerCluster']
    }],
    markerClusterOptions: [{
      type: Input,
      args: ['leafletMarkerClusterOptions']
    }],
    markerClusterReady: [{
      type: Output,
      args: ['leafletMarkerClusterReady']
    }]
  });
})();
class LeafletMarkerClusterModule {
  static {
    this.ɵfac = function LeafletMarkerClusterModule_Factory(ɵt) {
      return new (ɵt || LeafletMarkerClusterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LeafletMarkerClusterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LeafletMarkerClusterModule, [{
    type: NgModule,
    args: [{
      exports: [LeafletMarkerClusterDirective],
      declarations: [LeafletMarkerClusterDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LeafletMarkerClusterDirective, LeafletMarkerClusterModule };
