import {inject, Injectable} from '@angular/core';
import BienDiagnostic from '@models/bien/diagnostic/bien-diagnostic.model';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {NgBienDiagnostic} from '@legacy/app/managers/ressources';
import {IBienDiagnosticApi} from '@models/bien/diagnostic/bien-diagnostic.interfaces';
import DateFormat from '@shared/date/date.format';

@Injectable({providedIn: 'root'})
export class BienDiagnosticFactory {
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienDiagnosticApi: IBienDiagnosticApi): BienDiagnostic {
        const bienDiagnostic: BienDiagnostic = new BienDiagnostic(bienDiagnosticApi.uuid);

        bienDiagnostic.date = bienDiagnosticApi.date;
        bienDiagnostic.comments = bienDiagnosticApi.comments;
        if (bienDiagnosticApi._embedded?.typeDiagnostic) {
            bienDiagnostic.type = this._dictionaryItemFactory.create(bienDiagnosticApi._embedded.typeDiagnostic);
        }

        return bienDiagnostic;
    }

    ngCreate(ngBienDiagnostic: NgBienDiagnostic): BienDiagnostic {
        const bienDiagnostic: BienDiagnostic = new BienDiagnostic(ngBienDiagnostic.uuid);

        bienDiagnostic.comments = ngBienDiagnostic.comments;
        bienDiagnostic.date = DateFormat.toJSON(ngBienDiagnostic.date);
        if (ngBienDiagnostic.typeDiagnostic) {
            bienDiagnostic.type = this._dictionaryItemFactory.ngCreate(ngBienDiagnostic.typeDiagnostic);
        }

        return bienDiagnostic;
    }
}
