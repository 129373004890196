import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgSoqTransformation, NgSoqValidation} from '@legacy/app/soqrate/soqrate';
import {NgUserManager} from '@legacy/app/managers/managers';

export default function getAuthResetCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskAuthResetCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/auth/controllers/reset.html',
        });

        /**
         * Controller for reset password
         *
         * @param Ng2RouterStateForNgService
         * @param UserManager
         * @param $translate
         * @param SoqValidation
         * @param SoqTransformation
         * @param Ng2ToasterService
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'UserManager', '$translate', 'SoqValidation', 'SoqTransformation', 'Ng2ToasterService'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            userManager: NgUserManager,
                            $translate: angular.translate.ITranslateService,
                            soqValidation: NgSoqValidation,
                            soqTransformation: NgSoqTransformation,
                            ng2ToasterService: ToasterService) {
            const $ctrl = this;

            $ctrl.resetPassword = resetPassword;
            $ctrl.getRegex = soqTransformation.getRegex;
            $ctrl.newPassword = undefined;
            $ctrl.confirmationNewPassword = undefined;

            /**
             * Change passowrd to current user
             */
            function resetPassword() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    return;
                }

                if (!soqValidation.password($ctrl.newPassword)) {
                    $ctrl.editForm.newPassword.$error = {parse: true};
                    $ctrl.editForm.newPassword.$invalid = true;

                    return;
                }

                $ctrl.sending = true;
                $ctrl.editForm.newPassword.$error = {};
                $ctrl.editForm.newPassword.$invalid = false;
                userManager.resetPassword(ng2RouterStateForNgService.params.token as string, $ctrl.newPassword).then(function () {
                    ng2ToasterService.success($translate.instant('authentication.reinit_password.MESSAGE'));
                    ng2RouterStateForNgService.navigateByUrl('/auth/login');
                }, () => $ctrl.message = "Erreur inconnue survenue").finally(() => $ctrl.sending = false);
            }
        }
    })(angularJS);
}
