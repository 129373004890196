import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-text-phones',
    templateUrl: 'text-phones.component.html',
})
export class TextPhonesComponent {
    private _phones!: string[];

    get phones(): string[] {
        return this._phones;
    }

    @Input()
    set phones(value: string[]) {
        this._phones = value.filter(phone => !!phone);
    }
}
