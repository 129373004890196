import angularJS from '@shared/angularJS/global.ng';
import {ConditionConst, SortConst} from '@shared/constants';
import {IModule, IPromise, IQService} from 'angular';
import {
    NgDemandeurRechercheResultatManager,
    NgDictionariesManager, NgLocationManager, NgManager, NgTemplateManager, NgUtilsManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {NgRechercheResultsCollection} from '@legacy/app/managers/collections';
import {NgDemandeur, NgDemandeurRecherche, NgFilter, NgItemDictionary} from '@legacy/app/managers/ressources';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Vente from '@models/ventes/vente/vente.model';
import Bien from '@models/bien/bien.model';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';

export default function getManagersDemandeurRecherche(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('DemandeurRechercheManager', manager);

        /**
         * Manager Demandeur Recherche
         *
         * @param BaseManager
         * @param UtilsManager
         * @param DemandeurRechercheResultatManager
         * @param VenteManager
         * @param LocationManager
         * @param $q
         * @param TemplateManager
         * @param DictionariesManager
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'DemandeurRechercheResultatManager', 'VenteManager', 'LocationManager', '$q', 'TemplateManager', 'DictionariesManager', 'Ng2DictionaryItemService'];
        function manager(baseManager: NgManager,
                         utilsManager: NgUtilsManager,
                         demandeurRechercheResultatManager: NgDemandeurRechercheResultatManager,
                         venteManager: NgVenteManager,
                         locationManager: NgLocationManager,
                         $q: IQService,
                         templateManager: NgTemplateManager,
                         dictionariesManager: NgDictionariesManager,
                         ng2DictionaryItemService: DictionaryItemService) {
            let classResource = baseManager.getClass<NgDemandeurRecherche>();
            // @ts-ignore
            var demandeurRechercheManager = new DemandeurRechercheManager();
            var multiChoices = [
                {field: 'etats', dictionary: Dictionary.names.ETATS},
                {field: 'mitoyennetes', dictionary: Dictionary.names.MITOYENNETES},
                {field: 'occupations', dictionary: Dictionary.names.MITOYENNETES},
                {field: 'plainPieds', dictionary: Dictionary.names.PLAIN_PIEDS},
            ];

            /**
             * Routing
             */
            baseManager.addRoute('demandeur-recherches.cget', {
                path: '/demandeurs/{demandeurId}/recherches',
                method: 'GET'
            });
            baseManager.addRoute('demandeur-recherches.get', {
                path: '/demandeurs/{demandeurId}/recherches/{id}',
                method: 'GET'
            });
            baseManager.addRoute('demandeur-recherches.insert', {
                path: '/demandeurs/{demandeurId}/recherches',
                method: 'POST'
            });
            baseManager.addRoute('demandeur-recherches.edit', {
                path: '/demandeurs/{demandeurId}/recherches/{id}',
                method: 'PUT'
            });
            baseManager.addRoute('demandeur-recherches.update-rank', {
                path: '/demandeurs/{demandeurId}/recherches/{id}/rank',
                method: 'PATCH'
            });
            baseManager.addRoute('demandeur-recherches.launch', {path: '/recherches/{id}/launch', method: 'PATCH'});
            baseManager.addRoute('demandeur-recherches.remove', {
                path: '/demandeurs/{demandeurId}/recherches/{id}',
                method: 'DELETE'
            });

            /**
             * DemandeurRechercheManager object
             *
             * @constructor
             */
            angular.extend(DemandeurRechercheManager.prototype, baseManager.__proto__);
            DemandeurRechercheManager.prototype.eskManager = {
                prefixRoute: 'demandeur-recherches',
                collectionName: 'items'
            };
            function DemandeurRechercheManager() {
            }

            DemandeurRechercheManager.prototype.getSearchForDemandeur = function (demandeur: NgDemandeur) {
                let recherchePromise: IPromise<NgDemandeurRecherche> = $q.resolve(undefined!);
                let searchId = -1;

                if (demandeur.recherches.length > 0 && angular.isNumber(demandeur.recherches[0].id) && demandeur.recherches[0].id > 0) {
                    searchId = demandeur.recherches[0].id;
                }

                if (searchId > 0) {
                    recherchePromise = demandeurRechercheManager.getOneById(demandeur.id, searchId);
                }

                return recherchePromise.then(search =>
                    (search ?? demandeurRechercheManager.create({status: 'brouillon'}, demandeur.id)) as NgDemandeurRecherche
                ).then(search => {
                    demandeur.recherches = [search];

                    return search;
                });
            }

            /**
             * Returns if attribute has to be used for natures
             *
             * @param natures
             * @param attribute
             * @returns {boolean}
             */
            DemandeurRechercheManager.prototype.useAttributeForNatures = function (natures: NgItemDictionary[], attribute: string): boolean {
                const excludeAttributes: Record<string, string[]> = {
                    anneeConstruction: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    annexe: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    ascenseur: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    balcon: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    bienRdc: [Bien.natures.MAISON, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    cave: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    chambreRdc: [Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    cheminee: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    dernierEtage: [Bien.natures.MAISON, Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, Bien.natures.AUTRE],
                    etats: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    exceptionnel: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    garage: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    grenier: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    mitoyennetes: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreChambres: [Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreLogements: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, Bien.natures.AUTRE],
                    nombrePieces: [Bien.natures.IMMEUBLE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    occupations: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    pieceEauRdc: [Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    piscine: [Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    plainPieds: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    residenceService: [Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    sousSol: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceBatie: [Bien.natures.APPARTEMENT, Bien.natures.BUREAUX, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceNonBatie: [Bien.natures.APPARTEMENT, Bien.natures.BUREAUX, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceSejour: [Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    terrasse: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    terrasseOuBalcon: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                };
                let useAttribute = true;

                if (!angular.isArray(natures) || !angular.isDefined(excludeAttributes[attribute])) {
                    return true;
                }

                angular.forEach(natures, function (nature) {
                    if (!nature || !angular.isString(nature.code)) {
                        return;
                    }

                    if (excludeAttributes[attribute].indexOf(nature.code) !== -1) {
                        useAttribute = false;
                    }
                });

                return useAttribute;
            };

            /**
             * Returns the promise to fetch object
             *
             * @param demandeurId
             * @param resourceId
             * @returns {Promise}
             */
            DemandeurRechercheManager.prototype.getOneById = function (demandeurId: number, resourceId: number) {
                return this.get('get', {
                    demandeurId: demandeurId,
                    id: resourceId
                }).then(function (recherche: NgDemandeurRecherche) {
                    recherche._esk.defaultRouteParams!.demandeurId = demandeurId;

                    return recherche.getResume().then(function () {
                        return recherche;
                    });
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param demandeurId
             * @returns {Promise}
             */
            DemandeurRechercheManager.prototype.getAll = function (demandeurId: number) {
                return baseManager.getAll.call(this, {demandeurId}).then(recherches => {
                    const recherchesCollection: NgRechercheResultsCollection = recherches as NgRechercheResultsCollection;

                    angular.forEach(recherchesCollection.collection, recherche => recherche._esk.defaultRouteParams!.demandeurId = demandeurId);

                    return recherchesCollection;
                });
            };

            /**
             * Create a Recherche object
             *
             * @param data
             * @param demandeurId
             * @returns manager.Recherche object || Array of manager.Recherche objects
             */
            DemandeurRechercheManager.prototype.create = function (data: unknown, demandeurId: number) {
                if (angular.isArray(data)) {
                    var recherches = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        recherches.push(new DemandeurRecherche(demandeurId, data[i]));
                    }

                    return recherches;
                }

                // @ts-ignore
                return new DemandeurRecherche(demandeurId, data);
            };

            /**
             * Demandeur Recherche object
             *
             * @param demandeurId
             * @param data
             * @constructor
             */
            angular.extend(DemandeurRecherche.prototype, classResource.prototype);
            DemandeurRecherche.prototype.eskManager = {prefixRoute: 'demandeur-recherches'};
            function DemandeurRecherche(this: NgDemandeurRecherche, demandeurId: number, data: unknown) {
                this.nom = 'Recherche sans nom';
                this.type = DossierBienTypesConst.VENTE;
                this.option = {};
                this.extend(data);
                this.updateStatus();
                this._esk.defaultRouteParams = {id: this.id, demandeurId: demandeurId};
            }

            /**
             * Extend the existing resource with new data
             *
             * @param data
             * @returns {Resource}
             */
            DemandeurRecherche.prototype.extend = function (data: unknown) {
                const self = this;
                var collection;

                classResource.prototype.extend.call(self, data);

                if (self.type === DossierBienTypesConst.VENTE) {
                    self.vente = angular.isObject(self.vente) ? self.vente : {};
                    self.vente.typeVente = angular.isString(self.vente.typeVente) ? self.vente.typeVente : Vente.types.SIMPLE;
                } else {
                    self.location = angular.isObject(self.location) ? self.location : {};
                }

                angular.forEach(multiChoices, function (multiChoice) {
                    if (angular.isArray(self[multiChoice.field])) {
                        collection = angular.copy(self[multiChoice.field]);
                        self[multiChoice.field] = [];
                        angular.forEach(collection, function (item) {
                            self[multiChoice.field].push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(multiChoice.dictionary, item.id)));
                        });
                    }
                });

                return this;
            };

            /**
             * Has search on localisation
             *
             * @returns {boolean}
             */
            DemandeurRecherche.prototype.hasLocalisation = function () {
                return this.hasArea() || this.hasCommune() || this.hasQuartier();
            };

            /**
             * Has search on commune
             *
             * @returns {boolean}
             */
            DemandeurRecherche.prototype.hasCommune = function () {
                return this.communes.length > 0;
            };

            /**
             * Has search on area
             *
             * @returns {boolean}
             */
            DemandeurRecherche.prototype.hasArea = function () {
                return this.areas.length > 0;
            };

            /**
             * Has search on quartier
             *
             * @returns {boolean}
             */
            DemandeurRecherche.prototype.hasQuartier = function () {
                return this.quartiers.length > 0;
            };

            /**
             * Sanitize object DemandeurRecherche before send to API
             *
             * @returns sanitizeObject
             */
            DemandeurRecherche.prototype.sanitize = function () {
                const self = this;
                var sanitizeObject;
                var booleanOptions = [
                    'annexe', 'ascenseur', 'balcon', 'cave', 'cheminee', 'exceptionnel', 'garage', 'grenier',
                    'parking', 'piscine', 'pointEau', 'sousSol', 'terrasse', 'vueExceptionnelle'
                ];
                var forkOptions = [
                    'surfaceBatie', 'surfaceNonBatie', 'surfaceSejour', 'nombrePieces',
                    'nombreChambres', 'nombreLogements', 'anneeConstruction'
                ];

                angular.forEach(booleanOptions, function (booleanOption) {
                    if (!demandeurRechercheManager.useAttributeForNatures(self.natures, booleanOption)) {
                        delete self.option[booleanOption];
                    }
                });

                angular.forEach(forkOptions, function (forkOption) {
                    if (!demandeurRechercheManager.useAttributeForNatures(self.natures, forkOption)) {
                        delete self.option[forkOption + 'Min'];
                        delete self.option[forkOption + 'Max'];
                    }
                });

                angular.forEach(multiChoices, function (multiChoice) {
                    if (!demandeurRechercheManager.useAttributeForNatures(self.natures, multiChoice.field)) {
                        delete self[multiChoice.field];
                    }
                });

                sanitizeObject = classResource.prototype.sanitize.call(self);

                delete sanitizeObject.results;
                if (self.type === DossierBienTypesConst.VENTE) {
                    delete sanitizeObject.location;
                } else {
                    delete sanitizeObject.vente;
                }

                if (!angular.isObject(sanitizeObject[self.type])) sanitizeObject[self.type] = {};

                utilsManager.flattenIdsForAttribute(sanitizeObject, 'areas');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'communes');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'etats');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'mitoyennetes');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'natures');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'occupations');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'plainPieds');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'quartiers');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'vues');

                return sanitizeObject;
            };

            /**
             * Update color status due to status
             */
            DemandeurRecherche.prototype.updateStatus = function () {
                const self = this;

                self._esk.status = {green: 0, orange: 0, red: 0, blue: 0, none: 0, total: 0};

                if (angular.isObject(self.nbResultsPerStatuses)) {
                    if (angular.isObject(self.nbResultsPerStatuses.match)) {
                        angular.forEach(['firstMatch', 'rerun'], function (reason) {
                            if (angular.isObject(self.nbResultsPerStatuses.match[reason])) {
                                if (angular.isDefined(self.nbResultsPerStatuses.match[reason].interested)) {
                                    self._esk.status.blue += self.nbResultsPerStatuses.match[reason].interested;
                                    self._esk.status.total += self.nbResultsPerStatuses.match[reason].interested;
                                }
                                if (angular.isDefined(self.nbResultsPerStatuses.match[reason].interestNotGiven)) {
                                    self._esk.status.green += self.nbResultsPerStatuses.match[reason].interestNotGiven;
                                    self._esk.status.total += self.nbResultsPerStatuses.match[reason].interestNotGiven;
                                }
                                if (angular.isDefined(self.nbResultsPerStatuses.match[reason].disinterestedExceptPrice)) {
                                    self._esk.status.none += self.nbResultsPerStatuses.match[reason].disinterestedExceptPrice;
                                    self._esk.status.total += self.nbResultsPerStatuses.match[reason].disinterestedExceptPrice;
                                }
                                if (angular.isDefined(self.nbResultsPerStatuses.match[reason].disinterested)) {
                                    self._esk.status.none += self.nbResultsPerStatuses.match[reason].disinterested;
                                    self._esk.status.total += self.nbResultsPerStatuses.match[reason].disinterested;
                                }
                            }
                        });
                    }

                    if (angular.isObject(self.nbResultsPerStatuses.matchUpdated)) {
                        if (angular.isObject(self.nbResultsPerStatuses.matchUpdated.price)) {
                            if (angular.isDefined(self.nbResultsPerStatuses.matchUpdated.price.interested)) {
                                self._esk.status.blue += self.nbResultsPerStatuses.matchUpdated.price.interested;
                                self._esk.status.total += self.nbResultsPerStatuses.matchUpdated.price.interested;
                            }
                            if (angular.isDefined(self.nbResultsPerStatuses.matchUpdated.price.interestNotGiven)) {
                                self._esk.status.orange += self.nbResultsPerStatuses.matchUpdated.price.interestNotGiven;
                                self._esk.status.total += self.nbResultsPerStatuses.matchUpdated.price.interestNotGiven;
                            }
                            if (angular.isDefined(self.nbResultsPerStatuses.matchUpdated.price.disinterestedExceptPrice)) {
                                self._esk.status.none += self.nbResultsPerStatuses.matchUpdated.price.disinterestedExceptPrice;
                                self._esk.status.total += self.nbResultsPerStatuses.matchUpdated.price.disinterestedExceptPrice;
                            }
                            if (angular.isDefined(self.nbResultsPerStatuses.matchUpdated.price.disinterested)) {
                                self._esk.status.none += self.nbResultsPerStatuses.matchUpdated.price.disinterested;
                                self._esk.status.total += self.nbResultsPerStatuses.matchUpdated.price.disinterested;
                            }
                        }
                    }

                    if (angular.isObject(self.nbResultsPerStatuses.matchRemoved)) {
                        angular.forEach(['compromis', 'suspended', 'archived'], function (reason) {
                            if (angular.isObject(self.nbResultsPerStatuses.matchRemoved[reason])) {
                                if (angular.isDefined(self.nbResultsPerStatuses.matchRemoved[reason].interested)) {
                                    self._esk.status.red += self.nbResultsPerStatuses.matchRemoved[reason].interested;
                                    self._esk.status.total += self.nbResultsPerStatuses.matchRemoved[reason].interested;
                                }
                                if (angular.isDefined(self.nbResultsPerStatuses.matchRemoved[reason].interestNotGiven)) {
                                    self._esk.status.red += self.nbResultsPerStatuses.matchRemoved[reason].interestNotGiven;
                                    self._esk.status.total += self.nbResultsPerStatuses.matchRemoved[reason].interestNotGiven;
                                }
                                if (angular.isDefined(self.nbResultsPerStatuses.matchRemoved[reason].disinterestedExceptPrice)) {
                                    self._esk.status.none += self.nbResultsPerStatuses.matchRemoved[reason].disinterestedExceptPrice;
                                    self._esk.status.total += self.nbResultsPerStatuses.matchRemoved[reason].disinterestedExceptPrice;
                                }
                                if (angular.isDefined(self.nbResultsPerStatuses.matchRemoved[reason].disinterested)) {
                                    self._esk.status.none += self.nbResultsPerStatuses.matchRemoved[reason].disinterested;
                                    self._esk.status.total += self.nbResultsPerStatuses.matchRemoved[reason].disinterested;
                                }
                            }
                        });
                    }
                }

                self._esk.status.main = {};
                if (self._esk.status.green > 0) {
                    self._esk.status.main.value = 'green';
                } else if (self._esk.status.orange > 0) {
                    self._esk.status.main.value = 'orange';
                } else if (self._esk.status.blue > 0) {
                    self._esk.status.main.value = 'blue';
                } else if (self._esk.status.red > 0) {
                    self._esk.status.main.value = 'red';
                }

                self._esk.status.main.class = {
                    'resultat-green': self._esk.status.main.value === 'green',
                    'resultat-orange': self._esk.status.main.value === 'orange',
                    'resultat-blue': self._esk.status.main.value === 'blue',
                    'resultat-red': self._esk.status.main.value === 'red',
                };
                self._esk.status.main.classBullet = {
                    'bullet-green': self._esk.status.main.value === 'green',
                    'bullet-orange': self._esk.status.main.value === 'orange',
                    'bullet-blue': self._esk.status.main.value === 'blue',
                    'bullet-red': self._esk.status.main.value === 'red',
                };
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            DemandeurRecherche.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    self.getResume();

                    return self;
                });
            };

            /**
             * Get resume
             *
             * @returns {Promise}
             */
            DemandeurRecherche.prototype.getResume = function (): IPromise<string> {
                const self = this;

                return templateManager.getRechercheResume(self).then(function (resume) {
                    self._esk.resume = resume;

                    return resume;
                });
            };

            /**
             * Launch recherche to get results
             *
             * @returns {promise}
             */
            DemandeurRecherche.prototype.launch = function () {
                return this.patch('launch');
            };

            /**
             * Returns the promise to fetch filtered results
             *
             * @returns {Promise}
             */
            DemandeurRecherche.prototype.getFilteredResults = function (filter: NgFilter) {
                return this.getResults(filter.getParamsRequest());
            };

            /**
             * Returns the promise to fetch results
             *
             * @param params
             * @returns {Promise}
             */
            DemandeurRecherche.prototype.getResults = function (params: unknown): IPromise<NgRechercheResultsCollection> {
                const self = this;

                if (!angular.isDefined(self.id)) {
                    return $q.reject('Recherche non enregistrée.');
                }

                self.results = [];
                self._esk.dossiersCorrespondants = {};

                return demandeurRechercheResultatManager.getAll(self.id, params).then(resultsPaginator => {
                    self.setDossiersCorrespondants(resultsPaginator);

                    return self.updateStatusValue().then(() => self.results);
                });
            };

            /**
             * Returns the promise to fetch historical results
             *
             * @returns {Promise}
             */
            DemandeurRecherche.prototype.getHistoricalResults = function () {
                const self = this;

                if (!angular.isDefined(self.id)) {
                    return $q.reject('Recherche non enregistrée.');
                }

                self.disinterestedResults = [];
                self._esk.disinterestedDossiersCorrespondants = {};

                return demandeurRechercheResultatManager.getAll(self.id, {
                    interest: {
                        condition: ConditionConst.IN,
                        values: [
                            DemandeurRechercheResultat.interests.DISINTERESTED,
                            DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE,
                        ],
                    },
                    tris: {
                        currentEtude: SortConst.ASCENDING,
                        status: SortConst.ASCENDING,
                        interest: SortConst.ASCENDING,
                    },
                }).then(resultsPaginator => {
                    self.setDossiersCorrespondants(resultsPaginator, true);

                    return self.disinterestedResults;
                }).finally(() => self.updateStatusValue());
            };

            /**
             * Returns the promise to fetch historical results
             */
            DemandeurRecherche.prototype.setDossiersCorrespondants = function (results: NgRechercheResultsCollection, disinterested: boolean): void {
                const labelDossiersCorrespondants = disinterested ? 'disinterestedDossiersCorrespondants' : 'dossiersCorrespondants';
                const labelResults = disinterested ? 'disinterestedResults' : 'results';
                const self = this;

                self[labelResults] = results;
                self._esk[labelDossiersCorrespondants] = angular.copy(self[labelResults]);
                self._esk[labelDossiersCorrespondants].collection = [];
                angular.forEach(self[labelResults].collection, function (result) {
                    var dossier;

                    switch (result.type) {
                        case DossierBienTypesConst.VENTE :
                            dossier = venteManager.create(result.vente);
                            break;
                        case DossierBienTypesConst.LOCATION :
                            dossier = locationManager.create(result.location);
                            break;
                    }

                    if (angular.isObject(dossier)) {
                        dossier._esk.result = result;
                        self._esk[labelDossiersCorrespondants].collection.push(dossier);
                    }
                });
            };

            DemandeurRecherche.prototype.updateStatusValue = function () {
                var self = this;

                return demandeurRechercheManager.getOneById(self._esk.defaultRouteParams!.demandeurId, self._esk.defaultRouteParams!.id).then((updatedDemandeurRecherche: NgDemandeurRecherche) => {
                    self.nbResultsPerStatuses = updatedDemandeurRecherche.nbResultsPerStatuses;
                    self._esk.status = updatedDemandeurRecherche._esk.status;
                });
            }

            return demandeurRechercheManager;
        }
    })(angularJS);
}
