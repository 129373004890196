import {inject, Injectable} from '@angular/core';
import BienCharge from '@models/bien/charge/bien-charge.model';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {NgBienCharge} from '@legacy/app/managers/ressources';
import {IBienChargeApi} from '@models/bien/charge/bien-charge.interfaces';

@Injectable({providedIn: 'root'})
export class BienChargeFactory {
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienChargeApi: IBienChargeApi): BienCharge {
        const bienCharge = new BienCharge(bienChargeApi.uuid);

        bienCharge.comments = bienChargeApi.comments;
        bienCharge.cout = bienChargeApi.cout;
        if (bienChargeApi._embedded) {
            if (bienChargeApi._embedded.charge) {
                bienCharge.charge = this._dictionaryItemFactory.create(bienChargeApi._embedded.charge);
            }

            if (bienChargeApi._embedded.periodicite) {
                bienCharge.periodicite = this._dictionaryItemFactory.create(bienChargeApi._embedded.periodicite);
            }
        }

        return bienCharge;
    }

    ngCreate(ngBienCharge: NgBienCharge): BienCharge {
        const bienCharge = new BienCharge(ngBienCharge.uuid);

        bienCharge.comments = ngBienCharge.comments;
        bienCharge.cout = ngBienCharge.cout;
        if (ngBienCharge.charge) {
            bienCharge.charge = this._dictionaryItemFactory.ngCreate(ngBienCharge.charge);
        }

        if (ngBienCharge.periodicite) {
            bienCharge.periodicite = this._dictionaryItemFactory.ngCreate(ngBienCharge.periodicite);
        }

        return bienCharge;
    }
}
