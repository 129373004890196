import angularJS from '@shared/angularJS/global.ng';
import {remove} from 'lodash';
import {IModule} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgBienUtils} from '@legacy/app/biens/biens';
import {NgBienPhoto} from '@legacy/app/managers/ressources';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getBienEditPhotos(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-bien-edit-photos></esk-bien-edit-photos>
         */
        module.component('eskBienEditPhotos', {
            bindings: {bien: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/item/edit/photos.html'
        });

        /**
         * Controller for display photo directive
         *
         * @param $translate
         * @param SoqSweetAlert
         * @param SoqModalesManager
         * @param BiensUtils
         */
        Controller.$inject = ['$translate', 'SoqSweetAlert', 'SoqModalesManager', 'BiensUtils'];
        function Controller(this: any,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            soqModalesManager: NgSoqModalesManager,
                            biensUtils: NgBienUtils) {
            const $ctrl = this;

            $ctrl.removePhoto = removePhoto;
            $ctrl.editBienPhoto = editBienPhoto;
            $ctrl.manageVisibilityBienPhoto = manageVisibilityBienPhoto;
            $ctrl.uiSortableOptions = biensUtils.getUISortableOptions();
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;

            /**
             * Open modale to edit photo
             *
             * @param bienPhoto
             */
            function editBienPhoto(bienPhoto: NgBienPhoto) {
                soqModalesManager.open('EskModalEditPhoto', {resolve: {photo: bienPhoto}});
            }

            /**
             * Open modale to manage visibility
             *
             * @param bienPhoto
             */
            function manageVisibilityBienPhoto(bienPhoto: NgBienPhoto) {
                soqModalesManager.open('BienModalVisibilityPhoto', {resolve: {bienPhoto: bienPhoto}});
            }

            /**
             * Remove the given photo
             *
             * @param photo
             */
            function removePhoto(photo: NgBienPhoto) {
                soqSweetAlert.dangerConfirmation(
                    $translate.instant('remove.confirm.TITLE'),
                    $translate.instant('remove.confirm.MESSAGE', {ceData: "cette photo"}),
                    {confirmButtonText: $translate.instant('remove.confirm.CONFIRMATION')}
                ).then(function () {
                    photo._esk.removing = true;
                    photo.remove().then(function () {
                        remove($ctrl.bien.photos, {_esk: {uuid: photo._esk.uuid}});
                    }).finally(function () {
                        photo._esk.removing = false;
                    });
                }, function () {
                });
            }
        }
    })(angularJS);
}
