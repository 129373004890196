import angularJS from '@shared/angularJS/global.ng';
import {filter, findIndex, min} from 'lodash';
import {IModule, IQService} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgBienManager, NgMediaManager} from '@legacy/app/managers/managers';
import {NgEskTaskRunner} from '@legacy/app/eskimmo/eskimmo';
import {NgMedia} from '@legacy/app/managers/ressources';
import {IMG_MAX_SIZE} from '@shared/image/image.constants';

export default function getBienEditPhotosCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskBienEditPhotosCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/controllers/item/edit/photos.html',
        });

        /**
         * Controller for template photos.html
         *
         * @param BienManager
         * @param MediaManager
         * @param EskTaskRunner
         * @param Ng2RouterStateForNgService
         * @param $q
         * @param $translate
         */
        Controller.$inject = ['BienManager', 'MediaManager', 'EskTaskRunner', 'Ng2RouterStateForNgService', '$q', '$translate'];
        function Controller(this: any,
                            bienManager: NgBienManager,
                            mediaManager: NgMediaManager,
                            eskTaskRunner: NgEskTaskRunner,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $q: IQService,
                            $translate: angular.translate.ITranslateService) {
            const $ctrl = this;

            $ctrl.getInputPhoto = getInputPhoto;
            $ctrl.typeFile = 'photo';
            $ctrl.bien = bienManager.current;
            $ctrl.infoClientFilter = {type: 'photo_bien', idDossier: $ctrl.bien.id};
            angular.forEach(filter(eskTaskRunner.getTasks(), {infoClient: $ctrl.infoClientFilter}), function (task) {
                task.promise.then(function (bienPhoto) {
                    if (findIndex($ctrl.bien.photos, function (photo) {
                        // @ts-ignore
                        return photo._esk.uuid === bienPhoto._esk.uuid;
                    }) < 0) {
                        $ctrl.bien.photos.push(bienPhoto);
                    }
                }).finally(function () {
                    // @MEC Conserver cet ordre car, si on quitte la vue et qu'on revient, la liste des tâches ne se met pas à jour, pas le même contexte pour eskUploadFilesInfo.
                    // @MEC De plus, il faut laisser cette méthode s'exécuter tel quelle, sinon elle n'est pas correctement initialisée.
                    $ctrl.updateTasks();
                });
            });

            /**
             * Get photo and upload it
             *
             * @param file
             */
            function getInputPhoto(file: { desktopFile: File; height: number; width: number }) {
                let media = mediaManager.create({file: file.desktopFile, height: file.height, width: file.width});
                var task = {};

                if (min([media.height, media.width])! < 300) {
                    setErrorTask('files.process.small_dimensions.MESSAGE');
                } else if (media.file.size >= IMG_MAX_SIZE) {
                    setErrorTask('files.process.over_weight.MESSAGE');
                } else {
                    media.file._esk = {status: 'WAITING'};
                    task = eskTaskRunner.addCallbackTask({
                        method: media.upload,
                        context: media,
                        then: (media: NgMedia) => $ctrl.bien.addPhotoFromMedia({id: media.id}),
                    }, 1, {
                        type: 'photo_bien',
                        idDossier: $ctrl.bien.id,
                        uisref: {
                            name: ng2RouterStateForNgService.current.name,
                            params: ng2RouterStateForNgService.current.params,
                        },
                        title: media.file.name + " du bien " + $ctrl.bien.reference,
                        media: media
                    }, {
                        method: media.cancelUpload,
                        context: media
                    });
                    $ctrl.updateTasks();
                }

                // @ts-ignore
                task.promise.catch($ctrl.updateTasksErrors).finally($ctrl.updateTasks);

                /**
                 * Set error to current task
                 *
                 * @param message
                 */
                function setErrorTask(message: string) {
                    task = eskTaskRunner.createTasks(undefined!, undefined!, {
                        type: 'photo_bien',
                        idDossier: $ctrl.bien.id,
                        media,
                    });
                    // @ts-ignore
                    task.deferred.reject({
                        status: 'ERROR',
                        data: {title: $translate.instant(message, {type: 'photo'})}
                    });
                }
            }
        }
    })(angularJS);
}
