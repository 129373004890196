import {inject, Injectable} from '@angular/core';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import BienSurface from '@models/bien/surface/bien-surface.model';
import {NgBienPiece} from '@legacy/app/managers/ressources';
import {IBienSurfaceApi} from '@models/bien/surface/bien-surface.interfaces';

@Injectable({providedIn: 'root'})
export class BienSurfaceFactory {
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienSurfaceApi: IBienSurfaceApi): BienSurface {
        const bienSurface: BienSurface = new BienSurface(bienSurfaceApi.uuid);

        bienSurface.comments = bienSurfaceApi.comments;
        bienSurface.habitable = bienSurfaceApi.habitable;
        bienSurface.otherLabel = bienSurfaceApi.otherLabel;
        bienSurface.ponderation = bienSurfaceApi.ponderation;
        bienSurface.surface = bienSurfaceApi.surface;
        if (bienSurfaceApi._embedded) {
            if (bienSurfaceApi._embedded.etage) {
                bienSurface.etage = this._dictionaryItemFactory.create(bienSurfaceApi._embedded.etage);
            }

            if (bienSurfaceApi._embedded.piece) {
                bienSurface.piece = this._dictionaryItemFactory.create(bienSurfaceApi._embedded.piece);
            }
        }

        return bienSurface;
    }

    ngCreate(ngBienPiece: NgBienPiece): BienSurface {
        const bienSurface: BienSurface = new BienSurface(ngBienPiece.uuid);

        bienSurface.comments = ngBienPiece.comments;
        bienSurface.habitable = ngBienPiece.habitable;
        bienSurface.otherLabel = ngBienPiece.otherLabel;
        bienSurface.ponderation = ngBienPiece.ponderation;
        bienSurface.surface = ngBienPiece.surface;
        if (ngBienPiece.etage) {
            bienSurface.etage = this._dictionaryItemFactory.ngCreate(ngBienPiece.etage);
        }

        if (ngBienPiece.piece) {
            bienSurface.piece = this._dictionaryItemFactory.ngCreate(ngBienPiece.piece);
        }

        return bienSurface;
    }
}
