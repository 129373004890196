import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

export default class BienLogement extends AModel {
    private _comments!: string;
    private _etage!: DictionaryItem;
    private _logement!: DictionaryItem;
    private _otherLabel!: string;
    private _surface!: number;

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get etage(): DictionaryItem {
        return this._etage;
    }

    set etage(value: DictionaryItem) {
        this._etage = value;
    }

    get logement(): DictionaryItem {
        return this._logement;
    }

    set logement(value: DictionaryItem) {
        this._logement = value;
    }

    get otherLabel(): string {
        return this._otherLabel;
    }

    set otherLabel(value: string) {
        this._otherLabel = value;
    }

    get surface(): number {
        return this._surface;
    }

    set surface(value: number) {
        this._surface = value;
    }
}
