import {Injectable} from '@angular/core';
import Bien from '@models/bien/bien.model';
import {DiagnosticLettersConst} from '@shared/diagnostic/diagnostic.constants';

@Injectable({providedIn: 'root'})
export class BienPerformanceEnergetiqueService {
    private static readonly ConsommationV1Habitable = [50, 90, 150, 230, 330, 450];
    private static readonly ConsommationV1NonHabitable = [50, 110, 210, 350, 540, 750];
    private static readonly ConsommationV2Surfaces = {
        default: [69, 109, 179, 249, 329, 419],
        min: [145, 185, 385, 504, 621, 738],
        /* eslint-disable @typescript-eslint/naming-convention */
        9: [133, 173, 354, 463, 573, 684],
        10: [123, 163, 328, 427, 532, 639],
        11: [116, 156, 310, 402, 502, 606],
        12: [111, 151, 295, 380, 477, 577],
        13: [107, 147, 282, 362, 455, 553],
        14: [103, 143, 272, 347, 436, 531],
        15: [99, 139, 262, 332, 420, 513],
        16: [96, 136, 254, 324, 411, 503],
        17: [94, 134, 247, 317, 403, 495],
        18: [91, 131, 240, 310, 396, 488],
        19: [89, 129, 234, 304, 389, 481],
        20: [87, 127, 229, 299, 384, 475],
        21: [86, 126, 224, 294, 379, 470],
        22: [84, 124, 220, 290, 374, 465],
        23: [83, 123, 216, 286, 370, 461],
        24: [81, 121, 213, 283, 366, 457],
        25: [80, 120, 209, 279, 362, 453],
        26: [79, 119, 206, 276, 359, 450],
        27: [78, 118, 203, 273, 356, 446],
        28: [77, 117, 201, 271, 353, 443],
        29: [76, 116, 198, 268, 350, 441],
        30: [75, 115, 196, 266, 348, 438],
        31: [75, 115, 194, 264, 345, 436],
        32: [74, 114, 192, 262, 343, 433],
        33: [73, 113, 190, 260, 341, 431],
        34: [73, 113, 188, 258, 339, 429],
        35: [72, 112, 187, 257, 337, 427],
        36: [71, 111, 185, 255, 336, 426],
        37: [71, 111, 184, 254, 334, 424],
        38: [70, 110, 182, 252, 332, 422],
        39: [70, 110, 181, 251, 331, 421],
        40: [69, 109, 179, 249, 329, 419],
        /* eslint-enable */
    };
    private static readonly EmissionsV1Habitable = [5, 10, 20, 35, 55, 80];
    private static readonly EmissionsV1NonHabitable = [5, 15, 30, 60, 100, 145];
    private static readonly EmissionsV2Surfaces = {
        default: [5, 10, 29, 49, 69, 99],
        min: [10, 15, 43, 67, 89, 121],
        /* eslint-disable @typescript-eslint/naming-convention */
        9: [10, 15, 41, 64, 86, 117],
        10: [9, 14, 39, 61, 83, 114],
        11: [9, 14, 38, 60, 81, 112],
        12: [8, 13, 37, 58, 80, 110],
        13: [8, 13, 36, 57, 78, 109],
        14: [8, 13, 36, 57, 77, 107],
        15: [7, 12, 35, 55, 75, 106],
        16: [7, 12, 34, 54, 75, 105],
        17: [7, 12, 34, 54, 74, 104],
        18: [7, 12, 33, 53, 74, 104],
        19: [7, 12, 33, 53, 73, 104],
        20: [7, 12, 33, 53, 73, 103],
        21: [7, 12, 32, 52, 73, 103],
        22: [6, 11, 32, 52, 72, 102],
        23: [6, 11, 32, 52, 72, 102],
        24: [6, 11, 32, 52, 72, 102],
        25: [6, 11, 31, 51, 72, 102],
        26: [6, 11, 31, 51, 71, 102],
        27: [6, 11, 31, 51, 71, 101],
        28: [6, 11, 31, 51, 71, 101],
        29: [6, 11, 31, 51, 71, 101],
        30: [6, 11, 31, 51, 71, 101],
        31: [6, 11, 30, 50, 71, 101],
        32: [6, 11, 30, 50, 70, 100],
        33: [6, 11, 30, 50, 70, 100],
        34: [6, 11, 30, 50, 70, 100],
        35: [6, 11, 30, 50, 70, 100],
        36: [6, 11, 30, 50, 70, 100],
        37: [6, 11, 30, 50, 70, 100],
        38: [6, 11, 30, 50, 70, 100],
        39: [6, 11, 30, 50, 70, 100],
        40: [5, 10, 29, 49, 69, 99],
        /* eslint-enable */
    };
    static readonly Surface = {MAX: 40, MIN: 8};

    getConsommationClasse(bien: Bien): string {
        if (!bien.performanceEnergetique.isDisponible() || (!bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation !== 0)) {
            return undefined!;
        }

        // Bien non habitable
        if (!bien.isHabitable()) {
            return this._getLettre(BienPerformanceEnergetiqueService.ConsommationV1NonHabitable, bien.performanceEnergetique.consommation);
        }

        // Bien avec performance énergétique en V1 ou dans les DOM TOM
        if (bien.performanceEnergetique.isV1ByDate() || bien.isInDOMTOM()) {
            return this._getLettre(BienPerformanceEnergetiqueService.ConsommationV1Habitable, bien.performanceEnergetique.consommation);
        }

        // Diagnostic V2
        const superficie = this.getSuperficie(bien);
        let consommationRange = BienPerformanceEnergetiqueService.ConsommationV2Surfaces.default;

        if (superficie <= BienPerformanceEnergetiqueService.Surface.MIN) {
            consommationRange = BienPerformanceEnergetiqueService.ConsommationV2Surfaces.min;
        } else if (superficie <= BienPerformanceEnergetiqueService.Surface.MAX) {
            consommationRange = BienPerformanceEnergetiqueService.ConsommationV2Surfaces[superficie];
        }

        const consommationClasse = this._getLettre(consommationRange, bien.performanceEnergetique.consommation);

        return bien.performanceEnergetique.emissionsClasse?.code as unknown as string > consommationClasse
            ? bien.performanceEnergetique.emissionsClasse?.code as unknown as string
            : consommationClasse;
    }

    getEmissionsClasse(bien: Bien): string {
        if (!bien.performanceEnergetique.isDisponible() || (!bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions !== 0)) {
            return undefined!;
        }

        // Bien non habitable
        if (!bien.isHabitable()) {
            return this._getLettre(BienPerformanceEnergetiqueService.EmissionsV1NonHabitable, bien.performanceEnergetique.emissions);
        }

        // Bien avec performance énergétique en V1 ou dans les DOM TOM
        if (bien.performanceEnergetique.isV1ByDate() || bien.isInDOMTOM()) {
            return this._getLettre(BienPerformanceEnergetiqueService.EmissionsV1Habitable, bien.performanceEnergetique.emissions);
        }

        // Diagnostic V2
        const superficie = this.getSuperficie(bien);
        let emissionsRange = BienPerformanceEnergetiqueService.EmissionsV2Surfaces.default;

        if (superficie <= BienPerformanceEnergetiqueService.Surface.MIN) {
            emissionsRange = BienPerformanceEnergetiqueService.EmissionsV2Surfaces.min;
        } else if (superficie <= BienPerformanceEnergetiqueService.Surface.MAX) {
            emissionsRange = BienPerformanceEnergetiqueService.EmissionsV2Surfaces[superficie];
        }

        return this._getLettre(emissionsRange, bien.performanceEnergetique.emissions);
    }

    getSuperficie(bien: Bien): number {
        // Cette ligne est présente à cause du code AngularJS et de la gestion en texte de la saisie des nombres
        const superficie = parseFloat((bien.superficie ?? 0).toString().replace(',', '.'));

        return Math.ceil(superficie);
    }

    isDiagV1(bien: Bien): boolean {
        if (!bien.performanceEnergetique.statutDpe) {
            return true;
        }

        if (!bien.isHabitable()) {
            return true;
        }

        if (bien.isInDOMTOM()) {
            return true;
        }

        return bien.performanceEnergetique.isV1ByDate();
    }

    isDiagV2(bien: Bien): boolean {
        return !this.isDiagV1(bien);
    }

    private _getLettre(array: number[], value: number): string {
        const lettre = [
            DiagnosticLettersConst.A,
            DiagnosticLettersConst.B,
            DiagnosticLettersConst.C,
            DiagnosticLettersConst.D,
            DiagnosticLettersConst.E,
            DiagnosticLettersConst.F,
            DiagnosticLettersConst.G,
        ];
        let idx = lettre.length - 1;

        array.forEach((borne, idxArray) => {
            if (value <= borne && idx >= idxArray) {
                idx = idxArray;
            }
        });

        return lettre[idx];
    }
}
