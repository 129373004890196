import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgVenteContratsCollection} from '@legacy/app/managers/collections';
import {NgManager} from '@legacy/app/managers/managers';
import {NgVenteContrat} from '@legacy/app/managers/ressources';
import DateFormat from '@shared/date/date.format';

export default function getManagersVenteContrat(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('VenteContratManager', manager);

        /**
         * Manager VenteContrat
         *
         * @param BaseManager
         * @returns {VenteContratManager}
         */
        manager.$inject = ['BaseManager'];
        function manager(baseManager: NgManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('vente-contrats.cget', {path: '/ventes/{venteUuid}/contrats', method: 'GET'});
            baseManager.addRoute('vente-contrats.get', {path: '/ventes/{venteUuid}/contrats/{uuid}', method: 'GET'});
            baseManager.addRoute('vente-contrats.insert', {path: '/ventes/{venteUuid}/contrats', method: 'POST'});
            baseManager.addRoute('vente-contrats.edit', {path: '/ventes/{venteUuid}/contrats/{uuid}', method: 'PUT'});
            baseManager.addRoute('vente-contrats.remove', {
                path: '/ventes/{venteUuid}/contrats/{uuid}',
                method: 'DELETE'
            });

            /**
             * VenteContratManager object
             *
             * @constructor
             */
            angular.extend(VenteContratManager.prototype, baseManager.__proto__);
            VenteContratManager.prototype.eskManager = {prefixRoute: 'vente-contrats', collectionName: 'items'};
            function VenteContratManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param venteUuid
             * @param params
             * @returns {Promise}
             */
            VenteContratManager.prototype.getAll = function (venteUuid: string, params: unknown) {
                return baseManager.getAll.call(this, angular.extend({venteUuid: venteUuid}, params)).then(venteContrats => {
                    const venteContratsCollection: NgVenteContratsCollection = venteContrats as NgVenteContratsCollection;

                    angular.forEach(venteContratsCollection.collection, function (venteContrat) {
                        venteContrat._esk.defaultRouteParams!.venteUuid = venteUuid;
                    });

                    return venteContrats;
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param venteUuid
             * @param resourceUuid
             * @returns {Promise}
             */
            VenteContratManager.prototype.getOneById = function (venteUuid: string, resourceUuid: string) {
                return this.get('get', {venteUuid: venteUuid, uuid: resourceUuid}).then(function (venteContrat: NgVenteContrat) {
                    venteContrat._esk.defaultRouteParams!.venteUuid = venteUuid;

                    return venteContrat;
                });
            };

            /**
             * Create a VenteContrat object
             *
             * @param data
             * @param venteUuid
             * @returns manager.VenteContrat object || Array of manager.VenteContrat objects
             */
            VenteContratManager.prototype.create = function (data: any, venteUuid: string) {
                if (angular.isArray(data)) {
                    var venteContrats = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        venteContrats.push(new VenteContrat(venteUuid, data[i]));
                    }

                    return venteContrats;
                }

                // @ts-ignore
                return new VenteContrat(venteUuid, data);
            };

            /**
             * VenteContrat object
             *
             * @param venteUuid
             * @param data
             * @constructor
             */
            angular.extend(VenteContrat.prototype, classResource.prototype);
            VenteContrat.prototype.eskManager = {prefixRoute: 'vente-contrats'};

            function VenteContrat(this: NgVenteContrat, venteUuid: string, data: any) {
                this.extend(data);
                this._esk.defaultRouteParams = {uuid: this.contratUuid, venteUuid: venteUuid};
            }

            /**
             * Extend the existing VenteContrat with new data
             *
             * @param data
             * @returns {VenteContrat}
             */
            VenteContrat.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);
                this._esk.persist = angular.isDefined(this.contratUuid);
                this._esk.flush = this._esk.persist;

                if (angular.isObject(this.contrat) && angular.isString(this.contrat.dateSignature)) {
                    this.contrat.dateSignature = DateFormat.toDate(this.contrat.dateSignature);
                }

                return this;
            };

            /**
             * Sanitize object DemandeurRecherche before send to API
             *
             * @returns sanitizeObject
             */
            VenteContrat.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                delete sanitizeObject.contrat;
                delete sanitizeObject.vente;
                if (angular.isObject(this.contrat)) {
                    sanitizeObject.contactId = this.contrat.contactId;
                    sanitizeObject.contactsGroupId = this.contrat.contactsGroupId;
                    sanitizeObject.contrat = {dateSignature: DateFormat.toAPI(this.contrat.dateSignature)};
                    sanitizeObject.documentCourrierIntroductifId = this.contrat.documentCourrierIntroductifId;
                    sanitizeObject.documentId = this.contrat.documentId;
                }

                return sanitizeObject;
            };

            /**
             * Returns the promise of POST or PUT request
             *
             * @returns {Promise}
             */
            VenteContrat.prototype.save = function () {
                const self = this;

                self.id = angular.isString(self.contratUuid) && self.contratUuid !== '';

                return classResource.prototype.save.call(self).then(function () {
                    if (angular.isDefined(self.contratUuid)) {
                        self._esk.defaultRouteParams.uuid = self.contratUuid;
                    }

                    return self;
                });
            };

            // @ts-ignore
            return new VenteContratManager();
        }
    })(angularJS);
}
